<template>
    <div>
        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">

            <download-excel
                    class="dropdown-item"
                    :fetch="fetchData"
                    :fields="fields"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="fileName+'.xls'">
                <span><i class="la la-file-excel"></i> <span>{{$t('excel')}}</span></span>
            </download-excel>


<!--            <button @click="generatePDF" class="dropdown-item">-->
<!--                <span><i class="la la-file-pdf"></i> <span>{{$t('generate_pdf')}}</span></span>-->
<!--            </button>-->

            <button @click="showModal" class="dropdown-item">
                <span><span>{{$t('custom_export')}}</span></span>
            </button>

        </b-dropdown>
        <b-modal ref="modalExport" hide-footer :title="$t('custom_export')">
            <div class="row">
                <div class="col-md-12 mb-7">
                    <b-form-checkbox v-model="select_all" :key="'select_all'">{{$t('select_all')}}</b-form-checkbox>
                </div>
                <div class="col-md-6 mb-3" v-for="(row, index) in fields" :key="index">
                    <b-form-checkbox v-model="selectedFields" @change="selectValue" :value="{[index]: row}" :key="index">{{index}}</b-form-checkbox>
                </div>
            </div>


            <div class="card-footer pl-0 pr-0 pb-0">
                <div class="row">
                    <div class="col-lg-12">

                        <download-excel
                                class="btn btn-primary mt-2 btn-sm"
                                :fetch="fetchData"
                                :fields="customFields"
                                :before-generate="startDownload"
                                :before-finish="finishDownload"
                                :name="fileName+'.xls'">
                            <i class="la la-file-excel" style="font-size: 22px;"></i>{{$t('excel')}}
                        </download-excel>
<!--                        <button @click="generatePDF(true)" class="btn btn-primary mt-2 mr-1 btn-sm">-->
<!--                            <span><i class="la la-file-pdf" style="font-size: 22px;"></i> <span>{{$t('generate_pdf')}}</span></span>-->
<!--                        </button>-->
                        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>

                    </div>
                </div>
            </div>
        </b-modal>
    </div>

</template>

<script>
    // import ApiService from "@/core/services/api.service";
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';

    export default {
        name: "ExportData",
        props: ['route', 'fields', 'filters', 'dataList', 'fileName'],
        data() {
            return {
                selectedFields: [],
                customFields: {},
                select_all: false,
            }
        },
        watch: {
            select_all: function (val) {
                if (val) {
                    let _keys = Object.keys(this.fields);
                    _keys.forEach((index) => {
                        let _val = this.fields[index];
                        this.selectedFields.push({[index]: _val});
                    });
                    Object.assign(this.customFields, this.fields);
                } else {
                    this.selectedFields = [];
                    this.customFields = {};
                }
            }
        },
        methods: {
            /**
             * function to get data without pagination
             * to export data
             * @returns {Promise<*>}
             */

            fetchData() {
                // const response = await ApiService.query(this.route, {params:{...this.filters}});
                return this.dataList;
            },
            generatePDF(is_custom = false) {
                let _keys = Object.keys(is_custom ? this.customFields : this.fields);

                let head = [_keys];
                let data_key = [];
                _keys.forEach(k => {
                    data_key.push(this.fields[k]);
                });
                let _data = this.dataList.map(row => {
                    let _inner_row = [];
                    data_key.forEach(k => {
                        _inner_row.push(row[k]);
                    });
                    return _inner_row;
                });

                let doc = new jsPDF();
                const AmiriRegular = doc.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
                doc.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);

                doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
                doc.setFont("Amiri");

                doc.autoTable({head: head, body: _data})
                doc.save(`${this.fileName}.pdf`);
            },
            // async fetchData(){
            //     const response = await ApiService.query(this.route, {params:{...this.filters}});
            //     return response.data.data;
            // },
            startDownload() {
            },
            finishDownload() {
            },
            showModal() {
                this.$refs['modalExport'].show()
            },
            hideModal() {
                this.$refs['modalExport'].hide();
            },
            toggleModal() {
                this.$refs['modalExport'].toggle('#toggle-btn');
            },
            selectValue() {
                this.customFields = {};
                this.selectedFields.map((row) => {
                    Object.assign(this.customFields, row);
                });
            },
            selectAll() {
                this.select_all = !this.select_all;
            }
        }
    }
</script>

<style scoped>

</style>