// Arabic
export const locale = {
    header_title: 'سجلها',
    TRANSLATOR: {
        SELECT: "اختر لغتك"
    },
    MENU: {
        inventory_statistics_tracking: 'تتبع إحصائيات المخزون',
        api_internal_configurations: 'إعدادات الـ API الداخلي',
        export_import_suppliers: 'تصدير واستيراد الموردين',
        item_configuration:'ادارة المنتجات',
        item_label_management: 'ادارة ملصق المنتجات',
        laundry_subscriptions: 'اشتراكات المغاسل',
        NEW: "الجديد",
        ACTIONS: "الإجراءات",
        CREATE_POST: "إنشاء منشور جديد",
        PAGES: "الصفحات",
        FEATURES: "سمات",
        APPS: "تطبيقات",
        DASHBOARD: "لــــــوحة التـحـكـم",
        USERS: "المستخدمين",
        Roles_Management: 'إدارة الصلاحيات',
        units_management: 'إدارة الوحدات',
        units: 'الوحدات',
        currencies: 'العملات',
        taxes: 'الضرائب',
        Cities: 'المدن',
        Constants: 'الثوابت',
        code_setting: 'إعدادات الكود',
        Countries: 'الدول',
        company_profile: 'ملف الشركة',
        users_roles_management: 'ادارة المستخدمين',
        setting_management: 'الإعــــــــــــــدادات',
        cities_management: 'إدارة المدن',
        user_Management: 'إدارة المستخدمين',
        tax_Management: 'إدارة الضرائب',
        Branches: 'ادارة الفروع',
        smtp: 'SMTP بروتوكول',
        barcode_setting: 'إعدادت الباركود',
        general_sales_rent: 'فاتورة تأجير عامة',
        sales_rent_management: 'فاتورة تأجير',
        Inventories: 'ادارة المخازن',
        Api_configurations: 'Api إعدادات',
        Api_endpoint_configurations: 'Api Endpoint إعدادات',
        key_generation: 'اكواد الخدمات',
        template_design: 'تصميم القالب',
        brands_management: 'العلامات التجارية',
        Manufacturers: 'المصانع',
        Categories: 'التصنيفات',
        customer_management: 'إدارة العملاء',
        customers: 'العمـــــــــــــــــلاء',
        procedures: 'الإجراءات',
        procedure_management: 'إدارة الإجراءات',
        suppliers_management: 'إدارة الموردين',
        purchases: 'المشتـــــــــريـــات',
        purchases_invoices: 'فواتير المشتريات',
        purchase_configuration: "الإعدادات العامة للمشتريات",
        estimations_management: "ادارة التقديرات",
        sales: "المـبـيـــــــــــــعات",
        sales_invoices_management: "فواتير المبيعات",
        laundry_sales_invoices_management: "فواتير المغاسل",
        sales_credits_management: "إشعار دائن",
        sales_shipping_management: "إدارة الشحنات",
        sales_configuration_management: "إعدادات فواتير المبيعات",
        expenses_category: "إدارة فئات المصروفات",
        expenses_management: "إدارة المصروفات",
        incomes_management: "إدارة الواردات",
        treasuries_management: "إدارة الخزائن",
        print_settings: "اعدادات الطباعة",
        /*****/
        country_management: 'إدارة الدول',
        banks_management: 'إدارة البنوك',
        payments_management: 'إدارة الدفع',
        payments_details: 'تفاصيل الدفع',
        code_setting_management: 'إدارة إعداد الكود',
        IncomeCategories: 'تصنيفات الدخل',
        Finances_management: 'الـــــمــــالـــــــيـــة',
        customers_management: 'إدارة العملاء',
        appointments: 'المواعيد',
        appointments_management: 'إدارة المواعيد',
        Items: 'المنتجات',
        Items_management: 'إدارة المـنـتــجــات',
        inventory_statistics_management: 'إدارة إحصائيات المخزون',
        inventory_statistics: 'إحصائيات المخزن',
        Prices_lists: 'قوائم الأسعار',
        Inventory_transactions: 'الإذونات المخزنية',
        Inventory_monitoring: 'إدارة الجرد',
        customers_status: 'حالة العملاء',
        customer_attributes: 'سمات العملاء',
        customers_features: 'ميزات العملاء',
        purchases_refunds: 'مرتجع المشتريات',
        expenses_categories: "فئات المصروفات",
        subscriptions_management: "المشتريات الدورية",
        payment_invoices: "دفع الفواتير",
        payment_sales_invoices: "دفعات المبيعات",
        payment_purchase_invoices: "دفعات المشتريات",
        payment_sales_credits: "دفعات إشعارات الدائن",
        payment_sales_debits: "دفعات إشعارات المدين",
        payment_sales_refund: "دفعات مرتجع المبيعات",
        payment_purchase_refund: "دفعات مرتجع المشتريات",
        purchase_refund: "مرتجع المشتريات",
        payment_sales_invoices_details: "تفاصيل دفعات المبيعات",
        sales_refund_management: "إدارة فواتير المبيعات المرنجعة",
        employees_management: 'ادارة المــــوظفين',
        employees: 'الموظفين',
        purchases_requests: 'أمر شراء داخلي',
        purchases_quotation_requests: 'طلبات مقترحات المشتريات',
        purchase_quotations: 'عروض أسعار المشتريات',
        purchases_orders: 'طلبيات المشتريات',
        payable_cheques: 'الشيكات المستحقة للدفع',
        cheques_cycle: 'دورة الشـــيــــكات',
        cheque_book: 'دفاتر الشيكات',
        receivable_cheques: 'الشيكات المستحقة للقبض',
        activity_log: 'سجل النشاطات',
        logs: 'السجلات',
        open_stock_items: 'الكميات الافتتاحية',
        open_quantity_history: 'سجل الكميات الافتتاحية',
        POS: 'نقــــــاط البــيـــــع',
        pos_devices: 'أجهزة نقاط البيع',
        pos_shifts: 'الورديات',
        pos_settings: 'إعدادات نقاط البيع',
        accounting: 'المحـــاســـــــــبــة',
        financial_years: 'السنوات المالية',
        closed_periods: 'الفترات المغلقة',
        insuranceagents: 'وكـــلاء التــأميــن',
        insurance_agents: 'وكـــلاء التــأميــن',
        points_credits: 'نقـــاط وأرصـــــدة',
        credit_types: 'أنواع الأرصدة',
        credit_usages: 'استخدامات الأرصدة',
        package_info: 'معلومات الحزمة',
        sales_commissions: 'عمولات المبيعات',
        sales_target_commission: 'عمولة المبيعـــات',
        sales_periods: 'فترات البيع',
        sales_commission_details: 'تفاصيل عمولة المبيعات',
        chart_accounts: 'شجرة الحسابات',
        journal_entries: 'القيود اليومية',
        assets_info: 'معلومات الأصول',
        accounts_routing: 'توجيه الحسابات',
        cost_centers: 'مراكز التكلفة',
        loyalty: 'الــــــــــــــــــــولاء',
        clients_loyalty: 'ولاء العملاء',
        loyalty_rules: 'قواعد الولاء',
        installment: 'تقسيط',
        schedule_appointments: 'جدولة المواعيد',
        installments: 'الأقســـــــــــــــاط',
        installment_agreements: 'اتفاقيات التقسيط',
        installments_payments: 'مدفوعات التقسيط',
        pay_installment: 'دفع القسط',
        add_to_cost_center: 'أضف إلى مركز التكلفة',
        add_sales_invoice_to_cost_center: 'أضف فاتورة المبيعات إلى مركز التكلفة',
        add_sales_credit: 'أضف إشعار دائن',
        refund: 'مرتجع',
        add_sales_debit: 'أضف إشعار مدين',
        debit: 'دين',
        add_notes_attachments: 'أضف ملاحظات ومرفقات',
        packing_slip: 'إرسال الشحن',
        invoice_printing: 'فاتورة',
        invoice_printing_80_mm: 'طباعة إيصال',
        sales_refund: 'مرتجع فاتورة',
        refund_receipt: 'إيصال المرتجع',
        point_of_sales: 'نقاط البيع',
        pos_sessions: 'جلسات نقاط البيع',
        refund_invoice: 'مرتجع فاتورة',
        sales_screen: 'شاشة المبيعات',
        cash_movement: 'الحركة المالية',
        refund_details: 'تفاصيل الإيصال المرتجع',
        close_session: 'إغلاق الجلسة',
        invoice_details: 'تفاصيل الفاتورة',
        cost_transactions: 'معاملات التكلفة',
        accounts_without_cost_center: 'الحسابات بدون مركز التكلفة',
        accounts_with_cost_center: 'الحسابات مع مركز التكلفة',
        cost_center_transactions: 'معاملات مركز التكلفة',
        installment_payments: 'مدفوعات التقسيط',
        change_email_password: 'تغيير البريد الالكتروني وكلمة المرور',
        daily_brief_report_invoices: 'تقرير يومي مختصر للفواتير',
        daily_brief_report_payments: 'تقرير يومي مختصر للدفعات المالية',
        stocks_report: 'تقرير المخازن',
        reports: 'التــــقـــــاريـــــــــر',
        inventory_transaction_report: 'تقرير حركة المخازن',
        inventory_transaction_details: 'تفاصيل حركة المخازن',
        estimated_inventory: 'القيمة التقديرية للمخزون',
        summary_stock_balance: 'ملخص رصيد المخزون',
        product_average_cost: 'متوسط تكلفة المنتج',
        daily_product_sales: 'مبيعات المنتجات اليومية',
        store_reports: 'تقارير المخازن',
        clients_reports: 'تقارير العملاء',
        customer_appointment_report: 'تقرير مواعيد العميل',
        customer_sales_report: 'تقرير مبيعات العميل',
        customer_payments_report: 'تقرير مدفوعات العميل',
        customer_installments: 'أقساط العميل',
        treasuries: 'الخزينة',
        customer_balance_report: 'تقرير رصيد العميل',
        aged_debtors: 'العملاء المدينون',
        sales_by_client_report: 'تقرير المبيعات حسب العميل',
        sales_reports: 'تقارير المبيعات',
        sales_by_staff_report: 'تقرير المبيعات حسب الموظف',
        sales_by_sales_person: 'تقرير المبيعات حسب مندوب المبيعات',
        payments_sales_report: 'تقرير مدفوعات المبيعات',
        purchases_reports: 'تقارير المشتريات',
        suppliers_reports: 'تقارير الموردين',
        suppliers_balance_reports: 'تقرير رصيد الموردين',
        supplier_purchase_report: 'تقرير مشتريات الموردين',
        purchases_by_staff_report: 'تقرير المشتريات حسب الموظف',
        purchases_by_supplier_report: 'تقرير المشتريات حسب المورد',
        purchases_invoices_segmented_Reports: 'تقارير فواتير المشتريات المقسمة',
        product_purchase_report: 'تقرير مشتريات المنتج',
        product_purchases_report_by_product: 'تقرير مشتريات المنتج حسب المنتج',
        product_purchases_report_by_supplier: 'تقرير مشتريات المنتج حسب المورد',
        product_purchases_report_by_employee: 'تقرير مشتريات المنتج حسب الموظف',
        purchases_payments_reports: 'تقارير مدفوعات المشتريات',
        daily_purchases_payments_report: 'تقرير مدفوعات المشتريات اليومية',
        weekly_purchases_payments_report: 'تقرير مدفوعات المشتريات الاسبوعية',
        monthly_purchases_payments_report: 'تقرير مدفوعات المشتريات الشهرية',
        yearly_purchases_payments_report: 'تقرير مدفوعات المشتريات السنوية',
        create_tree_chart_with_basic_info: 'إنشاء شجرة حسابات مع المعلومات الأساسية',
        create_simple_tree_chart_with_basic_info: 'إنشاء شجرة حسابات بسيطة مع المعلومات الأساسية',
        create_complex_tree_chart_with_basic_info: 'إنشاء شجرة حسابات مركبة مع المعلومات الأساسية',
        clone_tree_chart_with_basic_info: 'نسخ شجرة حسابات مع المعلومات الأساسية',
        chart_account_setting: 'إعدادات شجرة الحسابات',
        document_type_setting: 'إعداد نوع المستند',
        key_generations_management: 'إدارة اكواد الخدمات',
        journals_setting: 'إعداد القيود',
        currency_exchange: 'إدارة تحويل العملات',
        journal_transactions_reports: 'تقارير القيود',
        accounting_reports: 'تقارير المحاسبة',
        send_message_users: 'إرسال رسالة للمستخدمين',
        all_notifications: 'جميع الإشعارات',
        notifications_config: 'إعدادات الإشعارات',
        custom_notifications: 'إعدادات إشعارات مخصصة',
        treasury_checking: 'مطابقة الخزائن',
        inventory_checking: 'تسوية المخزون',
        treasury_checking_history: 'سجل المطابقة',
        inventory_checking_history: 'سجل تسوية المخزون',
        profit_loss_report: 'تقرير الربح والخسارة',
        income_report: 'تقرير الواردات',
        expenses_report: 'تقرير المصروفات',
        balance_sheet: 'رصيد الميزانية',
        chart_accounts_directory: 'تقرير شجرة الحسابات',
        balance_balances_review: 'تقرير ميزان مراجعة الارصدة',
        tax_report: 'تقرير الضرائب',
        financial_transactions_report: 'تقرير المعاملات المالية',
        items_report: 'تقرير المنتجات',
        activity_log_report: 'سجل نشاط النظام',
        supplier_checking: 'مطابقة رصيد المورد',
        supplier_transactions_report: 'تقرير معاملات المورد',
        customer_transactions_report: 'تقرير معاملات العميل',
        items_transactions_report: 'تقرير حركة المواد',
        export_import_items: 'تصدير واستيراد المنتجات',
        available_features: 'الميزات المتوفرة',
        available_sub_features: 'الميزات الفرعية المتوفرة',
        secondary_accounts_tree: 'شجرة الحسابات الثانوية',
        groups_accounts: 'مجموعات الحسابات',
        chart_account_list: 'قائمة شجرة الحسابات',
        journals_entries_account: 'ادخالات القيود للحساب',
        branch_config: 'إعدادات الفرع',
        workstation: 'محطات العمل',
        manufacturers: 'التــصــنــيـــــــــــع',
        quality_inspections: 'فحوصات الجودة',
        operation_management: 'إدارة العمليات',
        routing_management: 'طريقة العمل',
        bill_of_material: 'فاتورة التصنيع',
        material_list: 'قائمة المواد',
        work_order: 'أمر العمل',
        job_card: 'بطاقة عمل',
        general_group: "المجموعات العامة",
        composite_items: "المنتجات المركبة",
        aspects: "متغيرات المنتج",
        collected_items: "منتجات المجموعة",
        bundle_and_device: "تجميع وتفكيك",
        bundle_divide: 'تجزئة المنتج المركب',
        bundle_divide_list: 'قائمة تجزئة المنتج المركب',
        departments: 'الأقسام',
        module_data_access: 'وحدة الوصول إلى البيانات',
        data_access_configuration: 'تكوين الوصول إلى البيانات',
        default_data: 'البيانات الافتراضية',
        currencies_management: 'إدارة العملات',
        pos_stores: 'المتاجر',
        invoice_printing_without_price: 'طباعة الفاتورة بدون سعر',
        receipt_document: 'وثيقة الإستلام',
        general_sales: 'فواتير المبيعات العامة',
        rent_invoices: "فواتير التأجير",
        general_rent_invoices: "فواتير التأجير العامة",
        expenses_income_transaction: 'سندات الصرف والقبض',
        bank_checking: 'مطابقة رصيد البنوك',
        bank_checking_history: 'سجل المطابقة',
        customer_checking: 'مطابقة حسابات العملاء',
        customer_checking_history: 'سجل المطابقة',
        supplier_checking_history: 'سجل المطابقة',
        general_sales_refund: 'مرتجع فاتورة عامة',
        sales_quotations: 'عروض الاسعار',
        general_sales_quotations: 'عروض الاسعار العامة',
        sales_quotation_requests: 'طلبات عروض الأسعار',
        customer_permissions: 'صلاحيات بيانات العملاء',
        supplier_permissions: 'صلاحيات بيانات الموردين',
        pos_permissions: 'صلاحيات بيانات نقاط البيع',
        sales_tax_settings: 'إعدادات ضرائب المبيعات',
        purchase_tax_settings: 'إعدادات ضرائب المشتريات',
        export_import_customers: 'تصدير واستيراد العملاء',
        receipt_documents: 'وثائق الإستلام',
        payment_purchase_invoices_details: "تفاصيل دفعات المشتريات",
        taxes_rules: "القواعد الضريبية",
        delimiters: "المحددات",
        inventories_management: "إدارة المخازن",
        orders_and_offers_purchases: "طلبات وعروض المشتريات",
        quotes_and_estimates: "عروض الاسعار والتقديرات",
        sales_configuration: "إعدادات المبيعات",
        sales_management: "إدارة المبيعات",
        customers_configurations: "إعدادات العملاء",
        cash_management: "الإدارة النقدية",
        financial_settings: "إعدادات المالية",
        invoice_status: 'حالات الفاتورة',
        import_quantities: 'استيراد الكميات',
        general_settings: 'إعدادات العامة',
        cost_centers_management: 'ادارة مراكز التكلفة',
        journals_management: 'ادارة القيود',
        main_settings: 'الإعدادات الرئيسية',
        taxes_management: 'ادارة الضرائب',
        countries_and_currencies: 'الدول والعملات',
        advanced_settings: 'الإعدادات المتقدمة',
        notifications_and_email: 'الإشعارات والبريد',
        template_and_design: 'القالب والتصميم',
        manufacturers_management: 'ادارة التصنيع',
        laundry_services: 'خدمات المغاسل',
        categories_list: 'قائمة التصنيفات',
        laundry_services_pricing: 'تسعير خدمة المغاسل',
        price_lists_laundry_services: 'قوائم أسعار خدمات المغاسل',
        attached_reasons: 'وصف القطع المستلمة',
        point_of_sales_laundry: 'نقاط البيع مغاسل',
        sales_debits_management: "إشعار مدين",
        general_sales_credits_management: "إشعار دائن عام",
        general_sales_debits_management: "إشعار مدين عام",
        laundry_services_colors: "ألوان القطع المستلمة",
        print_settings_details: "تفاصيل اعدادت الطباعة",
        sales_via_interval_report: 'مبيعات فترة محددة',
        cash_categories: 'الفئات النقدية',
        invoice_bond_configuration: 'إعدادات الفواتير والسندات',
        invoices_bonds_management: 'إدارة الفواتير والسندات',
        cancel_partial_reservation: 'إلغاء حجز جزئي',
        basic_status_management: 'إدارة الحالات',
        document_status_paths: 'مسار الوثيقة',
        status_mapping: 'تخطيط الحالات',
        connected_printers: 'الطابعات المتصلة',
        cash_closing: 'اقفال النقدية',
        income_expenses_report: 'تقرير سندات الصرف والقبض',
        tax_declaration_report: 'الاقرار الضريبي',
        simple_customer_balance_report: 'تقرير رصيد عملاء مبسط',
        fabric_problems: 'مشاكل القماش',
    },
    AUTH: {
        GENERAL: {
            OR: "Or",
            SUBMIT_BUTTON: "Submit",
            NO_ACCOUNT: "Don't have an account?",
            SIGNUP_BUTTON: "Sign Up",
            FORGOT_BUTTON: "Forgot Password",
            BACK_BUTTON: "Back",
            PRIVACY: "Privacy",
            LEGAL: "Legal",
            CONTACT: "Contact"
        },
        LOGIN: {
            TITLE: "Login Account",
            BUTTON: "Sign In"
        },
        FORGOT: {
            TITLE: "Forgot Password?",
            DESC: "Enter your email to reset your password",
            SUCCESS: "Your account has been successfully reset."
        },
        REGISTER: {
            TITLE: "Sign Up",
            DESC: "Enter your details to create your account",
            SUCCESS: "Your account has been successfuly registered."
        },
        INPUT: {
            EMAIL: "Email",
            FULLNAME: "Fullname",
            PASSWORD: "Password",
            CONFIRM_PASSWORD: "Confirm Password",
            USERNAME: "Username"
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
    },
    ECOMMERCE: {
        COMMON: {
            SELECTED_RECORDS_COUNT: "Selected records count: ",
            ALL: "All",
            SUSPENDED: "Suspended",
            ACTIVE: "Active",
            FILTER: "Filter",
            BY_STATUS: "by Status",
            BY_TYPE: "by Type",
            BUSINESS: "Business",
            INDIVIDUAL: "Individual",
            SEARCH: "Search",
            IN_ALL_FIELDS: "in all fields"
        },
        ECOMMERCE: "eCommerce",
        CUSTOMERS: {
            CUSTOMERS: "Customers",
            CUSTOMERS_LIST: "Customers list",
            NEW_CUSTOMER: "New Customer",
            DELETE_CUSTOMER_SIMPLE: {
                TITLE: "Customer Delete",
                DESCRIPTION: "Are you sure to permanently delete this customer?",
                WAIT_DESCRIPTION: "Customer is deleting...",
                MESSAGE: "Customer has been deleted"
            },
            DELETE_CUSTOMER_MULTY: {
                TITLE: "Customers Delete",
                DESCRIPTION: "Are you sure to permanently delete selected customers?",
                WAIT_DESCRIPTION: "Customers are deleting...",
                MESSAGE: "Selected customers have been deleted"
            },
            UPDATE_STATUS: {
                TITLE: "Status has been updated for selected customers",
                MESSAGE: "Selected customers status have successfully been updated"
            },
            EDIT: {
                UPDATE_MESSAGE: "Customer has been updated",
                ADD_MESSAGE: "Customer has been created"
            }
        }
    },
    open_stock_quantity_total_before: 'إجمالي الكمية الافتتاحية قبل',
    open_stock_quantity_total_after: 'إجمالي الكمية الافتتاحية بعد',
    available_stock_qty_before: 'كميات متوفرة للبيع قبل',
    available_stock_qty_after: 'كميات متوفرة للبيع بعد',
    stock_on_hand_qty_before: 'كميات فعلية في المخزن قبل',
    stock_on_hand_qty_after: 'كميات فعلية في المخزن بعد',
    sales_booking_before: 'حجز المبيعات قبل',
    sales_booking_after: 'حجز المبيعات بعد',
    purchase_on_way_before: 'مشتريات على الطريق قبل',
    purchase_on_way_after: 'مشتريات على الطريق بعد',
    committed_qty_before: 'الكمية السالبة قبل',
    committed_qty_after: 'الكمية السالبة بعد',
    booked_before: 'حجز التأجير قبل',
    booked_before_after: 'حجز التأجير بعد',
    booking_before: 'تسليم حجر التأجير قبل',
    booking_after: 'تسليم حجر التأجير بعد',
    type_type: 'نوع المستند',
    type_id: 'رقم المستند',
    type_status_name: 'حالة المستند',
    more_details: 'المزيد من التفاصيل',
    payment_rollback: 'تراجع عن الدفع',
    item_table_settings: 'اعدادات جدول المنتجات',
    do_you_want_to_payment_rollback: 'هل تريد التراجع عن الدفع؟',
    bonds: 'السندات',
    payment_details: 'تفاصيل الدفع',
    the_payments: 'الدفعات',
    back_off_the_opening_balance: 'تراجع عن الرصيد الإفتتاحي',
    are_you_sure_that_you_want_to_retract_from_the_opening_balance: 'هل أنت متأكد أنك تريد التراجع عن الرصيد الإفتتاحي؟',
    next_item: 'المنتج التالي',
    previous_item:'المنتج السابق',
    generate_token: 'إنشاء توكن',
    security_key: 'مفتاح الأمان',
    method_name: 'اسم الميثود',
    method_type: 'نوع الميثود',
    add_api_internal_configurations: 'إضافة إعدادات API الداخلية',
    main_link: 'الرابط الرئيسي',
    operation_date: 'تاريخ العملية',
    add_quantities: 'اضافة كميات',
    price_type: 'نوع السعر',
    subscripers: 'المشتركين',
    treasury_or_bank: 'الخزينة او البنك',
    subscription_info: 'تفاصيل الاشتراك',
    add_subscriber: 'اضافة مشترك',
    add_subscribers: 'اضافة مشتركين',
    size: 'المقاسات',
    no_items_found: 'لا يوجد منتجات',
    height: 'الطول',
    width: 'العرض',
    margin: 'الهامش',
    padding: 'التباعد',
    label_per_row: 'عدد العناصر في الصف',
    unit_standard: 'وحدة القياس',
    min_price: 'أقل سعر',
    undefined: 'غير محدد',
    scale_type: 'نوع القياس',
    no_services: 'لا يوجد خدمات',
    laundry_subscription_view: 'تفاصيل اشتراك مغاسل',
    times_no: 'عدد المرات',
    descriptions: 'الوصف',
    add_laundry_subscription: 'اضافة اشتراك مغاسل',
    edit_laundry_subscription: 'تعديل اشتراك مغاسل',
    end_date: 'تاريخ الانتهاء',
    start_date: 'تاريخ البدء',
    max_customers: 'الحد الأقصى للعملاء',
    Do_you_want_delete_it: 'هل انت موافق علي الحذف؟',
    Confirm: 'تاكيد',
    save_add_quantity: 'احفظ واضف الكميه',
    save_only: 'حفظ فقط',
    Cancel: 'الغاء',
    ok: 'موافق',
    close: 'اغلاق',
    save: 'حفظ',
    cancel: 'الغاء',
    actions: 'الإجراءات',
    Something_went_wrong: 'هناك خطأ ما',
    Filter: 'فلتر',
    Search_query: 'البحث السريع',
    Search: 'بحث',
    Loading: 'تحميل',
    edit: 'تعديل',
    delete: 'حذف',
    back: 'رجوع',
    records: 'سجل',
    yes: 'نعم',
    no: 'لا',
    all: 'الكل',
    advanced_filter: 'فلتر متقدم',
    reset_search: 'إعادة تعيين البحث',
    main: 'رئيسي',
    others: 'اخرى',
    active: 'مفعل',
    inactive: 'غير مفعل',
    client: 'عميل',
    insurance_agent: 'وكيل تأمين',
    status: 'الحالة',
    is_default: 'افتراضي',
    internal: 'داخلى',
    external: 'خارجي',
    created_at: 'تاريخ الإنشاء',
    upload_logo: 'تحميل شعار',
    add_more: 'اضف المزيد',
    bring_all_items: 'جلب جميع المنتجات',
    from_date: 'من تاريخ',
    to_date: 'الى تاريخ',
    export: 'تصدير',
    excel: 'اكسل',
    generate_pdf: 'تصدير PDF',
    custom_export: 'تصدير مخصص',
    select_all: 'اختيار الكل',
    add_new: 'اضف جديد',
    change_status: 'تغيير الحالة',
    Hi: 'مرحباً',
    User_Profile: 'ملف تعريفي للمستخدم',
    Sign_out: 'تسجيل الخروج',
    not_selected: 'غير مختار',
    more_actions: 'إجراءات إضافية',
    extra_options: 'خيارات إضافية',
    all_transactions: 'كل المعاملات',
    no_data_to_show: 'لا يوجد بيانات لعرضها',
    clone: 'نسخة',
    link: 'الرابط',
    finished: 'مكتمل',
    unfinished: 'غير مكتمل',
    must_last_serial_be_grater_than_first_serial: 'يجب أن يكون الرقم التسلسلي الأخير أكبر من الرقم التسلسلي الأول والفرق بينهما 150',
    Added_By: 'أضيفت بواسطة',
    unpaid: 'غير مدفوعة',
    paid: 'مدفوع',
    products: 'المنتجات',
    clients: 'العملاء',
    invoice: 'فاتورة',
    search: 'بحث',
    print_last_invoice: 'طباعة آخر فاتورة',
    session_validate: 'التحقق من صحة الجلسة',
    product_information: 'معلومات المنتج',
    payment: 'الدفع',
    add_client: 'إضافة عميل',
    payment_method: 'طريقة الدفع',
    payment_amount: 'مبلغ الدفع',
    payment_list: 'قائمة الدفع',
    discount: 'خصم',
    discounts: 'الخصومات',
    order_details: 'تفاصيل الطلب',
    cash_payment: 'دفع نقدي',
    from: 'من',
    amount: 'المبلغ',
    paid_by: 'المدفوع بواسطة',
    received_by: 'المستلمة بواسطة',
    number_no: 'رقم.',
    signature: 'التوقيع',
    do_you_want_make_it_as_draft: 'هل تريد جعله كمسودة؟',
    do_you_want_to_close_session: 'هل تريد إغلاق الجلسة؟',
    no_transactions_found: 'لم يتم العثور على معاملات',
    no_invoices_found: 'لم يتم العثور على فواتير',
    no_credit_found: 'لم يتم العثور على اشعارات ائتمان',
    no_refund_receipts_found: 'لم يتم العثور على إيصالات المرتجع',
    no_payments_found: 'لم يتم العثور على مدفوعات',
    pos_client: 'عميل نقاط البيع',
    screen: 'شاشة',
    print: 'طباعة',
    pdf: 'PDF',
    setup_installment_agreement: 'اتفاقية تقسيط الإعداد',
    create_credit_note: 'مذكرة الائتمان',
    assign_costs_centers: 'تعيين مراكز التكاليف',
    qty: 'الكمية',
    accounts: 'الحسابات',
    name: 'الاسم',
    type: 'النوع',
    user: 'المستخدم',
    notes: 'ملاحظات',
    abbreviation: 'الاختصار',
    email: 'البريد الالكتروني',
    key: 'مفتاح',
    category: 'التصنيف',
    date: 'التاريخ',
    customer: 'العميل',
    paginationLimit: 'السجلات',
    paginationCount: 'عدد',
    paginationFirst: 'أولاً',
    paginationLast: 'آخر',
    paginationFilter: 'الفلتر',
    paginationPage: 'صفحة',
    paginationNoResult: 'لا يوجد نتيجة',
    paginationFilterBy: 'التصنيف بواسطة',
    paginationLoading: 'جار التحميل',
    paginationDefaultOption: 'الخيار الافتراضي',
    No_sub_options: 'لا توجد خيارات فرعية.',
    No_options_available: 'لا توجد خيارات متاحة.',
    No_results_found: 'لم يتم العثور على نتائج...',
    Select: 'اختر...',
    added_by: 'أضيفت بواسطة',
    currency: 'العملة',
    view: 'عرض',
    mark_as_draft: 'تحديد كمسودة',
    add_payment: 'اضافة دفع',
    options: 'خيارات',
    total: 'المجموع',
    quantity: 'الكمية',
    price: 'السعر',
    confirm: 'تأكيد',
    customer_search: 'البحث عن العملاء',
    customer_name: 'اسم العميل',
    start_session: 'بدأ الجلسة',
    create_invoice: 'انشاء فاتورة',
    pos_session_n: 'جلسة نقطة البيع',
    invoices: 'الفواتير',
    clients_n: 'العملاء',
    new_client: 'عميل جديد',
    sales: 'المبيعات',
    payments_summary: 'ملخص المدفوعات',
    profit_and_loss: 'الربح والخسارة',
    income_and_expenses: 'الدخل والمصروفات',
    recent_invoices: 'الفواتير الحديثة',
    overdue_invoices: 'الفواتير المتأخرة',
    payment_statistics: 'إحصائيات الدفع',
    invoice_search: 'البحث عن فاتورة',
    invoice_number: 'رقم الفاتورة',
    recent_payments: 'المدفوعات الحديثة',
    low_stock_products: 'المنتجات منخفضة المخزون',
    last_appointments: 'آخر المواعيد',
    view_all: 'مشاهدة الكل',
    activity_logs: 'سجلات النشاط',
    num_movements: 'عدد الحركات',
    available: 'متاح',
    out_of_stock: 'نفذت الكمية',
    expense: 'المصروفات',
    income: 'الدخل',
    refund: 'المرتجع',
    low_of_stock: 'الكمية منخفضة',
    to: 'الى',
    details: 'التفاصيل',
    daily: 'يومي',
    weekly: 'أسبوعي',
    monthly: 'شهريا',
    yearly: 'سنوي',
    employee: 'الموظف',
    select_filter: 'اختر فلتر',
    revenue_invoice: 'إيرادات الفواتير',
    collected_by: 'جمع بواسطة',
    supplier: 'المورد',
    brand: 'العلامة التجارية',
    product: 'المنتج',
    show_draft: 'عرض المسودة',
    staff: 'الموظف',
    daily_products: 'المنتجات اليوميه',
    summery: 'ملخص',
    time: 'الوقت',
    group_by: 'تجميع حسب',
    due_date: 'تاريخ الاستحقاق',
    order_by: 'ترتيب حسب',
    asc: 'تصاعدي',
    desc: 'تنازلي',
    partial_paid: 'مدفوعة جزئيا',
    overdue: 'متأخر',
    summary: 'الملخص',
    sales_summary: 'ملخص المبيعات',
    sales_man: 'مندوب المبيعات',
    daily_sales: 'المبيعات اليومية',
    weekly_sales: 'المبيعات الأسبوعية',
    monthly_sales: 'المبيعات الشهرية',
    yearly_sales: 'المبيعات السنوية',
    payments_sales_by_client_reports: 'تقارير مدفوعات المبيعات حسب العميل',
    payments_sales_by_staff_reports: 'تقارير مدفوعات المبيعات حسب الموظفين',
    payments_sales_by_payment_method_reports: 'تقارير مدفوعات المبيعات حسب طريقة الدفع',
    payments_sales_by_daily_reports: 'تقارير دفعات المبيعات اليومية',
    payments_sales_by_weekly_reports: 'تقارير دفعات المبيعات الأسبوعية',
    payments_sales_by_monthly_reports: 'تقارير دفعات المبيعات الشهرية',
    payments_sales_by_yearly_reports: 'تقارير دفعات المبيعات سنويا',
    payments_periodic_reports: 'تقارير دفعات المبيعات الدورية',
    payments_segmented_reports: 'تقارير دفعات المبيعات المقسمة',
    sales_periodic_reports: 'تقارير فواتير المبيعات الدورية',
    sales_segmented_reports: 'تقارير فواتير المبيعات المقسمة',
    display_report: 'عرض التقرير',
    purchases_summary: 'ملخص المشتريات',
    products_purchases_summary: 'ملخص مبيعات المنتجات',
    prefix: 'البادئة',
    digits: 'الأرقام',
    phone: 'الهاتف',
    enter: 'ادخل',
    please_upload_valid_file: 'الرجاء تحميل ملف صحيح',
    load_more: 'إظهار المزيد',
    journal_details: 'تفاصيل القيود',
    the_entries_should_contain_at_least_one_credit_and_one_debit: 'يجب ان تحتوى القيود علي الاقل واحد دائن واخر مدين',
    branch: 'الفروع',
    inventory: 'المخازن',
    revert: 'تراجع',
    confirm_revert: 'هل انت متاكد من تراجع العملية ؟',
    exchange_rate: 'سعر الصرف',
    nothing: 'لاشئ',
    user_send_message: 'ارسال رسالة للمستخدمين',
    all_users: 'المستخدمين',
    send_mail_to_customer: 'ارسال بريد الى العميل',
    customer_mail: 'بريد العميل',
    title: 'العنوان',
    body: 'محتوي الرسالة',
    attachments: 'المرفقات',
    send: 'ارسال',
    send_mail_share: 'ارسال بريد | مشاركة',
    send_mail: 'ارسال بريد',
    share: 'مشاركة',
    bcc: 'Bcc',
    cc: 'Cc',
    content: 'محتوي الرسالة',
    additional_info: 'معلومات إضافية',
    customer_info: 'معلومات العميل',
    company_info: 'معلومات الشركة',
    make_all_read: 'جعل الكل مقروء',
    unread_notifications: 'إشعار غير مقروء',
    create: 'إنشاء',
    update: 'تعديل',
    do_you_want_make_it_approved: 'هل تريد اعتماد القيمة؟',
    approve: 'إعتماد القيمة',
    increase: 'زيادة القيمة',
    decrease: 'نقصان القيمة',
    use_multi_currency: 'استخدام لإنشاء عملات متعددة',
    import_status: 'تفاصيل الاستيراد',
    no_errors: 'لايوجد اخطاء',
    type_to_search: 'اكتب للبحث',
    download: 'تنزيل',
    save_as_draft: 'حفظ كمسودة',
    save_as_confirm: 'حفظ كتأكيد',
    material_validation: 'التحقق من المواد',
    Do_you_want_start_this_operation: 'هل تريد تفعيل هذه العملية؟',
    Do_you_want_stop_this_operation: 'هل تريد إيقاف هذه العملية؟',
    Do_you_want_start_work_order: 'هل تريد تفعيل أمر العمل؟',
    Do_you_want_stop_work_order: 'هل تريد إيقاف أمر العمل؟',
    start: 'ابدأ',
    stop: 'إيقاف',
    complete: 'مكتمل',
    restart: 'اعادة البدء',
    import_data: 'استيراد بيانات',
    composite_items: "المنتجات المركبة",
    generate_items: "انشاء منتجات",
    total_items: "اجمالي منتجات",
    delete_selected: "حذف المحدد",
    skip: 'تخطي',
    copied: 'تم نسخ',
    copy: 'نسخ',
    save_without_permission: 'حفظ واضافة صلاحيات لاحقا',
    save_with_permission: 'حفظ واضافة الصلاحيات',
    account_information: 'بيانات الحساب',
    quick_actions: 'إجراءات سريعة',
    you_need_to_create_account_tree_to_use_this_module: 'يجب عليك انشاء سنة مالية مفعلة، ثم انشاء شجرة الحسابات اما شجرة مخصصة او شجرة عامة يتم انشاءؤها من قبل النظام.',
    accounting_tree: 'شجرة الحسابات العامة',
    select_time_after: 'اختر وقت بعد',
    you_must_adjust_the_system_settings_before_starting_to_use_it: 'يجب عليك ضبط إعدادات النظام قبل البدء في استخدامه، من خلال التالي:',
    type_at_least_three_letters: 'الرجاء كتابة ثلاثة أحرف على الأقل لبدء البحث عن',
    // type_at_least_three_letters : 'أكتب 3 أحرف للبحث عن',
    select_doc_type: 'اختر نوع الوثيقة حتى تظهر',
    day: 'يوم',
    month: 'شهر',
    year: 'سنة',
    browse: 'متصفح',
    services_products: 'منتجات وخدمات',
    products_only: 'منتجات فقط',
    services_only: 'خدمات فقط',
    select_currencies_allowed_outer: 'اختر من قائمة العملات المسموح بها لعرض العملات',
    you_must_choose_a_inventory_before_searching_for_products: 'يجب عليك اختيار مخزن قبل البحث عن المنتجات',
    settlement_quantities: 'تسوية الكميات',
    do_you_agree_to_the_settlement_process: 'هل توافق علي عملية التسوية؟',
    do_you_want_the_store_to_be_affected_by_new_products_and_quantities: 'هل تريد ان يتاثر المخزن بالمنتجات والكميات الجديدة ؟',
    no_balances: 'لايوجد ارصدة',
    no_items: 'لايوجد منتجات',
    mobile: 'الجوال',
    address: 'العنوان',
    country: 'الدولة',
    city: 'المدينة',
    transfer_to_purchase_invoice: 'تحويل الى فاتورة مشتريات',
    balance: 'رصيد',
    amount_due: 'مبلغ مستحق',
    select_inventory_to_get_qty: 'اختر مخزن لاظهار الكميات بناءً علي المحزن المختار',

    item: 'المنتج',
    qty_before: 'الكمية قبل',
    qty_after: 'الكمية بعد',
    stock_on_hand_qty: 'إجمالي الكمية الفعلية',
    available_stock_qty: 'الكمية المتوفرة في المخزن',
    stock_change_control_log: 'سجل مراقبة تغير المخزون',
    basic_information: 'المعلومات الاساسية',
    account_statement: 'كشف حساب',
    treasury: 'الخزينة',
    previous: 'السابق',
    next: 'التالي',
    please_choose_any_file: 'من فضلك اختر ملف',
    please_select_a_valid_csv_file: 'من فضلك اختر ملف بصيغة csv',
    the_data_import_process_is_in_progress: 'يتم الان عملية إستيراد البيانات',
    the_data_import_process_is_complete: 'تمت عملية إستيراد البيانات',
    import_options: 'خيارات الإستيراد',
    define_fields: 'تحديد الحقول',
    results: 'النتائج',
    import_again: 'إستيراد مرة أخرى',
    export_template: 'تصدير القالب',
    do_you_want_to_delete_all_system_products: 'هل تريد حذف جميع منتجات النظام؟ وبذلك لن تتمكن من استعادتها مرة اخرى.',
    delete_all_items_system: 'حذف جميع منتجات النظام',
    business_record_attachment: 'مرفق السجل التجاري',
    tax_no: 'الرقم الضريبي',
    business_record: 'السجل التجاري',
    invoice_type: 'نوع الفاتورة',
    sale_invoice: 'فاتورة مبيعات',
    sale_invoice_pos: 'فاتورة مبيعات نقاط بيع',
    discount_and_settlement: 'الخصم والتسوية',
    delivery_information: 'بيانات التوصيل',
    deposit: 'الإيداع',
    price_list_and_accounts: 'قائمة الأسعار والحسابات',
    credit: 'دائن',
    debit: 'مدين',
    export_invoice_pdf: 'تصدير الفاتورة PDF',
    export_pdf: 'تصدير PDF',
    export_pdf_without_price: 'تصدير PDF بدون سعر',
    search_and_print: 'بحث وطباعة',
    search_and_export_pdf: 'بحث وتصدير PDF',
    customer_data_details: 'تفاصيل بيانات العميل',
    supplier_data_details: 'تفاصيل بيانات المورد',
    employee_data_details: 'تفاصيل بيانات الموظف',
    is_show_main_name_company_in_print: 'اضافة هوية سجلها على الفواتير',
    is_show_setting_message: 'ظهور رسالة إعدادت النظام',
    identity_name_for_invoice: 'نص هوية سجلها على الفواتير',
    category_laundry: 'تصنيفات المغاسل',
    balances: 'الأرصدة',
    settlement_treasury: 'خزينة تسوية',
    not_specified: 'غير محدد',
    shipping_address: 'عنوان الشحن',
    customer_address: 'عنوان العميل',
    add_new_customer_address: 'اضافة عنوان جديد للعميل',
    are_you_sure_to_termination_of_receipt: 'هل تريد إنهاء إستلام هذه الفاتورة؟',
    tax_invoice_printing: 'فاتورة ضريبية',
    export_tax_invoice_pdf: 'تصدير الفاتورة الضريبية PDF',
    applicable_taxes: 'الضرائب المطبقة',
    paid_amount: 'المبلغ المدفوع',
    paid_amount_label: 'المدفوع',
    current_payment: 'الدفع الحالي',
    already_paid: 'مدفوع بالفعل',
    tax_invoice_printing_simplified: 'فاتورة ضريبية مبسطة',
    export_tax_invoice_pdf_simplified: 'تصدير الفاتورة الضريبية المبسطة PDF',
    invoice_and_procedures: 'الفاتورة  واجراءات',
    shipping_and_delivery: 'الشحن والتوصيل',
    return_and_payment: 'الإرجاع والدفع',
    refund_fully_invoice: 'ارجاع الفاتورة بالكامل',
    this_invoice_cant_be_editing: 'هذه الفاتورة لا يمكن تعديلها',
    add_payment_type: 'أضف نوع دفع',
    payment_type: 'نوع الدفع',
    payment_type_multi: 'متعدد',
    unified: 'موحد',
    you_cannot_change_the_status_Please_check_with_your_system_administrator: 'لا يمكنك تغيير الحالة، الرجاء مراجعة مدير النظام',
    confirm_invoice: 'تأكيد الفاتورة',
    name_holder_of_check: 'اسم صاحب الشيك',
    check_number: 'رقم الشيك',
    bank: 'البنك',
    orders: 'طلبات',
    pieces: 'قطع',
    refresh_orders: 'تحديث الطلبات',
    change_the_delivery_date: 'تغيير موعد التسليم',
    delivery_date: 'موعد التسليم',
    received_and_delivery_dates: 'موعد الإستلام والتسليم',
    received: 'موعد الإستلام',
    delivery: 'موعد التسليم',
    arrange_orders: 'ترتيب الطلبات',
    add_urgent_order_time: 'تحديد وقت المستعجل',
    the_value_of_the_entered_amount_is_higher_than_the_value_of_the_requested_amount: 'قيمة المبلغ المدخل اعلى من قيمة المبلغ المطلوب',
    discount_must_be_less_than_or_equal_total_invoice: 'يجب ان يكون الخصم اقل او يساوي الاجمالي للفاتورة',
    fetch_all_items: 'جلب جميع المنتجات',
    is_apply_taxes_invoice: 'تطبيق الضريبة علي الفاتورة',
    minimum: 'أقل قيمة',
    maximum: 'أعلى قيمة',
    shipping: 'الشحن',
    delivery_and_received: 'موعد الإستلام والتسليم',
    pricing_information: 'معلومات المالية',
    remove_all: 'حذف الكل',
    reset_default_values: 'اعادة تعيين القيم الإفتراضية',
    reset_default_values_alert_message: 'هل تريد اعادة تعيين القيم الإفتراضية؟',
    you_should_select_supplier_to_display_the_report: 'يجب عليك اختيار مورد لإظهار التقرير',
    subtotal_before_tax: 'المبلغ قبل الضريبة',
    please_select_customer: 'الرجاء اختيار عميل',
    edit_qty: 'تعديل الكمية',
    quantity_details: 'تفاصيل الكميات',
    deleted_invoices: 'الفواتير الملغاة',
    deferred: 'آجل',
    cash: 'نقدي',
    invoice_is_tax: 'الفاتورة ضريبية',
    invoice_is_non_tax: 'الفاتورة غير ضريبية',
    show: 'عرض',
    this_is_item_selected_before: 'لقد تم اختيار هذا المنتج بالفعل',
    row_numbers: 'عدد الأسطر',
    adjustment_customer_statement: 'ضبط كشف العميل',
    adjust: 'اعادة احتساب',
    adjustment: 'ضبط',
    barcode: 'باركود',
    rollback_the_customer_receipt: 'التراجع عن سند القبض للعميل',
    rollback_the_supplier_receipt: 'التراجع عن سند القبض للمورد',
    rollback_the_customer_expense: 'التراجع عن سند الصرف للعميل',
    rollback_the_supplier_expense: 'التراجع عن سند الصرف للمورد',
    do_you_want_rollback: 'هل تريد التراجع؟',
    adjust_payment: 'ضبط الدفع',
    are_you_sure_that_you_want_to_adjust_customer_balance: 'هل انت متأكد من ضبط  رصيد العميل؟',
    customer_balance_adjustment: 'ضبط رصيد العميل',
    zero_quantities_cannot_be_accepted: 'لا يمكن قبول كميات صفرية',
    quantity_must_be_greater_than_0: 'يجب ان تكون الكمية اكبر من 0',
    unit_price_must_be_greater_than_0: 'يجب ان يكون سعر الوحدة اكبر من 0',
    treatment: 'المعالجة',
    rollback_after_confirm: 'التراجع بعد التأكيد',
    id: 'المعرف',
    save_and_create_new: 'حفظ وانشاء جديد',
    save_and_redirect_to_main_page: 'حفظ والعودة للقائمة الرئيسية',
    taxes: 'الضرائب',
    general_tax: 'ضريبة عامة',
    minimum_unit: 'اقل وحدة',
    filter_status: 'فلتر الحالة',
    invoice_date: 'تاريخ الفاتورة',
    status_to_be_converted_to: 'الحالة المراد التحويل لها',
    unable_to_send_due_to_data_entry_error: 'لا يمكن الارسال بسبب خطأ في ادخال البيانات',
    the_item_field_must_be_required: 'حقل المنتج مطلوب',
    the_description_field_must_be_required: 'حقل الوصف مطلوب',
    the_value_must_not_be_less_than_0: 'يجب أن لا تكون القيمة أقل من او تساوي 0',

    global: {
        all: 'اختر',
        actions: 'الإجراءات',
        yes: 'نعم',
        no: 'لا',
    },
    roles: {
        roles_management_section: 'قسم ادارة الصلاحيات',
        role_name: 'اسم الصلاحية',
        description: 'الوصف',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
        new_role: 'صلاحية جديد',
        role: 'صلاحية',
        edit_role: 'تعديل الصلاحية',
    },
    users: {
        users_management: 'قسم إدارة المستخدمين',
        users: 'المستخدمين',
        user_name: 'اسم المستخدم',
        name: 'الاسم',
        phone: 'الهاتف',
        email: 'البريد الإلكتروني',
        password: 'كلمة المرور',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
        new_user: 'مستخدم جديد',
        user: 'مستخدم',
        edit_user: 'تعديل المستخدم',
        roles: 'الصلاحيات',
        description: 'الوصف',
        first_name: 'الاسم الأول',
        last_name: 'الاسم الأخير',
        countries: 'الدول',
        cities: 'المدن',
        confirm_password: 'تأكيد كلمة المرور',
        active: 'نشط',
        inactive: 'غير نشط',
        add_user: 'إضافة مستخدم',
        pl_first_name: 'ادخل الاسم الاول',
        pl_last_name: 'ادخل اسم الاخير',
        pl_email: 'ادخل البريد الإلكتروني',
        pl_password: 'ادخل كلمة المرور',
        pl_confirm_password: 'ادخل تأكيد كلمة المرور',
        pl_phone: 'ادخل رقم الهاتف',
        pl_roles: 'اختر الصلاحيات',
        pl_username: 'ادخل اسم المستخدم',
        inventory: 'المخزن',
        branch: 'الفرع',
    },
    units: {
        units_management: 'قسم إدارة الوحدات',
        name: 'اسم الوحدة',
        description: 'الوصف',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
        new_unit: 'وحدة جديدة',
        unit: 'وحدة',
        is_active: 'الحاله',
        edit_unit: 'تعديل الوحدة',
        abbreviation: 'الاختصار',
        sub_value_total: 'القيمة الفرعية',
        active: 'نشط',
        inactive: 'غير نشط',
        Notes: 'ملاحظات',
        default: 'الافتراضي',
        the_main: 'الرئيسي',
    },
    currencies: {
        currencies_management: 'قسم إدارة العملات',
        name: 'اسم العملة',
        code: 'كود العملة',
        currency: 'عملة',
        default: 'الافتراضي',
        active: 'نشط',
        inactive: 'غير نشط',
        is_active: 'الحاله',
        edit_currency: 'تعديل العملة',
        new_currency: 'عملة جديدة',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
    },
    constants: {
        constants_section: 'قسم الثوابت',
        new_constant: 'ثابت جديد',
        name: 'الاسم',
        key: 'مفتاح',
        the_main: 'الرئيسية',
        constant: 'ثابت',
        value: 'القيمة',
    },
    code_setting: {
        code_setting: 'إعدادات الكود',
        new_code_setting: 'اضافة كود جديد',
        type: 'النوع',
        code_prefix: 'بادئة الكود',
        code_method: 'طريقة الكود',
        automatic: 'آلي',
        manual: 'يدوي',
        code_start: 'بدأ من',
        code_type: 'نوع الكود',
        digit_no: 'عدد الأرقام',
        char_no: 'عدد الحروف',
        after_update_prefix: 'عند تغيير البادئة',
    },
    cities: {
        new_city: 'مدينة جديدة',
        cities: 'المدن',
        city_management: 'قسم إدارة المدن',
        city: 'المدينة',
        en_name: 'الاسم بالانجليزية',
        ar_name: 'الاسم بالعربية',
        code2: 'الكود 2',
        code3: 'الكود 3',
    },
    header: {
        my_profile: 'صفحتي الشخصية',
        account_settings_and_more: 'إعدادات الحساب والمزيد',
        change_email_password: 'تغيير البريد الالكتروني وكلمة المرور',
        renewal_of_plan_subscription: 'تجديد الاشتراك في الخطة',
        plan_upgrade: 'ترقية الخطة',
    },
    company: {
        debit_note: 'اشعار المدين',
        company_profile: 'ملف الشركة',
        basic_information: 'المعلومات الاساسية',
        business_name: 'الاسم التجاري',
        business_name_en: 'الاسم التجاري (بالانجليزي)',
        business_type: 'نوع العمل',
        currency: 'عملة',
        language: 'لغة',
        time_zone: 'وحدة زمنية',
        date_format: 'صيغة التاريخ',
        printing_method: 'طريقة الطباعة',
        upload_logo: 'تحميل الشعار',
        address_information: 'معلومات العنوان',
        country: 'دولة',
        city: 'مدينة',
        postal_code: 'رمز بريدي',
        district: 'الحي',
        address_1: 'العنوان 1',
        address_2: 'العنوان 2',
        mobile: 'الجوال',
        phone: 'هاتف',
        email: 'البريد الإلكتروني',
        first_name: 'الاسم الاول',
        last_name: 'الاسم الأخير',
        contact_information: 'معلومات التواصل',
        account_holder_name: 'اسم الحساب البنكي',
        account_holder_number: 'رقم الحساب البنكي',
        iban: 'الآيبان',
        bank_name: 'اسم البنك',
        bank_branch: 'فرع البنك',
        banking_data: 'البيانات المصرفية',
    },
    branches: {
        basic_information: 'المعلومات الاساسية',
        branch_name: 'اسم الفرع',
        address_information: 'معلومات العنوان',
        country: 'الدولة',
        city: 'المدينة',
        postal_code: 'رمز بريدي',
        district: 'الحي',
        address_1: 'العنوان 1',
        address_2: 'العنوان 2',
        mobile: 'الجوال',
        phone: 'هاتف',
        email: 'البريد الإلكتروني',
        fax: 'فاكس',
        contact_information: 'معلومات التواصل',
        manager_name: 'اسم المدير',
        branches_section: 'قسم الفروع',
        new_branch: 'فرع جديد',
        branch: 'الفرع',
        state: 'المقاطعة',
        no_inventories: 'عدد المخازن',
        no_employee: 'عدد الموظفين',
    },
    smtp: {
        sender_name: 'اسم المرسل',
        email: 'البريد الالكتروني',
        main_password: 'كلمة المرور الرئيسية',
        smtp_user_name: ' اسم المستخدم SMTP',
        smtp_service_provider: 'مزود الخدمة SMTP',
        smtp_port: 'المنفذ SMTP',
        ssl_required: 'SSL استخدام',
        use_smtp: 'استخدام SMTP',
        SMTP_Setting: 'بروتوكول SMTP',
    },
    barcode_setting: {
        type: 'النوع',
        number_parts: 'خانات الباركود',
        number_symbols: 'مقاطع الباركود',
        remove_zero_item_code: 'ازالة القيمة الصفرية',

        number_part: 'رقم الجزء',
        symbols_type: 'نوع الجزء',
        symbols_number: 'خانات الكود',
        another_value_symbols: 'قيمة اخرى للجزء',
        number_symbols_boxes: 'عدد الخانات الكسرية',
        details: 'تفاصيل',
    },

    tax: {
        taxes: 'الضرائب',
        tax_management: 'إدارة الضرائب',
        tax_name: 'اسم الضريبة',
        value_rate: 'معدل القيمة',
        include_tax: 'تشمل الضريبة',
        add_tax: 'إضافة ضريبة',
        status: 'الحالة',
        tax: 'الضريبة',
        edit_tax: 'تعديل الضريبة',
        created_at: 'تاريخ الإنشاء',
        type: 'النوع',
        description: 'الوصف',
        tax_rule: 'القاعدة الضريبية',
        rule_option_value: 'المحدد',
    },

    key_generation: {
        key_generation: 'اكواد الخدمات',
        add_key_generation: 'إضافة اكواد الخدمات',
        name: 'الاسم',
        identity_no: 'رقم الهوية',
        key: 'المفتاح',
    },

    print_setting: {
        print_setting: 'إعدادت الطباعة',
        add_print_setting: 'إضافة نموذج',
        serial_number: 'الرقم التسلسلي',
        name: 'الاسم',
        type: 'نوع الوثيقة',
        upload_attachment: 'تحميل المرفق',
        print_settings_details: 'تفاصيل إعدادت الطباعة',
        template: 'نموذج',
        select_fields_showing: 'اختر الحقول التي تريد اظهارها',
        header_height: 'ارتفاع رأس الصفحة',
        is_multi_language_label: 'استخدام تعدد اللغات للعنوان',
        is_use_watermark: 'استخدام العلامة المائية',
        is_banking_details: 'عرض التفاصيل المصرفية',
        is_payment_details: 'عرض تفاصيل الدفعات',
        is_break_before_footer: 'ادراج فاصل قبل الفوتر',
        footer_font_color: 'لون الفاصل قبل الفوتر',
        usage_policy: 'سياسة الاستخدام',
        is_repeated_header: 'تكرار رأس الصفحة في كل صفحة طباعة',
        is_repeated_footer: 'تكرار تذليل الصفحة في كل صفحة طباعة',
        is_empty_row: 'اضافة اسطر فارغة علي جدول المنتجات',
        is_signature: 'اظهار التواقيع',
        is_show_contact: 'اظهار بيانات التواصل',
        is_show_customer_details: 'اظهار بيانات العميل',
        is_show_terms_and_conditions: 'اظهار الشروط والاحكام',
        print_size: 'حجم الطباعة',
        mm: 'ملم',
        description: 'الوصف بالعربية',
        description_en: 'الوصف بالإنجليزية',
        products_template_design: 'طريقة عرض بيانات المنتجات',
        card_design: 'تصميم بطاقة',
        table_design: 'تصميم جدول',
        subtotal_before_tax: 'المبلغ قبل الضريبة',
        subtotal_after_tax: 'المبلغ شامل الضريبة',
        subtotal_before_discount: 'المبلغ قبل الخصم',
        subtotal_after_discount: 'المبلغ بعد الخصم',
        amount_tax: 'قيمة الضريبة',
        tax_applied: 'نسبة الضريبة',
        discount_calc: 'الخصم',
        unit: 'الوحدة',
        product_table_details: 'بيانات جدول المنتجات',
    },

    payments: {
        payment_method_management: 'إدارة طرق الدفع',
        new_payment: 'طريقة دفع جديدة',
        payment_methods: 'طرق الدفع',
        cash: 'نقدا',
        minimum_amount: 'الحد الأدنى',
        active_for_online_clients: 'نشط للعملاء',
        fees_rate: 'معدل الرسوم',
        fees_fixed: 'رسوم ثابتة',
        status: 'الحالة',
        main: 'الرئيسية',
        others: 'أخرى',
        payment: 'الدفع',
        payment_type: 'نوع الدفع',
        verified: 'مؤكد',
        api_login_id: 'معرف تسجيل الدخول API',
        transaction_key: 'مفتاح المعاملة',
        account_number: 'رقم الحساب',
        approved_url: 'الرابط الموافق عليه',
        paytabs: 'PayTabs',
        authorize_net: 'Authorize.Net',
        checkout: 'الدفع',
        details: 'تفاصيل',
        effect_type: 'التأثير تلقائي',
    },
    inventories: {
        inventories: 'المخازن',
        products: 'المنتجات',
        inventory: 'المخزن',
        basic_information: 'المعلومات الاساسية',
        name: 'اسم',
        branch: 'فرع',
        type: 'النوع',
        inventory_class: 'تصنيف المخزن',
        content_type: 'محتوى المخزن',
        num_employee: 'عدد الموظفين',
        num_work_hours: 'عدد ساعات العمل',
        start_work_time: 'وقت بدء العمل',
        end_work_time: 'إنهاء وقت العمل',
        address_information: 'معلومات العنوان',
        country: 'دولة',
        city: 'مدينة',
        postal_code: 'رمز بريدي',
        district: 'الحي',
        address_1: 'العنوان',
        address_2: 'العنوان البديل',
        mobile: 'الجوال',
        phone: 'هاتف',
        email: 'البريد الإلكتروني',
        fax: 'فاكس',
        contact_information: 'معلومات التواصل',
        inventories_section: 'قسم المخزن',
        new_inventory: 'مخزن جديد',
        manager: 'مدير المخزن',
    },
    brands: {
        brands: 'العلامات التجارية',
        new_brand: 'علامة تجارية جديدة',
        brand: 'علامة تجارية',
        name: 'الاسم',
        notes: 'الملاحظات',
    },
    inventory_class_list: {
        main_inventory: 'مخزن رئيسي',
        factory_inventory: 'مخزن المصنع',
        custom_inventory: 'مخزن مخصص',
        shipping_inventory: 'مخزن الشحن',
        maintenance_inventory: 'مخزن الصيانة',
        temp_inventory: 'مخزن مؤقت',
    },
    content_type_list: {
        raw_materials: 'مواد أولية',
        salable_items: 'المنتجات القابلة للبيع',
        mixed: 'مختلط',
        samples: 'عينات',
        maintenance_materials: 'مواد الصيانة',
    },
    revenue_invoices: {
        id: 'رقم',
        date: 'التاريخ',
        customer: 'العميل',
        employee: 'الموظف',
        paid: 'مدفوعه',
        unpaid: 'غير مدفوعه',
        refund: 'مرتجع',
        total: 'الاجمالي',
        payment_method: 'طريقة الدفع',
        payment_id: 'رقم معرف',
        invoice_code: 'رقم الفاتورة',
        ref_no: 'الرقم المصدر',
        invoice_number: 'رقم الفاتورة',
    },
    api_configurations: {
        base_url: 'الرابط الأساسي',
        service_provider: 'مزود الخدمة',
        service_type: 'نوع الخدمة',
        slug: 'المفتاح',
        credentials: 'بيانات العتماد',
        api_configurations_section: 'قسم إعدادات Api',
        user: 'المستخدم',
        new_api_configuration: 'اضف إعدادات API جديدة',
        attribute: 'الفتاح',
        value: 'قيمة',
        api_configurations: 'إعدادات Api',
        api_endpoint_configurations_section: 'Api endpoint قسم إعدادات',
        protocol: 'البروتوكول',
        new_api_endpoint_configurations: 'اضف إعدادات API endpoint جديدة',
        api_endpoint_configurations: 'Api endpoint إعدادات',
        endpoint_url: 'Endpoint رابط',
        param: 'المفتاح والقيمة',
        endpoint: 'Endpoint',
    },
    service_types_list: {
        payment: 'دفع',
        shipment: 'شحنة',
        call: 'مكالمة',
        sms: 'رسالة قصيرة',
        email: 'البريد الإلكتروني',
        advertisement: 'الإعلانات',
    },
    manufacturers: {
        manufacturers_section: 'قسم المصانع',
        new_manufacturer: 'مصنع جديد',
        name: 'الاسم',
        manufacturer: 'مصنع',
        upload_image: 'تحميل صورة',
        notes: 'الملاحظات',
        user: 'المستخدم',
        category: 'التصنيف',
    },
    categories: {
        categories: 'التصنيفات',
        add_new_category: 'اضافة تصنيف جديد',
        add_new_sub_category: 'اضافة تصنيف فرعي جديد',
        name: 'الاسم',
        description: 'الوصف',
        upload_image: 'تحميل صورة',
        printers: 'طابعات',
        parent_category: 'التصنيف الرئيسي',
        unit: 'الوحدة',
        return_days: 'عدد أيام الإرجاع',

        services: 'الخدمات',
        tax: 'الضريبة',
        service: 'الخدمة',
        quantity: 'الكمية',
        price: 'السعر',
        is_laundries_services: 'تصنيف خدمة مغاسل',
    },
    banks: {
        add_bank: 'اضافة بنك',
        balance: 'الرصيد',
        name: 'الاسم',
        bank_no: 'رمز البنك',
        notes: 'الملاحظات',
        is_active: 'نشط',
        banks: 'البنوك',
        permission: 'صلاحيات',
        withdraw: 'سحب',
        deposit: 'إيداع',
        created_at: 'تاريخ الإنشاء',
        code: 'الكود',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        bank_number: 'الرقم البنكي',
        banks_section: 'قسم البنوك',
        add_open_balance: 'اضافة رصيد افتتاحي',
        open_balance: 'الرصيد الإفتتاحي',
        account_no: 'رقم الحساب',
        currency: 'العملة',
        balances: 'الرصيد',
        currencies_allowed: 'العملات المسموح التعامل بها',
        last_validated_date: 'تاريخ التحقق الأخير',
        iban_no: 'رقم IBAN',
    },
    income_categories: {
        income_categories: 'تصنيفات الدخل',
        add_new_income_category: 'اضافة قئة دخل جديد',
        add_new_sub_income_category: 'اضافة قئة دخل فرعية جديد',
        name: 'الاسم',
        description: 'الوصف',
        upload_image: 'تحميل صورة',
    },
    suppliers: {
        suppliers_management: 'إدارة الموردين',
        suppliers_section: 'قسم الموردين',
        add_supplier: 'إضافة مورد',
        name: 'الاسم',
        supplier_code: 'كود المورد',
        email: 'البريد الإلكتروني',
        mobile: 'الجوال ',
        phone: 'هاتف ',
        country: 'الدولة ',
        postal_code: 'رمز بريدي',
        address: 'العنوان',
        basic_information: 'المعلومات الاساسية',
        business_name: 'الاسم التجاري',
        full_name: 'الاسم كامل',
        first_name: 'الاسم الأول',
        last_name: 'الاسم الأخير',
        telephone: 'الهاتف',
        city: 'المدينة',
        state: 'المقاطعة',
        address_1: 'عنوان الشارع 1',
        address_2: 'عنوان الشارع 2',
        is_active: 'الحالة',
        default_currency: 'العملة الافتراضية',
        open_balance: 'الرصيد الافتتاحي للمورد',
        open_balance_date: 'تاريخ فتح المخزن',
        notes: 'ملاحظات',
        accounting_information: 'المعلومات المحاسبية',
        address_info: 'معلومات العنوان',
        contact_info: 'معلومات التواصل',
        is_default: 'افتراضي',
        contact_list: 'قائمة الإتصال',
        add_new_supplier: 'اضافة مورد جديد',
        activity_log: 'سجل النشاط',
        create_special_account_in_tree_accounting: 'إنشاء حساب خاص في المحاسبة الشجرية',
        account: 'الحساب',
        balances: 'الأرصدة',
        balance: 'الرصيد',
        currency: 'العملة',
        last_validated_date: 'تاريخ التحقق الأخير',
        currencies_allowed: 'العملات المتاحة',
        buisnessno: 'رقم السجل التجاري',
        taxno: 'رقم الضريبة',
        add_open_balance: 'اضافة رصيد افتتاحي',
        credit: 'رصيد المورد',
        debit: 'المبلغ المستحق',
        contact_information: 'معلومات الإتصال',
        address_information: 'معلومات العنوان',
        credit_and_debit: 'الدائن والمدين',
        minimum_credit: 'اقل قيمة للدائن',
        maximum_credit: 'اعلى قيمة للدائن',
        minimum_debit: 'اقل قيمة للمدين',
        maximum_debit: 'اعلى قيمة للمدين',
    },
    customers: {
        buisnessno: 'رقم السجل التجاري',
        supplier_code: 'كود المورد',
        taxno: 'رقم الضريبة',
        customers: 'العملاء',
        add_customer: 'عميل جديد',
        customers_management: 'إدارة العملاء',
        cities: 'المدن',
        countries: 'الدول',
        currencies: 'العملات',
        languages: 'اللغات',
        categories: 'التصنيفات',
        full_name: 'الاسم كامل',
        email: 'البريد الإلكتروني',
        phone: 'الهاتف',
        mobile: 'الجوال',
        is_default: 'هو الافتراضي',
        code: 'الكود',
        address: 'العنوان',
        city: 'المدينة',
        country: 'الدولة',
        postcode: 'الرمز البريدي',
        notes: 'ملاحظات',
        price_list: 'قائمة الاسعار',
        state: 'المقاطعة',
        customer: 'العميل',
        address_1: 'العنوان 1',
        address_2: 'العنوان 2',
        basic_information: 'المعلومات الاساسية',
        address_info: 'عناوين العميل',
        postal_code: 'رمز بريدي',
        type: 'نوع',
        is_active: 'الحالة',
        first_name: 'الاسم الاول',
        last_name: 'الاسم الأخير',
        telephone: 'الهاتف',
        contact_list: 'قائمة الإتصال',
        accounting_information: 'المعلومات المحاسبية',
        add_new_customer: 'اضافة عميل جديد',
        business_name: 'الاسم التجاري',
        opening_balance: 'الرصيد الافتتاحي',
        amount: 'المبلغ',
        date: 'التاريخ',
        currency: 'العملة',
        notes_attachments: 'مرفقات / الملاحظات',
        time: 'الزمن',
        action: 'الحدث',
        status: 'الحالة',
        attachments: 'المرفقات',
        upload_attachment: 'تحميل المرفق',
        file: 'ملف',
        file_link: 'ارتباط الملف',
        share_with_client: 'شارك مع العميل',

        attachment: 'المرفق',
        payment_status: 'حالة السداد',
        user_id_collected_by: 'جمع بواسطة',
        reference_no: 'رقم المرجع',
        payment_details: 'بيانات الدفع',
        receipt_note: 'مذكرة استلام',
        payment_credit: 'ائتمان الدفع',
        payment_method: 'طريقة الدفع او السداد',
        sales_man: 'مندوب المبيعات',
        create_special_account_in_tree_accounting: 'إنشاء حساب خاص في شجرة المحاسبة',
        account: 'الحساب',
        classification: 'تصنيف العميل',
        add_open_balance: 'اضافة رصيد افتتاحي',
        balance: 'الرصيد',
        open_balance: 'الرصيد الإفتتاحي',
        balances: 'الأرصدة',
        last_validated_date: 'تاريخ التحقق الأخير',
        credit: 'رصيد العميل',
        debit: 'المبلغ المستحق',
        user_name: 'اضيف بواسطة',

        branch: 'الفرع',
        customer_type: 'نوع حساب العميل',
        commercial_register: 'السجل التجاري',
        tax_register: 'الرقم الضريبي',
        contact_information: 'معلومات الإتصال',
        address_information: 'معلومات العنوان',
        individual: 'فردي',
        business: 'تجاري',

        fullname: 'الاسم كامل',
        country_code: 'الدولة',
        city_id: 'المدينة',
        customer_code: 'الكود',
        currency_id: 'العملة',
        category: 'التصنيف',
        branch_id: 'الفرع',
        related_customers: 'العملاء المرتبطين',
        is_related_customers: 'العملاء المرتبطين',
        credit_and_debit: 'الدائن والمدين',
        minimum_credit: 'اقل قيمة للدائن',
        maximum_credit: 'اعلى قيمة للدائن',
        minimum_debit: 'اقل قيمة للمدين',
        maximum_debit: 'اعلى قيمة للمدين',
    },
    customer_types: {
        billing: 'فواتير',
        shipping: 'شحن',
        both: 'كلاهما',
    },
    validation: {
        numeric_digits: 'أرقام',
        lowercase_hex_numbers: 'الأرقام ستة عشر صغيرة',
        uppercase_hex_numbers: 'الأرقام ستة عشر كبيرة',
        lowercase_letters: 'أحرف صغيرة',
        uppercase_letters: 'أحرف كبيرة',
        lowercase_letters_followed_by_numeric_digits: 'أحرف صغيرة متبوعة بأرقام',
        uppercase_letters_followed_by_numeric_digits: 'الأحرف الكبيرة متبوعة بأرقام',
    },
    purchases_invoices: {
        purchases_invoices: 'فواتير المشتريات',
        title: 'العنوان',
        supplier: 'المورد',
        purchase_code: 'كود الشراء',
        purchase_date: 'تاريخ الشراء',
        payment_terms: 'شروط الدفع',
        day_s: 'أيام',
        currency: 'العملة',
        shipping_fees: 'مصاريف الشحن',
        inventory: 'المخزن',
        attachment: 'مرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'الوصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        tax: 'ضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        subtotal: 'اجمالى المبلغ',
        purchases_invoices_section: 'قسم فواتير المشتريات',
        add_purchases_invoice: 'اضافة فاتورة مشتريات',
        from_date: 'من تاريخ',
        to_date: 'الي تاريخ',
        added_by: 'أضيفت من قبل',
        items_total: 'مجموع المنتجات',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع الكلي',
        create_date: 'تاريخ الاضافة',
        status: 'الحالة',
        change_status: 'تغيير الحالة',
        amount_tax: 'مبلغ الضريبة',
        subtotal_before_tax: 'المبلغ الكلي قبل الضريبة',
        invoice_total_discount: 'مجموع الخصومات',
        reference_number: 'الرقم المرجعي',
        unit: 'الوحدة',
        termination_of_receipt: 'إنهاء الإستلام',
        is_paid_label: 'حالة الدفع',
        paid_amount_label: 'مبلغ المدفوع',
    },
    purchases_refunds: {
        purchases_refunds: 'فاتورة مرتجع مشتريات',
        title: 'العنوان',
        supplier: 'المورد',
        purchase_refund_code: 'كود المرتجع',
        purchase_refund_date: 'تاريخ المرتجع',
        currency: 'العملة',
        shipping_fees: 'مصاريف الشحن',
        inventory: 'المخزن',
        attachment: 'مرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'وصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية المرتجعة',
        tax: 'الضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        payment_type: 'طريقة الدفع او السداد',
        payment_ref: 'رقم المرجع',
        subtotal: 'اجمالى المبلغ',
        purchases_refunds_section: 'قسم مرتجع المشتريات',
        add_purchases_refund: 'إضافة فاتورة مرتجع مشتريات',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        items_total: 'مجموع المنتجات',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع',
        create_date: 'تاريخ الاضافة',
        tax_2: 'الضريبة 2',
        branch: 'الفرع',
        deposit_type: 'نوع الإيداع',
        deposit_value: 'قيمة الإيداع',
        total_amount_refunf_supplier: 'تم رد المبلغ الإجمالي للعناصر المرتجعة من المورد',
        payment_method: 'طريقة الدفع',
        original_qty: 'الكمية الأساسية',
        amount_tax: 'مبلغ الضريبة',
        subtotal_before_tax: 'المبلغ الكلي قبل الضريبة',
        invoice_total_discount: 'مجموع الخصومات',
    },
    appointments: {
        appointments: 'المواعيد',
        add_appointment: 'إضافة موعد',
        appointments_management: 'إدارة المواعيد',
        customer: 'العميل',
        date: 'التاريخ',
        start_time: 'وقت البدء',
        interval: 'الفترة',
        procedures: 'الإجراء',
        share_with_client: 'شارك مع العميل',
        is_recurring: 'متكرر',
        recurring_type: 'النوع التكرار',
        recurring_date: 'التاريخ التكرار',
        notes: 'ملاحظات',
        is_active: 'نشط',
        start_at: 'يبدأ في',
        appintment_date: 'تاريخ الموعد',
    },
    items: {
        wholesale_price: 'سعر الجملة',
        half_wholesale_price: 'سعر نصف الجملة',
        branch_price: 'سعر الفرع',
        upload_image: 'تحميل الصور',
        name: 'اسم',
        id: 'المعرف',
        sku_code: 'كود SKU',
        is_committed: 'البيع بالسالب',
        description: 'الوصف',
        category: 'التصنيف',
        brand: 'العلامة التجارية',
        barcode: 'الباركود',
        re_order: 'إعادة الطلب',
        special_description: 'وصف مشتريات',
        tags: 'العلامات',
        is_tracking: 'هل يتم تتبع؟',
        item: 'المنتج',
        items_section: 'قسم المنتجات',
        new_item: 'منتج جديد',
        basic_information: 'المعلومات الاساسية',
        financial_information: 'معلومات مالية',
        purchase_price: 'سعر الشراء',
        sale_price: 'سعر البيع',
        min_sale_price: 'أدنى سعر بيع',
        profit_margin: 'هامش الربح',
        discount: 'قيمة الخصم',
        discount_type: 'نوع الخصم',
        taxes: 'الضرائب',
        tax: 'ضريبة',
        percentage: 'نسبة مئوية',
        initial_stock_level: 'مستوى المخزون الأولي',
        low_stock_threshold: 'عتبة المخزون المنخفضة',
        inventory_details: 'تفاصيل المخزن',
        add_new_item: 'اضافة منتج جديد',
        sum_available_qty: 'مجموع الكمية المتاحة',
        sum_stock_on_hand_qty: 'مجموع الكمية باليد',
        minimum_qty: 'الحد الأدنى من الكمية',
        item_details: 'تفاصيل المنتج',
        is_manufacturing_item: 'منتج تصنيع',
        raw_material_price: 'سعر كمادة خام',
        purchase_price_last_3_invoice: 'سعر الشراء لآخر 3 فواتير',
        purchase_price_last_6_invoice: 'سعر الشراء لآخر 6 فواتير',
        purchase_price_last_10_invoice: 'سعر الشراء لآخر 10 فواتير',
        add_composite_item: "اضف منتج مركب",
        sub_items: "منتجات فرعية",
        qty: "الكمية",
        sku: 'Sku',
        sell_price: 'سعر البيع',
        open_stock_value: 'القيمة الافتتاحية للمخزن',
        open_stock_quantity: 'الكمية الافتتاحية للمخزن',
        group_items: 'مجموعة المنتجات',
        add_collected_item: 'اضف منتج مجموعة',
        composite_item: "منتج مركب",
        collected_item: "منتج مجموعة",
        values: "القيم",
        you_must_select_category_to_add_group_items: "يجب عليك اختيار التصنيف لاضافة مجموعة المنتجات",
        add_aspects_for_category: "اضف متغيرات المنتج للتصنيف من خلال:",
        add_aspects: "اضف متغيرات منتج",
        through_this_form_you_can_add_only_simple_item: "من خلال هذا النموذج يمكنك اضافة منتج بسيط فقط",
        total_taxes: "مجموع النسب الضريبية",
        generate_barcode: "توليد بار كود عشوائي",
        save_barcode: "حفظ / تحميل كصوره",
        category_id: "التصنيف",
        brand_id: "العلامة التجارية",
        item_type: "نوع المنتج",
        tax_1: "الضريبة 1",
        tax_2: "الضريبة 2",
        mrp: "سعر MRP",
        unit: "الوحدة",
        unit_id: "الوحدة",
        related_items: "المنتجات المرتبطة",
        is_active: "هل المنتج فعال",
        is_service: "هل هو خدمة",
        is_tax_inclusive_price: "هل السعر متضمن الضريبة",
        apply_priority_tax: "تطبيق الأولوية",
        laundry_services: "خدمات مغاسل",
        other_barcodes: "باركودات اخرى",
        barcode_number: "رقم الباركود",
        return_days: "عدد أيام الإرجاع",
        units: 'الوحدات',
        number: 'القيمة',
    },
    discount_types: {
        none: 'لا يوجد',
        percentage: 'نسبة مئوية',
        value: 'قيم',
    },
    price_lists: {
        price_list: "قائمة الاسعار",
        name: "الاسم",
        notes: "ملاحظات",
        prices: "الأسعار",
        product: "المنتج",
        sale_price: "السعر البيع",
        new_price_list: "قائمة الأسعار جديدة",
        Prices_lists_section: "قسم قوائم الأسعار",
        item: "المنتج",
    },
    inventory_transactions: {
        transaction_code: "رمز العملية",
        transaction_type: "نوع المعاملة",
        inventory: "المخزن",
        date: "التاريخ",
        inventory_transactions_section: "قسم حركات المخزن",
        from_date: "من تاريخ",
        to_date: "الى تاريخ",
        inventory_transaction: "إذن مخزني",
        new_inventory_transaction: "إذن مخزني جديدة",
        transaction_reference: "رقم المرجع",
        transaction_date: "تاريخ الصفقة",
        notes: "ملاحظات",
        product: "منتج",
        purchase_price: "سعر الشراء",
        quantity: "الكمية",
        unit: "الوحدة",
        total: "المجموع",
        products: "المنتجات",
        item_inventory_qty_after: "الكمية بعد",
        item_inventory_qty_before: "الكمية قبل",
        status: "الحالة",
        change_status: "تغيير الوضع",
        transfer_from_inventory: "التحويل من المخزن",
        transfer_to_inventory: "التحويل الى المخزن",
    },
    inventory_monitoring: {
        date: "التاريخ",
        product: "المنتج",
        unit: "الوحدة",
        products: "المنتجات",
        from_date: "من تاريخ",
        to_date: "الى تاريخ",
        inventory: "المخزن",
        notes: "ملاحظات",
        time: "الوقت",
        inventory_monitoring_section: "قسم إدارة الجرد",
        new_inventory_monitoring: "جرد جديد",
        inventory_monitoring: "إدارة الجرد",
        code: "الكود",
        actual_quantity: "الكمية الفعلية",
        expected_quantity: "الكمية في النظام",
        settlement_status: "حالة التسوية",
    },
    customers_status: {
        customers_status: "حالة العملاء",
        is_default: "هو الافتراضي",
        is_active: "نشط",
        color: "اللون",
        customer: "العميل",
        statuses: "الحالات",
    },
    transaction_types: {
        add_quantity: "أضف الكمية",
        decrease_quantity: "تقليل الكمية",
        transfer_quantity: "تحويل الكمية",
    },
    status_list: {
        pending: "قيد المراجعة",
        confirm: "تأكيد",
    },
    customer_attributes: {
        name: "الاسم",
        data_type: "نوع البيانات",
        is_unique: "فريد من نوعة",
        is_nullable: "لاغية",
        customer_attributes: "سمات العميل",
        attributes: "السمات",
    },
    customer_feature_data_type: {
        number: "رقم",
        char: "حرف",
    },
    customers_feature: {
        customers_feature: "ميزات العملاء",
        customer_type: "نوع العميل",
        customer_advance_feature: "ميزات تقدمة للعميل",
        feature: "الميزة",
        status: "الحالة",
    },
    customer_type: {
        company: "شركة",
        individual: "الفرد",

    },

    purchase_configuration: {
        purchase_configuration: "الإعدادات العامة للمشتريات",
        purchase_configuration_section: "قسم الإعدادات العامة للمشتريات",
        update_product_prices: "تحديث أسعار المنتج بعد فاتورة الشراء",
        auto_pay_supplier_invoice: "دفع فواتير الشراء تلقائيًا إذا كان لدى المورد رصيد متاح",
        mark_invoice_default_paid: "ضع علامة على فواتير الشراء على أنها مدفوعة بالفعل افتراضيًا",
        purchase_invoice_manual_status: "حالات دليل فاتورة الشراء",
        custom_jornal_description: "وصف دفتر اليومية المخصص",
    },
    estimations: {
        estimations: 'تقديرات المبيعات',
        name: 'الاسم',
        customer: 'العميل',
        estimate_code: 'رقم التقدير',
        estimate_date: 'تاريخ التقدير',
        currency: 'العملة',
        shipping_fees: 'مصاريف الشحن',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'وصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        tax: 'الضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        subtotal: 'اجمالى المبلغ',
        unit: 'الوحدة',
        estimations_section: 'قسم تقديرات المبيعات',
        add_estimations: 'أضف تقديرات المبيعات',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        estimate_subtotal: 'اجمالى المبلغ',
        total_after_discount: 'مجموع الخصم',
        total_after_shipping: 'مجموع الشحن',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع الكلي',
        from_quantity: 'من الكيمة',
        to_quantity: 'الى الكيمة',
        basic_information: 'المعلومات الاساسية',
        attachments: 'المرفقات',
        shipping_details: 'تفاصيل الشحن',
        adjustment: 'تسوية',
        upload_attachment: 'تحميل مرفق',
    },
    sales_invoices: {
        sales_invoices: 'فواتير المبيعات',
        name: 'اسم',
        customer: 'العميل',
        invoice_code: 'رقم الفاتورة',
        invoice_date: 'تاريخ الفاتورة',
        currency: 'عملة',
        shipping_fees: 'مصاريف الشحن',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'الوصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        tax: 'الضريبة',
        tax2: '2 الضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        subtotal: 'اجمالى المبلغ',
        unit: 'الوحدة',
        sales_invoices_section: 'قسم فواتير المبيعات',
        add_sales_invoices: 'أضف فاتورة المبيعات',
        add_general_sales_rent: 'أضف فاتورة تأجير عامة',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        invoice_subtotal: 'اجمالى المبلغ',
        total_discount: 'مجموع الخصم',
        total_after_shipping: 'مجموع الشحن',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع',
        from_quantity: 'من الكمية',
        to_quantity: 'إلى الكمية',
        basic_information: 'المعلومات الاساسية',
        attachments: 'المرفقات',
        shipping_details: 'تفاصيل الشحن',
        adjustment: 'الضبط',
        upload_attachment: 'تحميل المرفق',
        deposite_value: 'قيمة الإيداع',
        payment_terms: 'شروط الدفع',
        issue_date: 'تاريخ الإصدار',
        start_date: ' تاريخ البداية',
        end_date: 'تاريخ النهاية',
        rent_table_title: 'تفاصيل التأجير',
        client_info: 'معلومات العميل',
        refund_receipt_details: 'تفاصيل الإسترداد',
        code_refund_receipt: 'رقم إسترداد الإسترداد',
        customer_code: 'رقم العميل',
        customer_name: 'اسم العميل',
        amount: 'المبلغ',
        payment_status: 'حالة الدفع',
        payment_status_label: 'الدفع',
        collected_by: 'جمعت من قبل',
        from_amount: 'من المبلغ',
        to_amount: 'إلى المبلغ',
        total_commission: 'إجمالي العمولة',
        accounts: 'الحسابات',
        inventory: 'المخزن',
        amount_tax: 'مبلغ الضريبة',
        subtotal_before_tax: 'المبلغ قبل الضريبة',
        add_general_sales: 'اضافة فاتورة مبيعات عامة',
        add_sales_rent: 'اضافة فاتورة تأجير',
        country: 'الدولة',
        city: 'المدينة',
        address: 'العنوان',
        shipping_address: 'عنوان الشحن',
        billing_address: 'عنوان الفاتورة',
        price_list: 'قائمة الأسعار',
        reference_number: 'الرقم المرجعي',
        total_amount_before_discount: 'إجمالي المبلغ قبل الخصم',
        total_amount_after_discount: 'إجمالي المبلغ بعد الخصم',
        total_before_discount: 'الإجمالي قبل الخصم',
        total_after_discount: 'الإجمالي بعد الخصم',
        final_total: 'الإجمالي النهائي',
        add_sales_laundries: 'اضافة فاتورة مغاسل',
        service: 'الخدمة',
        received_and_delivery_appointment: 'موعد الاستلام والتسليم',
        received: 'موعد الاستلام',
        delivery: 'موعد التسليم',
        user_assigned_to_invoice: 'المستخدم المعين للفاتورة',
        user: 'مستخدم',
        zero_sales_invoice: 'فاتورة صفرية',
        deferred_invoice: 'نوع الفاتورة (آجل-نقدي)',
        amount_in_letters: 'المبلغ بالحروف',
        word_invoice_total: 'إجمالي الفاتورة بالأحرف العربية',
        word_invoice_total_en: 'إجمالي الفاتورة بالأحرف الإنجليزية',
    },
    sales_refund: {
        sales_refund: 'مرتجع فاتورة',
        refund_info: 'معلومات المرتجع',
        sales_refund_section: 'مرتجع فاتورة',
        basic_information: 'المعلومات الاساسية',
        attachments: 'المرفقات',
        customer: 'العميل',
        invoice_code: 'رقم الفاتورة',
        receipt_no: 'رقم الإيصال',
        receipt_date: 'تاريخ الإيصال',
        shipping_details: 'تفاصيل الشحن',
        shipping_fees: 'مصاريف الشحن',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        issue_date: 'تاريخ الإصدار',
        adjustment: 'تعديل',
        sales_man: 'مندوب المبيعات',
        notes: 'ملاحظات',
        ref_no: 'الرقم المصدر',
        payment_method: 'طريقة الدفع',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'الوصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        tax: 'الضريبة',
        subtotal: 'اجمالى المبلغ',
        unit: 'الوحدة',
        invoice_subtotal: 'اجمالى المبلغ',
        total_after_discount: 'مجموع الخصم',
        total_after_shipping: 'مجموع الشحن',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع',
        from_quantity: 'من الكمية',
        to_quantity: 'إلى الكمية',
        tax1: 'الضريبة',
        tax2: 'الضريبة 2',
        client_info: 'معلومات العميل',
        refund_receipt_details: 'تفاصيل إيصال المرتجع',
        code_refund_receipt: 'رمز إيصال المرتجع',
        customer_code: 'كود العميل',
        customer_name: 'اسم العميل',
        currency: 'العملة',
        amount: 'المبلغ',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        payment_status: 'حالة الدفع',
        collected_by: 'جمع بواسطة',
        refund_receipt_date: 'تاريخ استلام المبلغ المرتجع',
        from_amount: 'من المبلغ',
        to_amount: 'إلى المبلغ',
        inventory: 'المخزن',
        amount_tax: 'مبلغ الضريبة',
        subtotal_before_tax: 'المبلغ قبل الضريبة',
    },
    credits: {
        credits: 'إشعارات الدائن',
        name: 'اسم',
        customer: 'العميل',
        credit_code: 'كود إشعار الدائن',
        credit_date: 'تاريخ إشعار الدائن',
        currency: 'العملة',
        shipping_fees: 'مصاريف الشحن',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'وصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        tax: 'الضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        subtotal: 'اجمالى المبلغ',
        unit: 'الوحدة',
        credits_section: 'قسم اشعارات الدائن',
        add_sales_credits: 'أضف إشعار دائن',
        add_sales_credits_general: 'أضف إشعار دائن عام',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        invoice_subtotal: 'اجمالى المبلغ',
        total_after_discount: 'المجموع بعد الخصم',
        total_after_shipping: 'المجموع بعد الشحن',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع',
        from_quantity: 'من الكمية',
        to_quantity: 'إلى الكمية',
        basic_information: 'المعلومات الاساسية',
        attachments: 'المرفقات',
        shipping_details: 'تفاصيل الشحن',
        adjustment: 'تعديل',
        upload_attachment: 'تحميل المرفق',
        deposite_value: 'قيمة الإيداع',
        payment_terms: 'شروط الدفع',
        issue_date: 'تاريخ الإصدار',
        credit_subtotal: 'اجمالى المبلغ',
        day_s: 'أيام',
        inventory: 'المخزن',
        amount_tax: 'مبلغ الضريبة',
        subtotal_before_tax: 'المبلغ الكلي قبل الضريبة',
        invoice_total_discount: 'مجموع الخصومات',
        original_qty: 'الكمية الأساسية',
    },
    sales_shipping: {
        name: 'اسم',
        fees: 'مصاريف',
        tax: 'ضريبة',
        currency: 'عملة',
        order: 'ترتيب',
        notes: 'ملاحظات',
        type: 'يكتب',
        sales_shipping: 'شحن المبيعات',
        add_sales_shipping: 'إضافة مبيعات الشحن',
        sales_shipping_section: 'قسم شحن المبيعات',
    },
    sales_configuration: {
        convert_delivery_from_pending: 'السماح بالتحويل من معلق إلى تسليم مباشرة',
        convert_delivery_without_available_qty: 'تحويل التسليم بدون كمية متاحة',
        sales_configuration: 'إعدادات المبيعات',
        add_sales_configuration: 'أضف إعدادات مبيعات',
        sales_configuration_section: 'قسم إعدادات المبيعات',
        Enable_Changing_Invoice_Number_For_Each_Invoice: 'تمكين تغيير رقم الفاتورة لكل فاتورة',
        billing_method: 'طريقة الدفع',
        discount_option: 'خيار الخصم',
        committed_type: 'السحب بالسالب',
        zero_fraction_appearing: 'ظهور الكسر الصفري',
        Disable_Invoice_Items_Edit: 'تعطيل تحرير عناصر الفاتورة',
        Disable_Estimates_Module: 'تعطيل وحدة التقديرات',
        Enable_Invoice_Manual_Statuses: 'تفعيل حالات دليل الفاتورة',
        Enable_Estimate_Manual_Statuses: 'تفعيل تقدير الحالات اليدوية',
        Disable_Shipping_Options: 'تعطيل خيارات الشحن',
        Enable_Maximum_Discount: 'تفعيل الحد الأقصى للخصم',
        Mark_Invoices_As_Already_Paid_By_Default: 'وضع علامة على الفواتير على أنها مدفوعة بالفعل افتراضيًا',
        Auto_Pay_Invoices_if_the_Client_Has_Available_Credit: 'الدفع التلقائي للفواتير إذا كان لدى العميل رصيد متاح',
        Send_Invoices_Estimates_Via_Whats_App: 'إرسال الفواتير والتقديرات عبر الواتساب',
        Display_the_Invoice_Profit: 'عرض ربح الفاتورة',
        Custom_Journal_Description: 'تخصيص وصف القيد',
        pos_policy: 'سياسة الاستخدام',
        is_company_has_tax_no: 'الشركة لديها رقم ضريبي',
        is_apply_taxes_setting: 'تطبيق الضريبة علي الفواتير',
        is_apply_tax_quotation: 'تطبيق الضريبة علي عروض الاسعار',
        is_apply_tax_debit: 'تطبيق الضريبة علي إشعار المدين',
    },
    expenses_category: {
        expenses_category: 'فئات المصروفات',
        add_new_category: 'اضافة تصنيف جديد',
        add_new_sub_category: 'اضافة تصنيف فرعي جديد',
        name: 'الاسم',
        description: 'الوصف',
    },
    expenses: {
        expenses: 'إدارة المصروفات',
        expenses_section: 'قسم إدارة المصروفات',
        code: 'الكود',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        currency: 'العملة',
        add_expenses: 'أضف مصروف',
        category: 'التصنيف',
        name: 'الاسم',
        date: 'التاريخ',
        amount: 'المبلغ',
        tax: 'الضريبة',
        attachment: 'مرفق',
        status: 'الحالة',
        notes: 'ملاحظات',
        taxes: 'الضرائب',
        tax_type: 'نوع الضريبة',
        tax_val: 'قيمة الضريبة',
        is_recurring: 'متكرر',
        frequency: 'التكرار',
        end_date: 'تاريخ الانتهاء',
        payment_method: 'طريقة الدفع',
        treasury: 'الخزينة',
        inventory: 'المخزن',
        bank: 'بنك',
        is_included_tax: 'شامل الضريبة',
    },
    incomes: {
        incomes: 'إدارة الواردات',
        incomes_section: 'قسم إدارة الواردات',
        code: 'الكود',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        currency: 'العملة',
        add_incomes: 'أضف وارد',
        category: 'التصنيف',
        name: 'الاسم',
        date: 'التاريخ',
        amount: 'المبلغ',
        tax: 'الضريبة',
        attachment: 'مرفق',
        status: 'الحالة',
        notes: 'ملاحظات',
        taxes: 'الضرائب',
        tax_type: 'نوع الضريبة',
        tax_val: 'قيمة الضريبة',
        is_recurring: 'متكرر',
        frequency: 'التكرار',
        end_date: 'تاريخ الانتهاء',
        payment_method: 'طريقة الدفع',
        treasury: 'الخزينة',
        inventory: 'المخزن',
        bank: 'بنك',
    },
    treasuries: {
        treasuries: 'إدارة الخزائن',
        treasuries_section: 'قسم إدارة الخزائن',
        add_treasuries: 'أضف خزينة',
        from_date: 'من التاريخ',
        to_date: 'الى تاريخ',
        name: 'اسم',
        balance: 'الرصيد',
        notes: 'ملاحظات',
        deposit: 'إيداع',
        withdraw: 'سحب',
        created_date: 'تاريخ الإنشاء',
        balances: 'الأرصدة',
        currency: 'العملة',
        currencies_allowed: 'العملات المسموح التعامل بها',
        last_validated_date: 'تاريخ التحقق الأخير',
        add_open_balance: 'اضافة رصيد افتتاحي',
        open_balance: 'الرصيد الإفتتاحي',
        manager: 'مسؤول الخزنة',
    },
    treasury_checking: {
        treasury_checking: 'مطابقة الخزائن',
        treasury: 'الخزينة',
        from_date: 'من',
        to_date: 'الى',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        current_amount: 'المبلغ الحالي',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },
    procedure: {
        procedure: 'إجراء',
        new_procedure: 'إجراء جديد',
        document: 'وثيقة',
    },
    countries: {
        country_management: 'إدارة الدولة',
        countries: 'بلدان',
        country: 'الدولة',
        phone_code: 'كود الهاتف',
        en_name: 'الاسم الانجليزي',
        ar_name: 'الاسم العربي',
        code2: 'الرمز 2',
        code3: 'الرمز 3',
    },
    template_design: {
        template_design: 'تصميم القالب',
        upload_logo: 'تحميل الشعار',
        color: 'اللون',
        dark_mode: 'الوضع المظلم',
    },
    inventory_statistics: {
        type_status: 'نوع الحالة',
        inventory_statistics: 'إحصائيات المخزن',
        inventory: 'المخزن',
        product: 'المنتج',
        financial_year: 'السنة المالية',
        sales_booking: 'حجز المبيعات',
        available_stock_qty: 'الكمية المتوفرة في المخزن',
        open_stock_quantity_total: 'الكمية الافتتاحية',
        stock_on_hand_qty: 'إجمالي الكميات الفعلية',
        purchase_on_way: 'مشتريات على الطريق',
        max_quantity: 'الحد الأعلى للكمية',
        min_quantity: 'الحد الأدنى للكمية',
        committed_qty: 'الكميات السالبة',
        booked: 'تم تفعيل الحجز',
        booking: 'محجوز',
    },
    discount_type: {
        percentage: 'نسبة مئوية',
        value: 'قيمة',
    },
    subscriptions: {
        subscriptions: 'المشتريات الدورية',
        basic_information: 'المعلومات الاساسية',
        subscription_name: 'اسم المشتريات الدورية',
        generate_invoice_every: 'إنشاء فاتورة كل',
        occurrences: 'حوادث',
        first_invoice_date: 'تاريخ أول فاتورة',
        issue_invoice_before: 'اصدار الفاتورة من قبل',
        day_s: 'أيام',
        customer: 'عميل',
        send_copy_flag: 'أرسل لي نسخة من الفواتير الجديدة التي تم إنشاؤها',
        display_dates_flag: 'عرض التواريخ (من) و (إلى) في الفاتورة',
        automatic_payment_flag: 'تمكين الدفع التلقائي لهذه الفاتورة',
        is_active: 'نشط',
        shipping_details: 'تفاصيل الشحن',
        shipping_fees: 'مصاريف الشحن',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        adjustment: 'التعديل',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'الوصف',
        unit_price: 'سعر الوحدة',
        qty: 'كمية',
        unit: 'وحدة',
        tax_1: 'الضريبة',
        tax_1_value: 'قيمة الضريبة',
        tax_2: 'الضريبة 2',
        tax_2_value: 'قيمة الضريبة 2',
        subtotal: 'اجمالى المبلغ',
        tax: 'الضريبة',
        total_subtotal: 'اجمالى المبلغ',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع',
        attachments: 'المرفقات',
        name: 'اسم',
        attachment: 'المرفق',
        upload_attachment: 'تحميل المرفق',
        payment_terms: 'شروط الدفع',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        from_amount: 'من مبلغ',
        to_amount: 'الى مبلغ',
        every: "كل",
        subscriptions_section: "قسم المشتريات الدورية",
        add_subscription: "إضافة اشتراك",
        generated: "تم إنشاؤها",
    },
    payment_sales_invoices: {
        payment_sales_invoices: 'دفعات مبيعات',
        payment_method: 'طريقة الدفع',
        banks: 'البنوك',
        amount: 'المبلغ',
        payment_date: 'موعد الدفع',
        payment_status: 'حالة الدفع',
        collected_by: 'جمع بواسطة',
        ref_no: 'الرقم المصدر',
        payment_details: 'بيانات الدفع',
        receipt_notes: 'ملاحظات الاستلام',
        attachment: 'المرفق',
        client_info: 'بيانات العميل',
        invoice_code: 'كود الفاتورة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        from_amount: 'من الكمية',
        to_amount: 'الى الكمية',
        customer: 'العميل',
        invoiced_by: 'تم إصدار الفواتير بواسطة',
        payment_sales_invoices_section: 'دفع مبيعات',
        payment_purchase_invoices_section: 'دفع مشتريات',
        customer_code: 'كود العميل',
        customer_name: 'اسم العميل',
        supplier_code: 'كود المورد',
        supplier_name: 'اسم المورد',
        type: 'نوع الفاتوره',
        sales_invoice: 'فاتورة المبيعات',
        purchase_invoice: 'فاتورة المشتريات',
        general_sales: 'فاتورة المبيعات العامة',
        rent_invoices: "فواتير التأجير",
        general_rent_invoices: "فواتير التأجير العامة",
        sales_refund: 'مرتجع المبيعات',
        general_sales_refund: 'مرتجع المبيعات العامة',
        purchase_refund: 'مرتجع المشتريات',
        sales_credit: 'إشعار دائن',

        details: 'التفاصيل',
        treasuries: 'الخزائن',
        currency: 'العملة',
        financial_payment: 'دفعة مالية',
    },
    payment_sales_invoices_details: {
        Client_Info: 'معلومات العميل',
        Payment_Details: 'بيانات الدفع',
        Client_Name: 'اسم العميل',
        Invoice_No: 'رقم الفاتورة.',
        Street_Address: 'عنوان الشارع',
        Payment_Method: 'طريقة الدفع',
        City: 'مدينة',
        Amount: 'المبلغ',
        State: 'المقاطعة',
        Transaction_ID: 'رقم المعاملة',
        Postal_Code: 'الرمز البريدي',
        Status: 'الحالة',
        Telephone: 'هاتف',
        Date: 'التاريخ',
        Country: 'الدولة',
        Added_By: 'أضيفت بواسطة',
        Currency: 'العملة',
        Details: 'التفاصيل',
        Receipt_Notes: 'ملاحظات الاستلام',
        delete: 'حذف',
        receipt: 'الإيصال',
        edit: 'تعديل',
    },
    employees: {
        employees_management: 'إدارة الموظفين',
        basic_information: 'المعلومات الاساسية',
        accounting_information: 'المعلومات المحاسبية',
        information_account: 'معلومات الحساب',
        financial_info: 'المعلومات المالية',
        attachments: 'المرفقات',
        notes: 'ملاحظات',
        upload_image: 'تحميل الصور',
        first_name: 'الاسم الاول',
        second_name: 'الاسم الثاني',
        surname: 'اسم العائلة',
        nationality: 'جنسية',
        mobile: 'الجوال',
        email: 'البريد الإلكتروني',
        identity_type: 'نوع الهوية',
        identity_no: 'رقم الهوية',
        employment_type: 'نوع الوظيفة',
        employment_level: 'الدرجة',
        employee_code: 'رمز الموظف',
        country: 'دولة',
        city: 'مدينة',
        state: 'المقاطعة',
        postal_code: 'رمز بريدي',
        address1: 'العنوان 1',
        address2: 'العنوان 2',
        password: 'كلمة المرور',
        roles: 'الصلاحيات',
        allow_access_system: 'السماح بالوصول إلى النظام',
        send_credetial_email: 'أرسل بيانات الاعتماد إلى الموظف عبر البريد الإلكتروني',
        display_language: 'عرض اللغة',
        start_date: 'تاريخ البدء',
        expired_date: 'تاريخ انتهاء الصلاحية',
        start_salary: 'يبدأ الراتب',
        increase_every: 'زيادة الكل',
        current_salary: 'الراتب الحالي',
        currency: 'العملة',
        increase_percentage: 'زيادة النسبة المئوية',
        house_alternative: 'بدل سكن',
        travel_alternative: 'بدل السفر',
        health_alternative: 'بدل تأمين صحي',
        title: 'عنوان',
        name: 'الاسم',
        attachment: 'المرفق',
        upload_attachment: 'تحميل المرفق',
        employees: 'الموظفين',
        add_employee: 'إضافة موظف',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        full_name: 'الاسم الكامل',
        level: 'المستوى',
        type: 'النوع',
        address_info: 'عناوين الموظف',
        inventory: 'المخزن',
        total_shipping: 'إجمالي الشحن',
        branch: 'الفرع',
        job_title: 'المسمى الوظيفي',
        special: 'خاص',
        general: 'عام',
        account_type: 'نوع الحساب',
        manager: 'المدير',
    },
    purchases_requests: {
        purchases_requests: 'أمر شراء داخلي',
        title: 'عنوان',
        purchase_request_code: 'كود الطلب',
        purchase_request_date: 'تاريخ الطلب',
        purchase_request_due_date: 'تاريخ الاستحقاق للطلب',
        branch: 'فرع',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        qty: 'كمية',
        purchases_requests_section: 'طلبيات المشتريات',
        add_purchases_request: 'إضافة أمر شراء داخلي',
        from_date: 'من التاريخ',
        to_date: 'حتي اليوم',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
    },
    purchases_quotation_requests: {
        purchases_quotation_requests: 'طلبات مقترحات المشتريات',
        title: 'عنوان',
        purchase_quotation_request_code: 'كود الطلب',
        purchase_quotation_request_date: 'تاريخ الطلب',
        purchase_request_due_date: 'تاريخ الاستحقاق للطلب',
        branch: 'فرع',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        qty: 'كمية',
        purchases_quotation_requests_section: 'قسم طلبات المقترحات',
        add_purchases_quotation_requests: 'إضافة طلب مقترحات المشتريات',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        supplier: 'المورد',
    },
    purchases_orders: {
        purchases_orders: 'طلبيات المشتريات',
        title: 'العنوان',
        supplier: 'المورد',
        purchase_code: 'كود الشراء',
        purchase_date: 'تاريخ الشراء',
        payment_terms: 'شروط الدفع',
        day_s: 'أيام',
        currency: 'العملة',
        shipping_fees: 'مصاريف الشحن',
        inventory: 'المخزن',
        attachment: 'مرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'الوصف',
        unit_price: 'سعر الوحدة',
        qty: 'كمية',
        tax: 'ضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        subtotal: 'اجمالى المبلغ',
        purchases_orders_section: 'طلبيات المشتريات',
        add_purchases_orders: 'اضافة طلبية مشتريات',
        from_date: 'من تاريخ',
        to_date: 'الي تاريخ',
        added_by: 'أضيفت من قبل',
        items_total: 'مجموع المنتجات',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع الكلي',
        create_date: 'تاريخ الاضافة',
        valid_until: 'صالح حتى',
        tax_1: 'ضريبة 1',
        tax_2: 'ضريبة 2',
        branch: 'الفرع',
        total_taxes: 'مجموع الضرائب',
        add_purchases_order: 'اضافة طلبية مشتريات',
        convert_to_purchase_invoice: 'التحويل لفاتورة مشتريات',
        do_you_want_convert_to_purchase_invoice: 'هل تريد التحويل لفاتورة مشتريات؟',
    },
    cheque_book: {
        cheque_book: 'دفاتر الشيكات',
        bank: 'البنك',
        bank_booking_no: 'رقم الحجز المصرفي',
        first_serial: 'الرقم التسلسلي الاول',
        last_serial: 'الرقم التسلسلي الاخير',
        currency: 'العملة',
        branch: 'الفرع',
        notes: 'الملاحظات',
        cheque_book_section: 'قسم دفاتر الشيكات',
        add_cheque_book: 'إضافة دفتر شيكات',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        used: 'الاستخدام',
        created_date: 'تاريخ الإنشاء',
        bank_name: 'اسم البنك',
        used_number: 'رقم الاستخدام',
    },
    payable_cheques: {
        payable_cheques: 'الشيكات المستحقة الدفع',
        bank: 'البنك',
        bank_booking_no: 'رقم الحجز المصرفي',
        first_serial: 'الرقم التسلسلي الاول',
        last_serial: 'الرقم التسلسلي الاخير',
        currency: 'العملة',
        branch: 'الفرع',
        notes: 'الملاحظات',
        payable_cheques_section: 'قسم الشيكات المستحقة',
        add_payable_cheques: 'إضافة شيك مستحق الدفع',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        used: 'الاستخدام',
        created_date: 'تاريخ الإنشاء',
        bank_name: 'اسم البنك',
        used_number: 'رقم الاستخدام',

        amount: 'المبلغ',
        cheque_book_number: 'رقم دفتر الشيكات',
        cheques_no: 'رقم الشيك',
        issue_date: 'تاريخ الإصدار',
        due_date: 'تاريخ الاستحقاق',
        received_account: 'حساب مستلم',
        cheques_name: 'الاسم علي الشيك',
        description: 'الوصف',
        status: 'الحالة',
        attachment: 'المرفق',
        upload_attachment: 'تحميل المرفق',
        booking_no: 'رقم الحجز',
        from_issue_date: 'من تاريخ الإصدار',
        to_issue_date: 'الى تاريخ الإصدار',
        from_due_date: 'من تاريخ الاستحقاق',
        to_due_date: 'الى تاريخ الاستحقاق',
        book_number: 'رقم الدفتر',
        collect_date: 'تاريخ الجمع',
        bank_account: 'الحساب البنكي',
    },
    receivable_cheques: {
        receivable_cheques: 'الشيكات المستحقة للقبض',
        bank: 'البنك',
        bank_booking_no: 'رقم الحجز المصرفي',
        first_serial: 'الرقم التسلسلي الاول',
        last_serial: 'الرقم التسلسلي الاخير',
        currency: 'العملة',
        branch: 'الفرع',
        notes: 'الملاحظات',
        receivable_cheques_section: 'قسم الشيكات المستحقة',
        add_receivable_cheques: 'إضافة شيك مستحق القبض',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        used: 'الاستخدام',
        created_date: 'تاريخ الإنشاء',
        bank_name: 'اسم البنك',
        used_number: 'رقم الاستخدام',

        amount: 'المبلغ',
        cheque_book_number: 'رقم دفتر الشيكات',
        cheques_no: 'رقم الشيك',
        issue_date: 'تاريخ الإصدار',
        due_date: 'تاريخ الاستحقاق',
        received_account: 'حساب مستلم',
        cheques_name: 'الاسم علي الشيك',
        description: 'الوصف',
        status: 'الحالة',
        attachment: 'المرفق',
        upload_attachment: 'تحميل المرفق',
        booking_no: 'رقم الحجز',
        from_issue_date: 'من تاريخ الإصدار',
        to_issue_date: 'الى تاريخ الإصدار',
        from_due_date: 'من تاريخ الاستحقاق',
        to_due_date: 'الى تاريخ الاستحقاق',
        book_number: 'رقم الدفتر',
        collected_account: 'حساب محصل',
        endorse: 'صدّق',
        endorse_name: 'اسم المصدّق',
        from_amount: 'من المبلغ',
        to_amount: 'الي المبلغ',
    },
    activity_log: {
        activity_log: 'سجل النشاطات',
        basic_information: 'المعلومات الاساسية',
    },
    open_stock_items: {
        open_stock_items: 'الكميات الافتتاحية',
        inventory: 'المخزن',
        item: 'المنتج',
        quantity: 'الكمية',
        min_quantity: 'اقل كمية للطلب',
        max_quantity: 'اعلى كمية للطلب',
        applied_date: 'تاريخ التطبيق',
        status: 'الحالة',
        new_open_stock_items: 'اضافة كمية جديدة',
        financial_year_name: 'السنة المالية',
        from_quantity: 'من الكمية',
        to_quantity: 'إلى الكمية',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        change_status: 'تغيير الحالة',
        items: 'المنتجات',
        created_at: 'تاريخ الإنشاء',
    },
    pos_devices: {
        pos_devices: 'أجهزة نقاط البيع',
        name: 'الاسم',
        store: 'المتجر',
        parent_category: 'القسم الرئيسي',
        image: 'الصورة',
        upload_image: 'تحميل الصور',
        description: 'الوصف',
        pos_devices_section: 'قسم أجهزة نقاط البيع',
        new_pos_devices: 'أجهزة نقاط البيع الجديدة',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
    },
    pos_shifts: {
        pos_shifts: 'الورديات',
        name: 'الاسم',
        store: 'المتجر',
        parent_category: 'القسم الرئيسي',
        image: 'الصورة',
        upload_image: 'تحميل الصور',
        description: 'الوصف',
        pos_shifts_section: 'الورديات',
        new_pos_shifts: 'وردية جديدة',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
    },
    pos_settings: {
        pos_settings: 'إعدادات نقاط البيع',
        default_client: 'العميل الافتراضي',
        default_inventory: 'المخزن الافتراضي',
        default_layout: 'التخطيط الافتراضي',
        enabled_payment: 'نفعيل الدفع',
        default_payment: 'طريقة الدفع الاساسية',
        allowed_categories: 'التصنيفات المسموح بها',
        product_category: 'فئة المنتج',
        enable_num_pad: 'تفعيل لوحة الأرقام',
        show_product_images: 'عرض صور المنتج',
        accounting_System_per_invoice: 'نظام المحاسبة لكل فاتورة',
        enable_auto_adjustment: 'تفعيل الضبط التلقائي',
        profit_account: 'حساب الربح',
        loss_account: 'حساب الخسارة',
        default_bank: 'البنك الافتراضي',
        default_cash_treasury: 'خزينة نقدية افتراضية',
        default_card_treasury: 'خزينة بطاقة افتراضية',
        default_currency: 'العملة الافتراضي',
        is_allow_skip_close_cash: 'سماحية عدم الإقفال',
        is_foreign_currency: 'عملة أجنبية',
    },
    financial_years: {
        financial_years: 'السنوات المالية',
        start_date: 'تاريخ البدء',
        end_date: 'تاريخ الانتهاء',
        description: 'الوصف',
        financial_years_section: 'قسم السنوات المالية',
        new_financial_year: ' اضف سنة مالية الجديدة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
    },
    closed_periods: {
        closed_periods: 'الفترات المغلقة',
        start_date: 'تاريخ البدء',
        description: 'الوصف',
        closed_periods_section: 'قسم الفترات المغلقة',
        new_closed_period: 'اضف فترة مغلقة',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
    },
    insurance_agents: {
        insurance_agents: 'وكلاء التامين',
        insurance_agents_section: 'قسم وكلاء التامين',
        name: 'الاسم',
        email: 'البريد الالكتروني',
        telephone: 'الهاتف',
        image: 'الصورة',
        upload_image: 'تحميل الصوره',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
        new_insurance_agent: 'اضافة وكيل تامين',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        classes: 'التصنيف',
        website: 'الموقع الالكتروني',
    },
    insurance_agents_class: {
        name: 'الاسم',
        notes: 'ملاحظات',
        class_details: 'تفاصيل الطبقة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        classes: 'التصنيف',

        categories: 'التصنيفات',
        discount: 'خصم',
        company_copayment: 'تكلفة الشركة',
        client_copayment: 'تكلفة العميل',
        max_copayment_type: 'نوع أقصى تكلفة',
    },
    credit_types: {
        credit_types: 'أنواع الائتمان',
        name: 'الاسم',
        unit: ' الوحدة',
        description: 'الوصف',
        credit_types_section: 'قسم أنواع الائتمان',
        new_credit_type: 'اضافة نوع جديد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
        allow_decimal: 'السماح بالقسمة',
    },

    credit_usages: {
        credit_usages: 'استخدامات الائتمان',
        client_name: 'اسم العميل',
        credit_type: 'نوع الائتمان',
        usage_amount: 'المبلغ المستخدم',
        usage_date: 'تاريخ الاستخدام',
        description: 'الوصف',
        credit_usages_section: 'قسم أنواع الائتمان',
        new_credit_usage: 'اضافة نوع جديد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
        allow_decimal: 'السماح بالقسمة',
        client: 'العميل',
    },

    package_info: {
        package_info: 'معلومات الحزمة',
        name: 'الاسم',
        unit: ' الوحدة',
        period_val: 'قيمة الفترة',
        price: 'السعر',
        period_type: 'نوع الفترة',
        description: 'الوصف',
        package_info_section: 'قسم معلومات الحزمة',
        new_package_info: 'اضافة حزمة جديد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        created_at: 'تاريخ الإنشاء',
        allow_decimal: 'السماح بالقسمة',
        credit_type: 'نوع الائتمان',
        credit_amount: 'مبلغ الائتمان',
        package_details: 'تفاصيل الحزمة',

    },

    sales_commission: {
        sales_commission: 'عمولة المبيعات',
        commission_name: 'اسم العمولة',
        commission_period: 'فترة العمولة',
        commission_calculation: 'حساب العمولة',
        employee: 'الموظفين',
        currency: 'العملة',
        target_type: 'نوع الهدف',
        target_revenue: 'الهدف الإيرادات',
        target_volume: 'الهدف الحجم',
        target_value: 'قيمة الهدف',
        notes: 'ملاحظات',
        applied_to_the_following_items: 'ينطبق على المنتجات التالية',
        type: 'يكتب',
        type_name: 'أكتب اسم',
        commission_percent: 'نسبة العمولة',
        category: 'فئة',
        item: 'المنتج',
        add_sales_commission: 'اضافة عمولة مبيعات',
        sales_commission_section: 'قسم عمولات المبيعات',
    },
    sales_periods: {
        sales_periods: 'فترات البيع',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        employee: 'الموظفين',
        sales_commission: 'عمولة المبيعات',
        sales_periods_section: 'قسم فترات المبيعات',
        add_sales_period: 'أضف فترة المبيعات',
        commission_period: 'فترة العمولة',
        employee_selection: 'اختيار الموظف',
        rules_selection: 'اختيار القواعد',
        total_commission: 'إجمالي العمولة',
    },
    sales_commission_details: {
        id: 'رقم المعرف',
        sales_commission_details: 'تفاصيل عمولة المبيعات',
        sales_amount: 'مبلغ المبيعات',
        invoice: 'فاتورة',
        sales_quantity: 'كمية المبيعات',
        transaction_date: 'تاريخ الصفقة',
        total_commission: 'إجمالي العمولة',
        sales_commission: 'عمولة المبيعات',
        sales_period: 'فترة المبيعات',
        list_of_the_items: 'قائمة المنتجات',
        item: 'المنتج',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        subtotal: 'اجمالى المبلغ',
        total: 'المجموع',
        total_quantity: 'الكمية الإجمالية',
        sales_commission_details_section: 'قسم تفاصيل عمولة المبيعات',
        employee: 'موظف',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        transaction_type: 'نوع المعاملة',
        sales_invoices: 'فواتير المبيعات',
        sales_credit: 'ائتمان المبيعات',
        sales_refund: 'مرتجع المبيعات',
        transaction: 'العملية',
    },
    chart_accounts: {
        chart_accounts: 'شجرة الحسابات',
        name: 'اسم',
        account_parent: 'الحساب الرئيسي',
        serial_from: 'مسلسل من',
        serial_to: 'المسلسل ل',
        natural_account: 'طبيعة الحساب',
        final_account: 'الحساب الأخير',
        type_account: 'نوع حساب',
        currency: 'العملة',
        custom_type: 'النوع المخصص',
        custom_type_name: 'اسم النوع المخصص',
        amount: 'المبلغ',
        notes: 'ملاحظات',
        the_account_is_empty: 'الحساب فارغ',
        new_chart_account: 'حساب جديد',
        main: 'الرئيسية',
        the_old_account_contains_a_value: 'الحساب القديم يحتوي على قيمة عند التاكيد على إنشاء الاكونت الجديد سيتم نقل القيمة من الحساب القديم الى الحساب الجديد',
        code: 'الكود',
        chart_accounts_settings: 'إعدادات شجرة الحسابات',
        inventory: 'المخزن',
        branch: 'الفرع',

    },
    journal_entries: {
        journal_entries: 'القيود اليومية',
        journal_date: 'تاريخ القيد',
        currency: 'العملة',
        serial_no: 'الرقم التسلسلي',
        upload_attachment: 'تحميل المرفق',
        notes: 'ملاحظات',
        is_active: 'نشط',
        journals_details: 'تفاصيل القيود',
        account_name: 'أسم الحساب',
        description: 'الوصف',
        debit: 'المدين',
        credit: 'الدائن',
        total: 'المجموع',
        the_result_of_subtracting_credit_and_debit_should_be: 'يجب أن يكون ناتج طرح الدائن والمدين يساوي 0',
        document_type: 'نوع المستند',
        transaction_id: 'معرف المعاملة',
        group_account: 'مجموعة الحسابات',
        secondary_account: 'الحساب الثانوي',
        operation: 'العملية',
        debit_credit: 'مدين / دائن',
        amount: 'المبلغ',
        local_credit: 'الدائن المحلي',
        local_debit: 'المدين المحلي',
        after_amount: 'بعد المبلغ',
        exchange_rate: 'بعد المبلغ',
        journal_type: 'نوع القيد',
        the_complex_type_use_3_row_at_lest: 'النوع المعقد يستخدم 3 صفوف على الأقل',
        the_opening_type_use_1_row_at_lest: 'النوع الافتتاحي يستخدم صفًا واحدًا على الأقل',
        the_simple_type_use_only_2_rows: 'النوع البسيط يستخدم صفين فقط',
        accounts_must_be_entered_in_the_entries_details: 'يجب ادخال الحسابات في تفاصيل القيود',
        currency_must_be_entered_in_the_entries_details: 'يجب ادخال العملة في تفاصيل القيود',
    },
    assets_info: {
        assets_info: 'معلومات الأصول',
        asset_details: 'تفاصيل الأصول',
        name: 'اسم',
        purchase_date: 'تاريخ الشراء',
        purchase_info: 'معلومات الشراء',
        status: 'الحالة',
        in_service_date: 'تاريخ الخدمة',
        category_account: 'حساب التصنيف',
        location: 'موقع',
        useful_life: 'العمر الإنتاجي',
        employee: 'موظف',
        notes: 'ملاحظات',
        purchase_amount: 'مبلغ الشراء',
        cash_account: 'حساب نقدي',
        tax_1: 'الضريبة',
        tax_2: 'الضريبة 2',
        salvage_value: 'قيمة المرتجع',
        depreciation_method: 'طريقة الإهلاك',
        straight_line: 'خط مستقيم',
        fixed_depreciation_value: 'قيمة الإهلاك الثابت',
        declining_balance: 'الرصيد المتناقص',
        period: 'فترة',
        period_type: 'نوع الفترة',
        depreciation_percentage: 'نسبة الإهلاك',
        unit_of_production: 'وحدة الإنتاج',
        cost_per_unit: 'التكلفة لكل وحدة',
        unit_name: 'إسم الوحدة',
        depricated_end_date: 'تاريخ انتهاء الاستهلاك',
        assest_total_unit: 'إجمالي وحدة الأصول',
        assets_info_section: 'قسم معلومات الأصول',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        id: 'رقم المعرف',
        account_name: 'أسم الحساب',
        amount: 'المبلغ',
        currency: 'العملة',
        refund_receipt_details: 'تفاصيل إيصال المرتجع',
        add_assets_info: 'أضف معلومات الأصول',
    },
    accounts_routing: {
        accounts_routing: 'توجيه الحسابات',
        purchases_accounts_routing: 'توجيه حسابات المشتريات',
        account: 'الحساب',
        routing_type: 'نوع التوجيه',
        suppliers: 'الموردين',
        purchases: 'المشتريات',
        return_purchase: 'مرتجع مشتريات',
        sales_accounts_routing: 'توجيه حسابات المبيعات',
        clients: 'العملاء',
        sales: 'مبيعات',
        returns: 'عائدات',
        discount_allowed: 'الخصم متاح',
        product_sales_account: 'حساب مبيعات المنتج',
        adjustment_routing: 'توجيه التعديل',
        inventory_accounts_routing: 'توجيه حسابات المخزون',
        treasury_accounts_routing: 'توجيه حسابات الخزينة',
        payable_cheque_account: 'حساب الشيكات الدائنة',
        receivable_cheque_account: 'حساب الشيكات المدينة',
        requisition_journal_settings: 'إعدادات دفتر اليومية',
        inbound_requisition: 'الطلبات الواردة',
        outbound_requisition: 'طلب الصادر',
        stocktaking_outbound: 'جرد للخارج',
        stocktaking_inbound: 'الجرد الواردة',
        invoice: 'فاتورة',
        refund_receipt: 'إيصال المرتجع',
        credit_note: 'اشعار دائن',
        pos_shift_outbound: 'تحويل POS صادر',
        pos_shift_inbound: 'تحول POS وارد',
        expenses: 'نفقات',
        incomes: 'الايرادات',
        financial_accounts_routing: 'توجيه الحسابات المالية',
        sales_credit: 'أرصدة المبيعات',
        banks_account: 'حساب البنوك',
        incomes_expenses_account: 'حساب سندات الضرف والقبض',
        tax_sales: 'ضريبة المبيعات',
        tax_purchase: 'ضريبة المشتريات',
        discount_purchase: 'خصومات المشتريات',
    },
    cost_centers: {
        cost_centers: 'مراكز التكلفة',
        main: 'الرئيسية',
        this_parent_cost_center_is_empty_without_childs: 'هذا مركز التكلفة الأب فارغ بدون مراكز فرعيه',
        new_chart_account: 'رسم جديد',
        name: 'اسم',
        code: 'الشفرة',
        debit: 'مدين',
        credit: 'دائن',
        sales_invoice: 'فاتورة المبيعات',
        parent_cost_center: 'مركز التكلفة الأب',
        is_parent_cost_center: 'هو مركز التكلفة الأب',
    },
    cost_center: {
        add_sales_invoice_to_cost_center: 'اضافة فاتورة مبيعات الى مركز التكلفة',
        account_id: 'رقم التعريفي للحساب',
        account_name: 'اسم الحساب',
        debit: 'مدين',
        credit: 'دائن',
        cost_center: 'مركز التكلفة',
        percentage: 'النسبة المئوية',
        amount: 'المبلغ',
        total: 'المجموع الكلي',
    },
    clients_loyalty: {
        clients_loyalty: 'ولاء العملاء',
        credit_types: 'نوع الائتمان',
        currency: 'العملة',
        conversion_factor: 'عامل التحويل',
    },
    loyalty_rules: {
        loyalty_rules: 'قواعد الولاء',
        name: 'اسم',
        categories: 'التصنيفات',
        proiority: 'الأولوية',
        collection_factor: 'عامل التحصيل',
        minimum_total_spent: 'الحد الأدنى لإجمالي الإنفاق',
        currency: 'العملة',
        expired_after: 'انتهاء الصلاحية بعد',
        interval_type: 'نوع الفاصل الزمني',
        loyalty_rules_section: 'قسم قواعد الولاء',
        new_loyalty_rule: 'قاعدة ولاء جديدة',
        from_minimum_total_spent: 'من الحد الأدنى لإجمالي الإنفاق',
        to_minimum_total_spent: 'إلى الحد الأدنى من إجمالي الإنفاق',
    },
    installment: {
        installment: 'تقسيط',
        client_no: 'رقم العميل',
        client_name: 'اسم العميل',
        client_email: 'البريد الإلكتروني للعميل',
        invoice_no: 'رقم الفاتورة',
        installment_agreement_amount: 'مبلغ اتفاقية التقسيط',
        installment_account: 'عدد الأقساط',
        installment_amount: 'تقسيط المبلغ',
        currency: 'العملة',
        period_installment: 'فترة القسط',
        installment_start_date: 'تاريخ بداية القسط',
        notes: 'ملاحظات',
        installment_agreements_section: 'قسم اتفاقيات التقسيط',
        installment_id: 'معرف القسط',
        from_installment_due_date: 'من تاريخ استحقاق القسط',
        to_installment_due_date: 'إلى تاريخ استحقاق القسط',
        client: 'العميل',
        invoice: 'الفاتورة',
        id_serial_no: 'الرقم التسلسلي للمعرف',
        client_info: 'معلومات العميل',
        payment_details: 'بيانات الدفع',
        due_date: 'تاريخ الاستحقاق',
        installments_list: 'قائمة الأقساط',
        installment_agreements: 'اتفاقيات التقسيط',
        paid_amount: 'المبلغ المدفوع',
        installment_pay: 'دفع القسط',
        payment_method: 'طريقة الدفع',
        amount: 'المبلغ',
        payment_date: 'موعد الدفع',
        payment_status: 'حالة السداد',
        collected_by: 'جمع بواسطة',
        ref_no: 'الرقم المرجعي',
        receipt_notes: 'ملاحظات الاستلام',
        attachment: 'المرفق',
        treasury: 'خزينة',
        pay: 'دفع',
        unpaid_amount: 'المبلغ الغير مدفوع',
        installment_payments: 'مدفوعات التقسيط',
        installment_payments_section: 'قسم مدفوعات الأقساط',
    },
    attachment_notes: {
        attachment_notes: 'أضف ملاحظات ومرفقات',
        note_date: 'تاريخ الملاحظة',
        note_time: 'الوقت الملاحظة',
        action_perform: 'أداء العمل',
        notes: 'ملاحظات',
        share_with_client: 'شارك مع العميل',
        upload_attachment: 'تحميل المرفق',
        file: 'الملف',
        date: 'التاريخ',
        name: 'الاسم',
    },
    invoice_sales_print: {
        simplified_tax_invoice: 'فاتورة',
        invoice_to: 'فاتورة إلى',
        invoice_number: 'رقم الفاتورة',
        invoice_date: 'تاريخ الفاتورة',
        item: 'المنتج',
        price: 'السعر',
        quantity: 'الكمية',
        total: 'المجموع',
        sub_total: 'اجمالى المبلغ',
        value_added: 'القيمة المضافة',
        zero: 'صفرية',
        balance_due: 'الرصيد المستحق',
        credit_note: 'فاتورة اشعار دائن',
        debit_note: 'فاتورة اشعار مدين',
        to: 'إلى',
        credit_note_number: 'رقم اشعار دائن',
        debit_note_number: 'رقم اشعار مدين',
        credit_note_date: 'تاريخ اشعار دائن',
        debit_note_date: 'تاريخ اشعار مدين',
        estimate_price: 'عرض الاسعار',
        estimate_number: 'رقم عرض الاسعار',
        estimate_date: 'تاريخ عرض الاسعار',
        sales_refund: 'إرجاع المبيعات',
        invoice_refund_no: 'رقم الفاتورة المرتجعة',
        invoice_refund_date: 'تاريخ الارتجاع',
        total_discount: 'إجمالي الخصم',
        total_shipping: 'إجمالي الشحن',
        payment_amount: 'المبلغ المدفوع',
        invoice_total_before_taxes: 'المجموع قبل الضريبة',
        invoice_total_taxes: 'مجموع الضريبة',
        discount: 'الخصم',
        tax_rate: 'نسبة الضريبة',
        tax_value: 'قيمة الضريبة',
        the_total_includes_VAT: 'المجموع شامل ضريبة القيمة المضافة',
        VAT_registration_number: 'رقم تسجيل ضريبة القيمة المضافة',
        VAT: 'VAT',
        price_including_tax: 'السعر شامل الضريبة',
        notes: 'ملاحظات',
        issue_date: 'تاريخ الاستحقاق',
        invoice_code: 'كود الفاتورة',
        unit: 'الوحدة',
    },
    invoice_sales_packing: {
        invoice_number: 'رقم الفاتورة',
        item: 'المنتج',
        description: 'الوصف',
        quantity: 'الكمية',
        total: 'المجموع',
        ship_to: 'الشحن إلى',
        bill_to: 'الفاتورة إلى',
    },
    invoice_purchase: {
        purchase_refund: 'إرجاع المشتريات',
        refund_date: 'تاريخ الارتجاع',
        refund_number: 'رقم الارتجاع',
        purchase_order: 'طلب الشراء',
        supplier: 'المورد',
        due_date: 'تاريخ الاستحقاق',
        item: 'المنتج',
        description: 'الوصف',
        unit_price: 'سعر الوحدة',
        quantity: 'الكمية',
        subtotal: 'اجمالى المبلغ',
        total: 'المجموع',
        value_added: 'القيمة المضافة',
        shipping: 'الشحن',
        purchase_invoice: 'فاتورة الشراء',
        paid: 'مدفوع',
        balance_due: 'الرصيد المستحق',
        p_o_invoice: 'رقم طلب الشراء',
    },
    pos_session: {
        total_credit: 'إجمالي اشعار الدائن',
        total_invoices: 'إجمالي الفواتير',
        total_refund: 'إجمالي المرتجعات',
        total_cash_movements_in: 'الحركات النقدية (اضافة)',
        total_cash_movements_out: 'الحركات النقدية (صرف)',
        cash_categories: 'الفئات النقدية',
        treasury_name: 'اسم الخزنة',
        pos_session: 'جلسة نقاط البيع',
        session: 'جلسة',
        cashier: 'المستخدم',
        POS_information: 'معلومات نقاط البيع',
        shift: 'الوردية',
        device: 'الجهاز',
        client: 'العميل',
        currency: 'العملة',
        session_information: 'معلومات الجلسة',
        opened_date: 'تاريخ الافتتاح',
        closed_date: 'تاريخ مغلق',
        pos_sessions: 'جلسات نقاط البيع',
        pos_sessions_section: 'قسم جلسات نقاط البيع',
        new_pos_session: 'جلسة نقطة بيع جديدة',
        pos_device: 'جهاز نقطة البيع',
        pos_shift: 'الوردية نقطة البيع',
        opened_from_date: 'افتتح من تاريخ',
        opened_to_date: 'افتتح الى تاريخ',
        closed_from_date: 'اغلق من تاريخ',
        closed_to_date: 'اغلق الى تاريخ',
        name: 'الاسم',
        shift_cashier: 'أمين صندوق الوردية',
        opening_closing: 'الافتتاح والإغلاق',
        session_sales_amount: 'مبلغ مبيعات الجلسة',
        payment_method: 'طريقة الدفع',
        received_amount: 'المبلغ المستلم',
        expected_amount: 'المبلغ المتوقع',
        difference: 'الفرق',
        received_type: 'نوع الاستلام',
        employee: 'موظف',
        treasury: 'خزنة',
        please_select_shift: 'من فضلك اختار الخزنة',
        invoice_info: 'معلومات الفاتورة',
        create_date: 'انشأ في',
        amount: 'المبلغ',
        invoices: 'الفواتير',
        session_id: 'معرف جلسة العمل',
        staff: 'الموظف',
        total: 'المجموع الكلي',
        print: 'طباعة',
        pdf: 'PDF',
        summary_by_payment_method: 'ملخص عن طريق طريقة الدفع',
        sales_details_by_payment_method: 'تفاصيل المبيعات بطريقة الدفع',
        sales: 'مبيعات',
        refunds: 'مرتجع',
        net: 'المبلغ النهائي',
        cash_movements: 'الحركات النقدية',
        time: 'الوقت',
        from_to: 'من/الى',
        note: 'ملحوظة',
        notes: 'ملاحظات',
        from_type: 'من نوع',
        cash_movement: 'الحركة النقدية',
        date_time: 'التاريخ والوقت',
        add_cash_in: 'إضافة مبلغ نقدي',
        add_cash_out: 'صرف مبلغ نقدي',
        take_cash_out: 'صرف مبلغ نقدي',
        cash_movement_section: 'قسم الحركة المالية',
        client_payment: 'دفع العميل',
        invoice: 'الفاتورة',
        payment_receipt: 'إيصال الدفع',
        payment_info: 'معلومات الدفع',
        amount_currency: 'المبلغ/العملة',
        summary_by_payment_methods: 'ملخص عن طرق الدفع',
        payment_for: 'دفع لـ',
        cash_amount: 'المبلغ النقدي',
        transaction_date: 'الوقت والتاريخ',
        added_by: 'بواسطة',
        balance: 'الرصيد',
        balances: 'الأرصدة',
        confirmed_amount: 'تأكيد المبلغ',
        details_currency: 'تفاصيل العملة ',
        details_other_currencies: 'تفاصيل العملات الاخرى',
        cash_category: 'الفئة',
        cash_category_no: 'العدد',
        cash_category_amount: 'المبلغ',
    },
    point_of_sales: {
        payment: 'الدفع',
        add_cash_in: 'إضافة مبلغ نقدي',
        add_cash_out: 'صرف مبلغ نقدي',
        subTotal: 'اجمالى المبلغ',
        refund_discount: 'خصم الإرجاع',
        discount: 'خصم',
        shipping: 'الشحن',
        tax: 'الضريبة',
        refund_rotal: 'إجمالي مبلغ الإرجاع',
        total: 'الإجمالي',
        qty: 'الكمية',
        dis: 'الخصم',
        price: 'السعر',
        remove: 'حذف',
        return: 'إرجاع',

        zero: 'صفريه',
        recovery: 'المعفاة',
        net_payable: 'المبلغ المستحق',
        paid: 'مدفوع',
        change: 'المتبقي',
        with_discount: 'مع الخصم',
        view_session: 'عرض الجلسة',
        previous_invoice_total: 'مجموع الفاتورة السابقة',
        total_current_bill_amount: 'إجمالي مبلغ الفاتورة الحالي',
        total_amount_before_return: 'إجمالي مبلغ قبل الإرجاع',
        new_order: 'طلب جديد',
        cleaning: 'قيد العمل',
        ready: 'الطلبات المجمعة',
        pickups: 'الطلبات الجاهزة',
        search: 'البحث',
        invoices: 'الفنواتير',
        express: 'تعبير',
        notes: 'ملاحظات',
        save: 'حفظ',
        quick_drop: 'إسقاط سريع',
        retail: 'بيع بالتجزئة',
        submit_wed: 'إرسال',
        edit_item_notes: 'تعديل ملاحظات المنتج',
        color: 'اللون',
        damage: 'التلف',
        stains: 'البقع',
        upcharge: 'التكلفة الإضافية',
        pricing: 'المالية',
        pricing_information: 'معلومات المالية',
        confirm: 'تأكيد',
        received: 'موعد الاستلام',
        delivery: 'موعد التسليم',
        item: 'المنتج',
        refund_amount: 'المبلغ المرتجع',
        details: 'التفاصيل',
        customer_name: 'اسم العميل',
        mobile: 'الجوال',
        status: 'الحالة',
        invoice_code: 'رقم الطلبية',
        quantity: 'الكمية',
        invoice_created_date: 'تاريخ انشاء الطلبية',
        invoice_received_date: 'تاريخ تسليم الطلبية',
        filter_for_details: 'فلتر التفاصيل',
        customer: 'العميل',
        by_date: 'حسب التاريخ',
        the_most_recent: 'الأحدث',
        the_oldest: 'الأقدم',
        today: 'اليوم',
        last_3_days: 'آخر 3 أيام',
        last_month: 'آخر شهر',
        code: 'الكود',
        attached_reasons: 'وصف القطع المستلمة',
        fabrics_problems: 'مشاكل الاقمشة',
        fabric_problem: 'مشكلة القماش',
        reason: 'السبب',
        type: 'النوع',

    },
    pos_invoice: {
        invoice_no: 'رقم الفاتورة',
        invoice_info: 'معلومات الفاتورة',
        date_time: 'التاريخ والوقت',
        amount_currency: 'المبلغ/العملة',
        pos_client: 'عميل نقطة البيع',
        added_payment: 'المدفوعات المضافة',
        amount: 'المبلغ',
        currency: 'العملة',
        attachment: 'المرفقات',
        add_payment: 'إضافة دفع',
        pdf: 'PDF',
        image: 'صورة',
        view: 'عرض',
        payment_info: 'معلومات الدفع',
        amount_and_status: 'المبلغ والحالة',
        invoice_receipt: 'إيصال الفاتورة',
        pos_shift: 'نقطة بيع الخزنة',
        payments_for_invoice: 'المدفوعات للفاتورة',
        payment_summary: 'ملخص الدفع',
        invoice_total: 'مجموع الفاتورة',
        refunded: 'مرتجع',
        paid_amount: 'المبلغ المدفوع',
        unpaid_amount: 'المبلغ الغير مدفوع',
        invoice_id: 'معرف الفاتورة',
    },
    pos_refund: {
        refund_info: 'معلومات المرتجع',
        date_time: 'الوقت والتاريخ',
        amount_currency: 'العملة/المبلغ',
        pos_client: 'العميل',
        added_payment: 'الدفع المضاف',
        amount: 'المبلغ',
        currency: 'العملة',
        attachment: 'المرفق',
        add_payment: 'اضافة دفع',
        pdf: 'PDF',
        image: 'صورة',
        view: 'عرض',
        payment_info: 'معلومات الدفع',
        amount_and_status: 'المبلغ والحالة',
        refund_receipt: 'إيصال مرتجع',
        pos_shift: 'وردية نقطة البيع',
        payments_for_invoice: 'المدفوعات للفاتورة',
        payment_summary: 'ملخص الدفع',
    },
    payment_print: {
        receipt: 'إيصال',
    },
    cost_transactions: {
        transactions: 'المعاملات',
        transaction: 'المعاملة',
        transactions_section: 'قسم المعاملات',
        debit: 'المدين',
        credit: 'الإئتمان',
        accounts: 'الحسابات',
        cost_centers: 'مراكز التكلفة',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
    },
    accounts_without_cost_center: {
        accounts_without_cost_center: 'حسابات بدون مركز تكلفة',
        assign_cost_centers: 'تعيين مراكز التكلفة',
        cost_center: 'مركز التكلفة',
        percentage: 'النسبة المئوية',
        auto: 'بشكل تلقائي',
        accounts_without_cost_center_section: 'قسم الحسابات بدون مركز التكلفة',
        account: 'الحساب',
        assign_cost_center: 'تعيين مركز التكلفة',
        id: 'رقم التعريف',
        name: 'الاسم',
        code: 'الكود',
    },
    accounts_with_cost_center: {
        accounts_with_cost_center_section: 'قسم الحسابات مع مركز التكلفة',
        journal_account_id: 'معرف حساب القيد',
        journal_account: 'حساب القيد',
        journal_account_code: 'كود حساب القيد',
        cost_center: 'مركز التكلفة',
        center_code: 'كود المركز',
        percentage: 'النسبة المئوية',
    },
    cost_center_transactions: {
        cost_center_transactions: 'معاملات مركز التكلفة',
        transaction: 'المعاملة',
        cost_center_transactions_section: 'قسم معاملات مركز التكلفة',
        debit: 'المدين',
        credit: 'الإئتمان',
        accounts: 'الحسابات',
        cost_centers: 'مراكز التكلفة',
        cost_center: 'مركز التكلفة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        amount: 'المبلغ',
        summary: 'الملخص',
        code: 'الكود',
        parent_cost_centers: 'مراكز التكلفة الرئيسية',
        secondary_cost_centers: 'مراكز التكلفة الثانوية',
        balance: 'الرصيد',
    },
    user_settings: {
        change_email_password: 'تغيير البريد الالكتروني وكلمة المرور',
        change_email: 'تغيير البريد الالكتروني',
        current_password: 'كلمة المرور الحالية',
        email: 'البريد الالكتروني',
        new_password: 'كلمة المرور الجديدة',
        confirm_password: 'تأكيد كلمة المرور',
        change_password: 'تغيير كلمة المرور',
    },
    daily_brief_report_invoices: {
        daily_brief_report_invoices: 'تقرير يومي مختصر للفواتير',
        daily_brief_report_payments: 'تقرير يومي مختصر للدفعات المالية',
        customer: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيفت من قبل',
        show_report: 'عرض التقرير',
        summary: 'الملخص',
        date: 'التاريخ',
        paid: 'مدفوع',
        unpaid: 'غير مدفوعة',
        refund: 'مرتجع',
        total: 'المجموع',
        details: 'التفاصيل',
        number: 'الرقم',
        employee: 'الموظف',
        ref_no: 'الرقم المصدر',
        collected_by: 'جمع بواسطة',
        invoice_number: 'رقم الفاتورة',
        payment_method: 'طريقة الدفع',
    },
    stocks_report: {
        stocks_report: 'تقرير المخازن',
        group_by: 'تجميع حسب',
        category: 'التصنيف',
        brand: 'العلامة التجارية',
        order_by: 'ترتيب حسب',
        product_name: 'اسم المنتج',
        stock_level_descending: 'مستوى المخزون تنازلي',
        stock_level_ascending: 'مستوى المخزون تصاعديًا',
        hide_zero_values: 'إخفاء القيم الصفرية',
        total_count: 'العدد الإجمالي',
        generate_sheet: 'إنشاء الورقة',
        code: 'الكود',
        name: 'الاسم',
        software_count: 'عدد المنتجات',
        barcode: 'الباركود',
        warehouse: 'المخزن',
    },
    inventory_transaction_report: {
        inventory_transaction_report: 'تقرير حركة المخازن',
        product_name: 'اسم المنتج',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        type: 'النوع',
        refund_receipt: 'إيصال المرتجع',
        sales_invoice: 'فاتورة المبيعات',
        purchase_invoice: 'فاتورة الشراء',
        inventory: 'المخزن',
        product: 'المنتج',
        primary_warehouse_transactions_summary: 'ملخص حركات المخازن الأولية',
        inward: 'الداخل',
        outward: 'الخارج',
        purchase_invoices: 'فواتير الشراء',
        refund_receipts: 'إيصالات المرتجع',
        transfer: 'التحويل',
        manual: 'يدوي',
        total: 'المجموع',
        sales_invoices: 'فواتير المبيعات',
        purchase_refund: 'مرتجع الشراء',
        total_transactions: 'إجمالي الحركات',
        sales_refund: 'مرتجع المبيعات',
        credit_note: 'مذكرة الائتمان',
        inventory_transaction_details: 'تفاصيل حركة المخزون',
        inventory_detailed_transactions: 'حركات المخزون المفصلة',
        time: 'الوقت',
        warehouse: 'المخزن',
        stock_level_after: 'مستوى المخزون بعد',
        inventory_transactions: 'الإذونات المخزنية',
    },

    estimated_inventory: {
        estimated_inventory: 'القيمة التقديرية للمخزون',
        code: 'الكود',
        product_name: 'اسم المنتج',
        qty: 'الكمية',
        current_retail_price: 'سعر البيع',
        average_retail_price: 'سعر الشراء',
        expected_total_retail_price: 'المبلغ المتوقع',
        total_buy_price: 'المبلغ الإجمالي',
        expected_profit: 'الربح المتوقع',
        calculate_using: 'الحساب باستخدام',
        average_buy_price: 'متوسط سعر الشراء',
        buy_price: 'سعر الشراء',
    },
    summary_stock_balance: {
        summary_stock_balance: 'ملخص رصيد المخزون',
        code: 'الكود',
        product_name: 'اسم المنتج',
        category: 'التصنيف',
        brand: 'العلامة التجارية',
        total: 'المجموع',
    },
    product_average_cost: {
        product_average_cost: 'متوسط تكلفة المنتج',
        barcode: 'الباركود',
        code: 'الكود',
        product_name: 'اسم المنتج',
        qty: 'الكمية',
        before_quantity_available_warehouse: 'Before الكمية المتوفرة في المخزن في بداية المدة',
        inward_quantity_entering_warehouses: 'Inward الكمية التي تدخل المخازن',
        outward_quantity_coming_warehouses: 'Outward الكمية التي تخرج من المخازن',
        next_entrance_exit: 'Next المدخل – المخرج ',
    },
    daily_product_sales: {
        daily_product_sales: 'مبيعات المنتجات اليومية',
        daily_product_sales_details: 'تفاصيل مبيعات المنتجات اليومية',
        day: 'اليوم',
        qty: 'الكمية',
        discount: 'الخصم',
        price: 'السعر',
        SalesInvoice: 'فاتورة المبيعات',
        SalesInvoiceRefund: 'مرتجع فاتورة المبيعات',
        show_draft: 'عرض المسودة',
        proudect_code: 'كود المنتج',
        sold_by: 'المباع بواسطة',
        invoice_no: 'رقم الفاتورة',
        client: 'العميل',
        unit_price: 'سعر الوحدة',
        number: 'الرقم',
    },
    store_reports: {
        daily_payments: 'المدفوعات اليومية',
        inventory_stocktaking_sheet: 'ورقة تسوية المخزون',
        inventory_transactions: 'حركات المخزون',
        weekly_payments: 'المدفوعات الأسبوعية',
        inventory_detailed_transactions: 'حركات المخزون المفصلة',
        monthly_payments_details: 'تفاصيل المدفوعات الشهرية',
        yearly_payments_details: 'تفاصيل المدفوعات السنوية',
        inventory_value: 'قيمة المخزون',
        summary_of_stock_balance: 'ملخص رصيد المخزون',
        product_average_cost: 'متوسط تكلفة المنتجات',
        product_sales_Periodic_Reports: 'تقارير المنتجات المباعة الدورية',
    },

    client_reports: {
        client_sales: 'مبيعات العميل',
        aged_debtors: 'العملاء المدينون',
        clients_list: 'قائمة العملاء',
        clients_balance: 'رصيد العملاء',
        clients_sales: 'مبيعات العملاء',
        clients_payments: 'مدفوعات العملاء',
        clients_statement: 'كشف حساب العملاء',
        clients_appointments: 'مواعيد العملاء',
        clients_installments: 'أقساط العملاء',
    },
    customer_appointment_report: {
        customer_appointment_report: 'تقرير مواعيد العميل',
        customer: 'العميل',
        date: 'التاريخ',
        start_time: 'وقت البدء',
        interval: 'الفترة',
        procedure: 'الإجراء',
        recurring_type: 'النوع التكرار',
        recurring_date: 'التاريخ التكرار',
        notes: 'ملاحظات',
        status: 'الحالة',
        start_at: 'تبدأ في',
        appintment_date: 'تاريخ الموعد',
        assigned_staff: 'اضيف بواسطة',
        appointments_report_group_by_all: 'تقرير المواعيد - تجميع حسب الكل',
    },

    customer_installment_report: {
        clients_installments_group_by_client: 'أقساط العملاء - مجمعة حسب العميل',
        client: 'العميل',
        email: 'البريد الإلكتروني',
        address: 'العنوان',
        mobile: 'الجوال',
        phone: 'الهاتف',
        due_date: 'تاريخ الاستحقاق',
        status: 'الحالة',
        total_amount: 'المبلغ الإجمالي',
        paid_amount: 'المبلغ المدفوع',
        remaining_amount: 'المبلغ المتبقي',
    },
    customer_sales_report: {
        customer_sales_report: 'تقرير مبيعات العملاء',
        customer: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        invoice: 'الفاتورة',
        date: 'التاريخ',
        type: 'النوع',
        name: 'الاسم',
        document_number: 'رقم المستند',
        shipping_fees: 'مصاريف الشحن',
        value: 'القيمة',
        discounts: 'الخصومات',
        taxes: 'الضرائب',
        total: 'المجموع',
    },
    customer_payments_report: {
        customer_payments_report: 'تقرير مدفوعات العميل',
        customer: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        date: 'التاريخ',
        type: 'النوع',
        name: 'الاسم',
        document_number: 'رقم المستند',
        total: 'المجموع',
        id: 'المعرف',
        client_number: 'كود العميل',
        client_name: 'اسم العميل',
        payment_method: 'طريقة الدفع',
        treasury: 'خزينة',
        treasury_bank: 'خزينة / بنك',
        amount: 'المبلغ',
        assigned_staff: 'الموظف',
        employee: 'الموظف',
        currency: 'العملة',
    },

    customer_balance_report: {
        customer_balance_report: 'تقرير رصيد العميل',
        customer: 'العميل',
        assigned_staff: 'الموظف',
        balances_report_group_by_all: 'تقرير الرصيد - تجميع حسب الكل',
        code: 'الكود',
        account_no: 'رقم الحساب',
        name: 'الاسم',
        manual_status: 'الحالة اليدوية',
        debit_before: 'مدين قبل',
        debit_after: 'مدين بعد',
        balance_after: 'دائن بعد',
        balance_before: 'الرصيد قبل',
        total_sales: 'مجموع المبيعات',
        total_refund: 'مجموع المرتجعات',
        net_sales: 'صافي المبيعات',
        total_payments: 'مجموع المدفوعات',
        balance: 'الرصيد',
        phone: 'الهاتف',
        address: 'العنوان',
        category: 'التصنيف',
        hide_zero_balance: 'إخفاء الرصيد الصفري',
        show_details: 'عرض التفاصيل',
        employee: 'الموظف',
        settlements: 'التسوية',
    },
    aged_debtors: {
        aged_debtors: 'العملاء المدينون',
        customer: 'العميل',
        from_date: 'من التاريخ',
        client: 'العميل',
        not_overdue: 'لم يتأخر',
        f_0_to_30_day: '0 إلى 30 يومًا',
        f_31_to_60_day: '31 إلى 60 يومًا',
        f_61_to_90_day: '61 إلى 90 يومًا',
        f_91_to_120_day: '91 إلى 120 يومًا',
        more_than_121_day: '121+ يوم',
        sum_over_due: 'مجموع المتأخرات',
        total: 'المجموع',
    },
    sales_by_client_report: {
        sales_by_client_report: 'تقرير المبيعات',
        customer: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيف بواسطة',
        show_report: 'عرض التقرير',
        date: 'التاريخ',
        paid: 'مدفوع',
        unpaid: 'غير مدفوعة',
        refund: 'مرتجع',
        total: 'المجموع',
        number: 'الرقم',
        employee: 'الموظف',
        invoice_number: 'رقم الفاتورة.',
        sales_man: 'مندوب المبيعات',
        day: 'اليوم',
        week: 'الاسبوع',
        month: 'الشهر',
        year: 'السنة',
        tax: 'الضريبة',
    },
    payments_sales_report: {
        payments_sales_report: 'تقرير مدفوعات المبيعات',
        customer: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيف بواسطة',
        show_report: 'عرض التقرير',
        date: 'التاريخ',
        paid: 'مدفوع',
        unpaid: 'غير مدفوعة',
        refund: 'مرتجع',
        total: 'المجموع',
        number: 'الرقم',
        employee: 'الموظف',
        invoice_number: 'رقم الفاتورة.',
        sales_man: 'مندوب المبيعات',
        day: 'اليوم',
        week: 'الاسبوع',
        month: 'الشهر',
        year: 'السنة',
        payment_method: 'طريقة الدفع',
        collected_by: 'جمع بواسطة',
        id: 'رقم',
        payment_id: 'رقم معرف',
        invoice_code: 'رقم الفاتورة',
        ref_no: 'الرقم المصدر',
        label: 'العنوان',
    },
    suppliers_reports: {
        suppliers_reports: 'تقارير الموردين',
        country: 'الدولة',
        city: 'المدينة',
        supplier_code: 'كود المورد',
        full_name: 'الاسم كامل',
        address: 'العنوان',
        mobile: 'الجوال',
        phone: 'الهاتف',
        credit_min: 'اقل قيمة للدائن',
        credit_max: 'اعلى قيمة للدائن',
        debit_min: 'اقل قيمة للمدين',
        debit_max: 'اعلى قيمة للمدين',
        supplier_balance: 'رصيد المورد',
    },
    supplier_balance_report: {
        supplier_balance_report: 'تقرير رصيد المورد',
        supplier: 'المورد',
        assigned_staff: 'الموظفين المعينين',
        balances_report_group_by_all: 'تقرير الأرصدة - المجموعة حسب المورد',
        code: 'الكود',
        account_no: 'رقم الحساب',
        name: 'الاسم',
        balance_before: 'الرصيد قبل',
        total_purchase: 'إجمالي الشراء',
        total_refund: 'إجمالي المبلغ المرتجع',
        net_purchase: 'صافي الشراء',
        total_payments: 'مجموع المدفوعات',
        balance: 'الرصيد',
        phone: 'هاتف',
        address: 'العنوان',
        settlements: 'التسوية',
    },
    supplier_purchase_report: {
        supplier_purchase_report: 'تقرير مشتريات الموردين',
        supplier: 'المورد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        invoice: 'الفاتورة',
        date: 'التاريخ',
        type: 'النوع',
        name: 'الاسم',
        document_number: 'رقم المستند',
        value: 'القيمة',
        discounts: 'الخصومات',
        taxes: 'الضرائب',
        total: 'المجموع',
        currency: 'العملة',
    },
    purchase_report: {
        purchase_report: 'تقرير المشتريات',
        supplier: 'المورد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيف بواسطة',
        show_report: 'عرض التقرير',
        date: 'التاريخ',
        paid: 'مدفوع',
        unpaid: 'غير مدفوعة',
        refund: 'مرتجع',
        total: 'المجموع',
        number: 'الرقم',
        employee: 'الموظف',
        invoice_number: 'رقم الفاتورة.',
        sales_man: 'مندوب المبيعات',
        day: 'اليوم',
        week: 'الاسبوع',
        month: 'الشهر',
        year: 'السنة',
    },
    product_purchase_report: {
        product_purchase_report: 'تقرير شراء المنتج',
        supplier: 'المورد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        invoice: 'الفاتورة',
        date: 'التاريخ',
        type: 'النوع',
        name: 'الاسم',
        document_number: 'رقم المستند',
        value: 'القيمة',
        discounts: 'الخصومات',
        taxes: 'الضرائب',
        total: 'المجموع',
        currency: 'العملة',
        id: 'المعرف',
        item: 'المنتج',
        product_code: 'كود المنتج',
        added_by: 'أضيف من قبل',
        unit_price: 'سعر الوحدة',
        total_taxes: 'إجمالي الضرائب',
        quantity: 'كمية',
        product: 'المنتج',
        employee: 'الموظف',
        description: 'الوصف',
    },
    purchases_payments_periodic_reports: {
        purchases_payments_periodic_reports: 'تقارير مدفوعات المشتريات الدورية',
        supplier: 'المورد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        added_by: 'أضيف بواسطة',
        show_report: 'عرض التقرير',
        date: 'التاريخ',
        total: 'المجموع',
        number: 'الرقم',
        employee: 'الموظف',
        invoice_number: 'رقم الفاتورة.',
        sales_man: 'مندوب المبيعات',
        day: 'اليوم',
        week: 'الاسبوع',
        month: 'الشهر',
        year: 'السنة',
        payment_method: 'طريقة الدفع',
        id: 'المعرف',
        ref: 'المرجع',
    },
    document_type_setting: {
        document_type_setting: 'إعداد نوع المستند',
        document_type_setting_section: 'قسم إعداد نوع الوثيقة',
        document_type: 'نوع الوثيقة',
        document_name: 'اسم الملف',
        document_slug: 'المستند slug',
        status_accounting: 'حالة المحاسبة',
        columns: 'الأعمدة',
        new_document_type_setting: 'إعداد نوع مستند جديد',
        no_journal: 'عدد القيود',
        column: 'العمود',
        description_ar: 'الوصف عربي',
        description_en: 'الوصف انجليزي',
        description_columns: 'وصف الأعمدة',
    },
    login_page: {
        Discover_Amazing_Sajlha_with_great_build_tools: "اكتشف سجلها المدهش <br /> مع أدوات بناء رائعة",
        Welcome_to_Sajlha: 'مرحبا بكم في سجلها',
        Email: 'البريد الإلكتروني',
        Password: 'كلمة المرور',
        Sign_In: 'تسجيل الدخول',
        Sign_Up: 'اشتراك',
        Enter_your_details_to_create_your_account: 'أدخل التفاصيل الخاصة بك لإنشاء حسابك',
        Submit: 'يُقدِّم',
        Cancel: 'يلغي',
        Forgotten_Password: 'هل نسيت كلمة المرور؟',
        Enter_your_email_to_reset_your_password: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
        Terms: 'الشروط والاحكام',
        Plans: 'الخطط',
        Contact_Us: 'اتصل بنا',
        Username_is_required: 'اسم المستخدم مطلوب',
        Password_is_required: 'كلمة المرور مطلوبة',
        Full_name_is_required: 'الإسم الكامل ضروري',
        Email_is_required: 'البريد الالكتروني مطلوب',
        The_value_is_not_a_valid_email_address: 'القيمة ليست عنوان بريد إلكتروني صالحًا',
        Confirm_password_is_required: 'تأكيد كلمة المرور مطلوب',
        The_password_and_its_confirm_are_not_the_same: 'كلمة المرور وتأكيدها ليسا متطابقين',
        You_should_agree_terms_and_conditions: 'يجب أن توافق على الشروط والأحكام',
        Please_provide_correct_data: 'من فضلك, قدم البيانات الصحيحة!',

        unlimited_possibilities_to_manage_your_business: 'إمكانيات لا محدودة لإدارة أعمالك',
        we_share_your_journey: 'نشاركك رحلتك',
        and_we_help_you_with_all_your_procedures: 'ونساعدك بجميع إجراءاتك',
    },
    errors: {
        the_page_not_found: 'الصفحة غير موجودة',
        you_dont_have_permission: 'ليس لديك صلاحية',
    },
    journals_setting: {
        journals_setting: 'إعداد القيود',
        document_name: 'اسم الملف',
        journal_title: 'عنوان القيد',
        journal_type: 'نوع القيد',
        status: 'الحالة',
        is_active: 'نشط',
        journal: 'القيد',
        column: 'عمود',
        general_account: 'حساب عام',
        type: 'النوع',
        depend_on: 'يعتمد على',
        special_account: 'حساب خاص',
        columns: 'الأعمدة',
        branch: 'فرع',
        inventory: 'مخزن',
        customer: 'عميل',
        vendor: 'بائع',
        simple: 'بسيط',
        complex: 'معقد',
        none: 'لا يوجد',
        decrease: 'نقصان',
        increase: 'زيادة',
        new_journals_setting: 'اضف إعداد قيد',
        credit: 'دائن',
        debit: 'مدين',
        account_type: 'نوع الحساب',
        item: 'منتج',
        currency: 'العملة',
    },
    currency_exchange: {
        add_currency_exchange: 'إضافة تحويل عملات',
        currency_exchange: 'تحويل العملات',
        system_currency: 'عملة النظام',
        currency: 'العملة',
        cannot_added_currency_conversion: 'لا يمكن اضافة لتحويل العملات لانه لا يوجد عملة افتراضية للنظام برجاء الذهاب الى إعدادت الصفحة الشخصية واختيار عملة للنظام',
        standard_val: 'القيمة الاساسية',
        custom_val: 'القيمة المخصصة',
    },
    journal_transactions: {
        source: 'المصدر',
        journal_account: 'البند المحاسبي',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        journal_transactions: 'معاملات القيود',
        date: 'التاريخ',
        number: 'الرقم',
        account: 'الحساب',
        description: 'الوصف',
        debit: 'مدين',
        credit: 'دائن',
        total: 'المجموع',
        before_amount: 'المبلغ قبل',
        after_amount: 'المبلغ بعد',
    },
    notifications: {
        user_notifications: 'إشعارات المستخدم',
        no_new_notifications: 'لا يوجد اشعارات جديدة',
    },
    notifications_config: {
        notifications_config: 'إعدادات الإشعارات',
        document_type: 'نوع الوثيقة',
        document_slug: 'المستند slug',
        status: 'حالة',
        action: 'الحدث',
        columns: 'الأعمدة',
        interested_roles: 'الصلاحيات المهتمة',
        interested_users: 'المستخدمين المهتمين',
        content: 'المحتوى',
        is_active: 'نشط',
        new_notification_config: 'إعدادات الإشعارات جديد',
        added_by: 'اضيف بواسطة',
        created_at: 'تاريخ الإنشاء',
        customer: 'العميل',
        supplier: 'المورد',
        transaction_id: 'رقم المعاملة',
    },
    custom_notifications: {
        custom_notifications: 'إعدادات إشعارات مخصصة',
        item_min_qty: 'وصلت كمية المنتج إلى الحد الأدنى',
        installment_due_date: 'تاريخ استحقاق القسط يساوي التاريخ الحالي',
        interested_roles: 'الصلاحيات المهتمة',
    },
    inventory_checking: {
        inventory: 'المخزن',
        product: 'المنتج',
        available_stock_qty: 'الكمية المتوفرة',
        last_validated_date: 'آخر تاريخ تم التحقق منه',
        last_value: 'آخر قيمة',
        actual_qty: 'الكمية الفعلية',
        qty: 'الكمية',
        inventory_checking: 'تسوية المخزون',
        sys_qty: 'الكمية في النظام',
        confirm_qty: 'اعتماد الكمية',
    },
    treasury_checking_history: {
        treasury_checking: 'سجل المطابقة',
        treasury: 'الخزينة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        current_amount: 'المبلغ الحالي',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },
    inventory_checking_history: {
        inventory: 'المخزن',
        product: 'المنتج',
        available_stock_qty: 'الكمية المتوفرة',
        last_validated_date: 'آخر تاريخ تم التحقق منه',
        last_value: 'آخر قيمة',
        actual_qty: 'الكمية الفعلية',
        qty: 'الكمية',
        inventory_checking: 'تسوية المخزون',
        sys_qty: 'الكمية في النظام',
        confirm_qty: 'اعتماد الكمية',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
    },
    profit_loss_report: {
        period: 'فترة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        profit_loss_report: 'تقرير الربح والخسارة',
        total: 'المجموع',
        income: 'الدخل',
        sales_invoices: 'فواتير المبيعات',
        income_total: 'إجمالي الدخل',
        expenses: 'المصروفات',
        refund_receipts: 'إيصالات المرتجع',
        purchase_invoices: 'فواتير الشراء',
        expenses_total: 'مجموع المصروفات',
        profit: 'الربح',
        monthly: 'شهريا',
        yearly: 'سنوي',
        quarterly: 'ربعي',
    },
    income_report: {
        collected_by: 'جمع بواسطة',
        treasury: 'الخزينة',
        currency: 'العملة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        user: 'المستخدم',
        income_report: 'تقرير الواردات',
        expenses_report: 'تقرير المضروفات',
        date: 'التاريخ',
        code: 'الكود',
        category: 'التصنيف',
        vendor: 'البائع',
        account: 'الحساب',
        staff: 'الموظف',
        notes: 'الملاحظات',
        amount: 'المبلغ',
        total: 'المجموع',
        daily: 'يومي',
        weekly: 'أسبوعي',
        monthly: 'شهري',
        yearly: 'سنوي',
    },
    balance_sheet: {
        financial_year: 'السنة المالية',
        balance_sheet: 'رصيد الميزانية',
        subtotal: 'اجمالى المبلغ',
        label: 'الاسم',
        amount: 'المبلغ',
        total: 'المجموع الكلي',
    },
    chart_accounts_directory: {
        account_level: 'مستوى الحساب',
        account_normal: 'حساب عادي',
        account_order: 'ترتيب الحسابات',
        chart_accounts_directory: 'تقرير شجرة الحسابات',
        account_code: 'رمز الحساب',
        account_name: 'أسم الحساب',
        account_type: 'نوع الحساب',
        main_account: 'الحساب الرئيسي',
        sub_account: 'حساب فرعي',
        asc: 'تصاعدي',
        desc: 'تنازلي',
        debit: 'مدين',
        credit: 'دائن',
        customer: 'العميل',
        supplier: 'المورد',
        treasury: 'الخزينة',
        inventory: 'المخزن',
        account_expects: 'باستثناء الحسابات',
    },
    balance_balances_review: {
        financial_year: 'السنة المالية',
        account: 'الحساب',
        balance_balances_review: 'تقرير ميزان مراجعة الارصدة',
        name: 'الاسم',
        code: 'الكود',
        debit: 'مدين',
        credit: 'دائن',
        subtotal: 'اجمالى المبلغ',
    },
    tax_report: {
        tax: 'الضريبة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        tax_report: 'تقرير الضرائب',
        sales: 'المبيعات',
        modification: 'تعديل',
        due_tax: 'الضريبة المستحقة',
        total: 'المجموع',
        purchase: 'المشتريات',
        others: 'آخرون',
        settlement_completed: 'اكتملت التسوية',

        taxable_amount: 'المبلغ الخاضع للضريبة',
        taxes: 'الضرائب',
        id: 'المعرف',
        financier: 'الممول',
        date: 'التاريخ',
        item: 'المنتج',
        description: 'الوضف',
        total_tax: 'إجمالي الضريبة',
    },
    financial_transactions_report: {
        currency: 'العملة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        financial_transactions_report: 'تقرير المعاملات المالية',
        date: 'التاريخ',
        description: 'الوصف',
        amount: 'المبلغ',
        balance_after: 'الرصيد بعد',
        balance_beginning_period: 'الرصيد بداية المدة',
        total_revenue: 'إجمالي الإيرادات',
        total_expenses: 'إجمالي المصروفات',
        balance_end_period: 'رصيد نهاية المدة',
    },
    items_report: {
        inventory: 'المخزن',
        product: 'المنتج',
        inventory_name: 'المخزن',
        item_name: 'المنتج',
        available_stock_qty: 'الكمية المتوفرة',
        qty: 'الكمية',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        quantity: 'الكمية',
        trans_affect_name: 'تأثير المعاملة',
        transaction_id: 'رقم المعاملة',
        items_report: 'تقرير المنتجات',
    },

    activity_log_report: {
        action: 'الحدث',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        user: 'المستخدم',
        activity_log_report: 'سجل نشاط النظام',

        create_invoice: 'إنشاء فاتورة',
        add_invoice_payment: 'إضافة دفع الفاتورة',
        add_expense: 'إضافة المصاريف',
        add_income: 'إضافة الدخل',
        add_client: 'إضافة عميل',
        add_product: 'إضافة منتج',
        print_reports: 'طباعة التقارير',
        update_system_settings: 'تحديث إعدادات النظام',
        login: 'تسجيل الدخول',
        add_sold_stock: 'إضافة الأسهم المباعة',
        manual_stock_adjustment: 'التعديل اليدوي للمخزون',
    },

    supplier_checking: {
        supplier_checking: 'مطابقة رصيد المورد',
        supplier: 'المورد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        credit: 'المبلغ الحالي دائن',
        debit: 'المبلغ الحالي مدين',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },

    supplier_transactions_report: {
        supplier: 'المورد',
        amount: 'المبلغ',
        exchange_rate: 'سعر الصرف',
        doc_type: 'نوع الوثيقة',
        doc_title: 'عنوان الوثيقة',
        balance_before: 'الرصيد قبل',
        balance_after: 'الرصيد بعد',
        wanted_amount: 'المبلغ المطلوب',
        paid_amount: 'المبلغ المدفوع',
        currency: 'العملة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        supplier_transactions_report: 'تقرير معاملات المورد',
        doc_created_by: 'اضيف بواسطة',
        doc_created_at: 'اضيف بتاريخ',
    },
    customer_transactions_report: {
        customer: 'العميل',
        amount: 'المبلغ',
        exchange_rate: 'سعر الصرف',
        doc_type: 'نوع الوثيقة',
        doc_number: 'رقم الوثيقة',
        doc_title: 'عنوان الوثيقة',
        balance_before: 'الرصيد قبل',
        balance_after: 'الرصيد بعد',
        wanted_amount: 'المبلغ المطلوب',
        paid_amount: 'المبلغ المدفوع',
        currency: 'العملة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        customer_transactions_report: 'تقرير معاملات العميل',
    },
    items_transactions_report: {
        inventory: 'المخزن',
        product: 'المنتج',
        inventory_name: 'المخزن',
        item_name: 'المنتج',
        available_stock_qty: 'الكمية المتوفرة',
        qty: 'الكمية',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        quantity: 'الكمية',
        trans_affect_name: 'تأثير المعاملة',
        transaction_id: 'رقم المعاملة',
        items_transactions_report: 'تقرير حركة المواد',
        quantity_after: 'الكمية بعد',
        quantity_before: 'الكمية قبل',
        transaction_type: 'نوع المعاملة',
    },
    export_import: {
        export_template: 'تصدير القالب',
        export: 'تصدير',
        import_data: 'استيراد البيانات',
        upload_file: 'رفع ملف',
        row: 'صف',
        attribute: 'الحقل',
        errors: 'أخطاء',
        values: 'قيم',
        select_fields: 'تحديد الحقول',
        field_in_the_system: 'الحقل في النظام',
        corresponding_field_in_the_file: 'الحقل المقابل في الملف',
        import_the_first_row_of_the_file: 'استيراد الصف الأول من الملف',
        the_file_to_be_imported: 'استيراد الصف الأول من الملف',
        specified: 'المحدد',

    },
    available_features: {
        new_available_feature: 'ميزة جديدة',
        new_available_sub_feature: 'ميزة فرعية جديدة',
        name: 'الاسم',
        key: 'المفتاح',
        available_features: 'الميزات المتوفرة',
        available_sub_features: 'الميزات الفرعية المتوفرة',
        description: 'الوصف',
        sub_features: 'الميزات الفرعية',
    },

    secondary_accounts_tree: {
        secondary_accounts_tree: 'شجرة الحسابات الثانوية',
        add_new_account: 'اضافة حساب ثانوي جديد',
        add_new_sub_account: 'اضافة حساب ثانوي فرعي جديد',
        name: 'الاسم',
        description: 'الوصف',
        upload_image: 'تحميل صورة',
    },
    groups_accounts: {
        name: 'الاسم',
        secondary_accounts: 'الحسابات الثانوية',
        created_at: 'تاريخ الإنشاء',
        groups_accounts: 'مجموعات الحسابات',
        add_new_group: 'أضف مجموعة حسابية جديدة',
    },
    chart_account_list: {
        name: 'الاسم',
        code: 'الكود',
        parent_name: 'الحساب الرئيسي',
        amount: 'المبلغ',
        currency: 'العملة',
        type_account: 'نوع الحساب',
        natural_account: 'طبيعة الحساب',
        custom_type: 'النوع المخصص',
    },
    journals_entries_account: {
        date: 'التاريخ',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        operation: 'العملية',
        debit: 'المدين',
        credit: 'الدائن',
        balance_after: 'الرصيد بعد',
    },
    branch_config: {
        branch_config: 'إعدادات الفرع',
        type: 'النوع',
        type_selected: 'النوع المحدد',
        is_active: 'الحالة',
    },
    workstation: {
        add_workstation: 'أضف محطة عمل',
        name: 'الاسم',
        manager: 'المدير',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        created_at: 'تاريخ الإنشاء',
        workstation: 'محطة العمل',
        electricity_cost_per_hour: 'تكلفة الكهرباء في الساعة',
        rent_cost_per_hour: 'تكلفة الإيجار في الساعة',
        consumable_cost_per_hour: 'التكلفة الاستهلاكية في الساعة',
        wages_per_hour: 'أجر الساعة',
        net_rate_per_hour: 'المعدل الصافي للساعة',
        work_days: 'أيام العمل',
        is_active: 'نشط',
        working_hours: 'ساعات العمل',
        start_time: 'وقت البدء',
        end_time: 'وقت النهاية',
    },
    quality_inspections: {
        add_quality_inspections: 'أضف فحص الجودة',
        name: 'الاسم',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        created_at: 'تاريخ الإنشاء',
        quality_inspections: 'فحوصات الجودة',
        quality_inspection: 'فحص الجودة',
        is_active: 'نشط',
        validator_user: 'المستخدم المدقق',
        validation_date: 'تاريخ الصلاحية',
        description: 'الوصف',
        attachment: 'المرفق',
        upload_attachment: 'رفع مرفق',
        title: 'العنوان',
    },
    operation_management: {
        operation_management: 'إدارة العمليات',
        name: 'الاسم',
        default_workstation: 'محطة العمل الافتراضية',
        available_workstation: 'محطة العمل المتاحة',
        batch_size: 'حجم الدفعة',
        quality_inspection: 'فحص الجودة',
        description: 'الوصف',
        is_batch_size: 'قم بإنشاء بطاقة عمل بناءً على حجم الدفعة',
        is_active: 'نشط',
        is_corrective: 'تصحيحية',
        operation: 'عملية',
        operation_time_minutes: 'وقت العملية بالدقائق',
        add_operation: 'أضف العملية',
        user: 'المستعمل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        created_at: 'تاريخ الإنشاء',
        sub_operations: 'العمليات الفرعية',
    },
    routing_management: {
        routing_management: 'طريقة العمل',
        name: 'الاسم',
        workstation: 'محطة العمل',
        description: 'الوصف',
        is_active: 'نشط',
        operation: 'العملية',
        operations: 'العمليات',
        operation_time_hour: 'وقت العملية بالساعة',
        add_routing: 'أضف طريقة عمل',
        user: 'المستخدم',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        created_at: 'تاريخ الإنشاء',
        sequence_id: 'معرف التسلسل',
    },
    bill_of_material: {
        bill_of_material: "فاتورة التصنيع",
        add_bill_of_material: "إضافة فاتورة مواد",
        item: "المنتج",
        from_date: "من تاريخ",
        to_date: "الى تاريخ",
        user: "المستخدم",
        created_at: "تاريخ الإنشاء",
        basic_information: "المعلومات الاساسية",
        scrap: "الاهلاك",
        cost: "التكلفة",
        operation_and_materials: "التشغيل والمواد",
        item_details: "تفاصيل العنصر",
        allow_alternative_item: "السماح بعنصر بديل",
        is_default: "افتراضي",
        is_active: "نشط",
        unit: "الوحدة",
        quantity: "الكمية",
        description: "الوصف",
        rate_sub_assembly_based_bom: "تعيين معدل التجميع الفرعي على أساس بوم",
        allow_multi_inventory: "السماح بالمخزون المتعدد",
        company: "الشركة",
        inventory: "المخزن",
        branch: "الفرع",
        currency: "العملة",
        with_operation: "مع التشغيل, وإدارة تكلفة التشغيل",
        rate_material: "معدل المواد على أساس",
        routing: "التوجيه",
        operations: "العمليات",
        sequence_id: "معرف التسلسل",
        operation: "العملية",
        workstation: "محطة العمل",
        operation_time_minutes: "وقت التشغيل بالدقائق",
        transfer_manual: "دليل النقل",

        origin_quantity: 'الكمية الفعلية',
        required_quantity: 'الكمية المطلوبة',
        reserved: 'الكمية المحجوزة',
        price: 'السعر',
        amount: 'المبلغ',
        materials: 'المواد',
        scrap_and_process_loss: 'الاهلاك وخسارة العمليات',
        process_loss_rate: 'معدل خسارة العملية (عملة الفاتورة)',
        process_loss_quantity: 'كمية الخسارة في العملية',
        is_waste: 'مهدر',
        process_loss_rate_system: 'معدل خسارة العملية (عملة النظام)',
        details: 'تفاصيل',
        type: 'نوع',
        title: 'العنوان',
        notes: 'تفاصيل',
        operation_cost: 'تكلفة العملية',
        material_cost: 'تكلفة المادة',
        workstation_cost: 'تكلفة محطة العمل',
        scrap_waste_cost: 'تكلفة نفايات الاهلاك',
        scrap_not_waste_cost: 'تكلفة الاهلاك الغير مفقودة',
        total_cost: 'التكلفة الإجمالية',
        extra_cost: 'تكلفة إضافية',
        net_total_cost: 'التكلفة الإجمالية الصافية',
        status: 'الحالة',
        name: 'الاسم',
        actual_quantity: 'الكمية الفعلية',
        operation_price_per_hour: 'الكمية الفعلية',
    },
    material_list: {
        item: "المنتج",
        from_date: "من تاريخ",
        to_date: "الى تاريخ",
        user: "المستخدم",
        created_at: "تاريخ الإنشاء",
        add_material: 'اضف مادة',
        material_list: 'قائمة المواد',
        description: 'الوصف',
        sub_items: 'المنتجات الفرعية',
        item_details: 'تفاصيل المنتج',
        quantity: 'الكمية',
        is_active: 'نشط',
        unit: 'الوحدة',
        alternatives: 'البدائل',
        validate_on_inventory: 'التحقق على المخزن',
        main_inventory: 'المخزن الرئيسي',
        sub_inventory: 'المخزن الفرعي',
        origin_quantity: 'الكمية الفعلية',
        inventory: 'المخزن',
        available_quantity: 'الكمية المتاحة',
        supposed: 'الكمية الافتراضية',
        reserved: 'الكمية المحجوز',
    },
    work_order: {
        work_order: 'أمر العمل',
        title: 'العنوان',
        expected_qty: 'الكمية المتوقعة',
        item: 'المنتج',
        bill_of_material: 'فاتورة التصنيع',
        sales_no: 'رقم المبيعات',
        project_no: 'رقم المشروع',
        status: 'الحالة',
        actual_quantity: 'الكمية الفعلية',
        in_progress_inventory: 'المخزن المؤقت',
        target_inventory: 'المخزن النهائي',
        scrap_inventory: 'مخزن الاهلاك',
        skip_material_transfer: 'تخطي نقل المواد',
        use_multilevel_bom: 'استخدم فاتورة التصنيع متعددة المستويات',
        notes: 'ملاحظات',
        add_work_order: 'أضف أمر عمل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        created_at: 'تاريخ الإنشاء',
        origin_quantity: 'كمية الفعلية',
        required_quantity: 'الكمية المطلوبة',
        inventory: 'المخزن',
        item_details: 'تفاصيل المنتج',
        materials: 'المواد',
        planned_operation_cost: 'التكلفة التشغيلية المخطط لها',
        total_operation_cost: 'التكلفة الإجمالية للعملية',
        actual_operation_cost: 'تكلفة التشغيل الفعلية',
        additional_operation_cost: 'تكلفة التشغيل الإضافية',
        start_date_time: 'تاريخ ووقت البدء',
        expected_delivery_date_time: 'تاريخ ووقت التسليم المتوقع',
        operations: 'العمليات',
        operation: 'العملية',
        workstation: 'محطة العمل',
        operation_time: 'وقت العملية بالدقائق',
        output_quantity: 'كمية المخرجات',
        bom_no: 'رقم الفاتورة',
        operation_progress: 'تقدم العملية',
    },

    job_card: {
        job_card_code: 'كود بطاقة العمل',
        job_card_no: 'رقم بطاقة العمل',
        work_order: 'أمر العمل',
        bill_material: 'فاتورة التصنيع',
        operation: 'العملية',
        company: 'الشركة',
        posting_date: 'تاريخ البدء',
        in_progress_inventory: 'مخزن قيد التقدم',
        quantity: 'الكمية',
        employee: 'الموظف',
        status: 'الحالة',
        notes: 'الملاحظات',
        item: 'المنتج',
        from_time: 'من وقت',
        to_time: 'الى وقت',
        time_in_minutes: 'الوقت بالدقائق',
        complete_quantity: 'الكمية الكاملة',
        job_card: 'بطاقة العمل',
        work_order_no: 'رقم أمر العمل',
        bom_no: 'رقم فاتورة التصنيع',
        time_log: 'سجل الوقت',
        the_sum_qty_in_time_log_must_be_equal_main_qty: 'مجموع الكميات في سجل الوقت يجب ان يساوي الكمية الاساسية',
    },

    general_group: {
        new_general_group: "اضافة مجموعة عامة",
        general_group: "المجموعات العامة",
        name: "الاسم",
        notes: "تفاصيل",
        type: "النوع",
        type_name: "النوع",
    },
    aspects: {
        name: 'اسم',
        aspects: 'متغيرات المنتج',
        value_type: 'نوع القيمة',
        attribute_style: 'السمة النمطية',
        category: 'التصنيف',
        notes: 'الملاحظات',
        aspect_values: 'الخيارات',
        new_aspect: 'أضف متغيرات جديدة',
    },
    bundle_and_device: {
        bundle_and_device: 'تجميع وتفكيك',
        item_details: 'تفاصيل',
        item: 'المنتج',
        from_inventory: 'من مخزن',
        target_inventory: 'المخزن المستهدف',
        bundle_code: 'كود التجميع',
        bundle_quantity: 'كمية التجميع',
        bundle_date: 'تاريخ التجميع',
        notes: 'ملاحظات',
        associated_quantity: 'الكمية المرتبطة',
        origin_quantity: 'كمية الفعلية',
        required_quantity: 'الكمية المطلوبة',
        inventory: 'المخزن',
        available_quantity: 'الكمية المتاحة',
        add_bundle_and_device: 'اضف تجميع',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        from_quantity: 'من الكمية',
        to_quantity: 'الى الكمية',
    },
    bundle_divide: {
        item_details: 'تفاصيل',
        item: 'المنتج',
        from_inventory: 'من مخزن',
        target_inventory: 'المخزن المستهدف',
        notes: 'ملاحظات',
        associated_quantity: 'الكمية المرتبطة',
        origin_quantity: 'كمية الفعلية',
        required_quantity: 'الكمية المطلوبة',

        bundle_divide: 'تجزئة المنتج المركب',
        return_quantity: 'الكمية المرجعة',
        bundle_no: 'رقم التجميع',
        divide_no: 'رقم تجزئة منتج المركب',
        reason: 'السبب',
        lost_quantity: 'الكمية المفقودة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        add_bundle_divide: 'اضف تجزئة منتج المركب',
    },
    profile_settings: {
        thanks: 'شكرا لتسجيلك',
        thanks_message: 'مرحبا بك في نسختك الخاصة علي البرنامج',
        thanks_message_2: 'يمكنك استخدام هذا الرابط الخاص بك لبدء الاستخدام مباشرة',
    },
    departments: {
        name: 'الاسم',
        departments: 'الأقسام',
        manager: 'المدير',
        no_employee: 'عدد الموظفين',
        description: 'الوصف',
        new_department: 'قسم جديد',
    },
    module_data_access: {
        module_data_access: 'وحدة الوصول إلى البيانات',
        module: 'الوحدة',
        notes: 'الملاحظات',
        attributes: 'الخصائص',
        attribute_name: 'اسم الخاصية',
        is_mandatory: 'إلزامي',
        new_module_data_access: 'وحدة جديدة',
        from_date: 'من تاريخ',
        to_date: 'الي تاريخ',
        module_name: 'اسم الوحدة',
        created_at: 'تاريخ الإنشاء',
    },
    data_access_configuration: {
        user: 'المستخدم',
        level: 'المستوى',
        inventories: 'المخازن',
        branches: 'الفروع',
        categories: 'التصنيفات',
        departments: 'الأقسام',
        banks: 'البنوك',
        special: 'خاص',
        general: 'عام',
        custom: 'مخصص',
    },
    default_data: {
        inventory: 'المخزن',
        branch: 'الفرع',
        department: 'القسم',
        country: 'الدولة',
        city: 'المدينة',
        currency: 'العملة',
        workstation: 'محطة العمل',
        apply_default: 'استخدام كإفتراضي',
        unit: 'الوحدة',
        tax: 'الضريبة',
    },
    settings_initial: {
        employees: 'الموظفين',
        suppliers: 'المزودين',
        workstations: 'محطات العمل',
        taxes: 'الضرائب',
        units: 'الوحدات',
        brands: 'العلامات التجارية',
        branches: 'الفروع',
        countries: 'الدول',
        currencies: 'العملات',
        categories: 'التصنيفات',
        inventories: 'المخازن',
        manufacturers: 'المصانع',
    },
    pos_stores: {
        name: 'الاسم',
        address: 'العنوان',
        manager: 'المدير',
        mobile: 'الجوال',
        mobile_2: 'الجوال 2',
        description: 'الوصف',
        new_store: 'متجر نقطة بيع جديد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        created_at: 'تاريخ الإنشاء',
        pos_store: 'المتجر',
        devices_list: 'قائمة الأجهزة',
        device: 'الجهاز',
        customer: 'العميل',
        account: 'الحساب',
        default_bank: 'البنك الافتراضي',
        default_cash_treasury: 'خزينة نقدية افتراضية',
        default_card_treasury: 'خزينة بطاقة افتراضية',
        default_customer: 'العميل الافتراضي',
        default_inventory: 'المخزن الافتراضي',
        default_currency: 'العملة الافتراضي',
        default_payment_method: 'طريقة الدفع الافتراضي',
        is_allow_invoice_return: 'سماحية إرجاع الفاتورة',
        no_of_day_return_invoice: 'عدد ايام سماحية الإرجاع للفاتورة',
        payment_methods_list: 'قائمة طرق الدفع',
        payment_method: 'طريقة الدفع',
        treasury_or_back: 'خزينة او بنك',
    },

    receipt_document: {
        name: 'الاسم',
        receipt_date: 'تاريخ الاستلام',
        receipt_number: 'رقم الاستلام',
        notes: 'الملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        origin_qty: 'الكيمة الأساسية',
        received_qty: 'الكمية الفعلية',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        purchase_code: 'كود المشتريات',
    },
    expenses_income_transaction: {
        expenses_income_transaction: 'سند الصرف والقبض',
        code: 'الكود',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        currency: 'العملة',
        add_expenses_income_transaction: 'اضف سند صرف او قبض',
        name: 'العنوان',
        date: 'التاريخ',
        amount: 'المبلغ',
        tax: 'الضريبة',
        attachment: 'مرفق',
        status: 'الحالة',
        notes: 'ملاحظات',
        taxes: 'الضرائب',
        tax_type: 'نوع الضريبة',
        tax_val: 'نسبة الضريبة',
        total_tax: 'قيمة الضريبة',
        is_recurring: 'متكرر',
        frequency: 'التكرار',
        end_date: 'تاريخ الانتهاء',
        payment_method: 'طريقة الدفع',
        treasury: 'الخزينة',
        inventory: 'المخزن',
        bank: 'بنك',
        doc_in: 'قبض',
        doc_out: 'صرف',
        doc_type: 'نوع السند',
        class: 'تصنيف السند',
        customer: 'العميل',
        supplier: 'المورد',
        employee: 'الموظف',
        others: 'أخرى',
        doc_type_lable: 'السند',
        type_name: 'النوع',
        type: 'النوع لـ',
    },

    expenses_incomes_print: {
        expenses: 'سند صرف',
        number: 'الرقم',
        disburse_to_mr: 'اصرفوا الى السيد',
        amount_of: 'مبلغ وقدره',
        on_the_date: 'بتاريخ',
        and_that_for: 'وذلك مقابل',
        recipient_signature: 'توقيع المستلم',
        signature_of_the_treasurer: 'توقيع امين الصندوق',
        signature_of_the_manager: 'توقيع المدير',
        print_transaction: 'طباعة السند',
        date: 'التاريخ',
        received_voucher: 'سند قبض',
        on: 'على',
        received_from_mr: 'استلمنا من',
        payment_method: 'طريقة الدفع',
        treasury: 'الخزينة',
        bank: 'البنك',
        created_date: 'تاريخ الانشاء',
    },
    purchase_quotations: {
        purchase_quotations: 'عرض سعر مشتريات',
        title: 'عنوان',
        purchase_quotation_code: 'كود الطلب',
        purchase_quotation_date: 'تاريخ الطلب',
        purchase_due_date: 'تاريخ الاستحقاق للطلب',
        branch: 'فرع',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        qty: 'كمية',
        purchase_quotations_section: 'قسم عرض سعر مشتريات',
        add_purchase_quotations: 'إضافة عرض سعر مشتريات',
        convert_to_purchase_quotations: 'تحويل لعرض سعر مشتريات',
        convert_to_purchase_invoice: 'تحويل الى فاتورة مشتريات',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        supplier: 'المورد',
        currency: 'العملة',
        subtotal: 'المبلغ الاجمالي',
        total: 'المجموع الكلي',
        company: 'شركة',
        shipping_with: 'خيارات الشحن',
        shipping_fees: 'رسوم الشحن',
        unit_price: 'سعر الوحدة',
    },
    supplier_view: {
        supplier_details: 'تفاصيل المورد',
        personal_file: 'الملف الشخصي',
        quick_details: 'تفاصيل سريعة',
        number_of_purchase_invoices: 'عدد فواتير الشراء',
        number_of_unpaid_purchase_invoices: 'عدد فواتير الشراء الغير مدفوعة',
        last_purchase_invoice: 'آخر فاتورة شراء',
        last_purchase_invoices: 'آخر فواتير الشراء',
        balances: 'الارصدة',
        balance: 'الرصيد',
        debit: 'المبلغ المستحق',
        currency: 'العملة',
        last_validated_date: 'آخر تاريخ تحقق',
        last_purchase_invoices_for: 'آخر فواتير الشراء لـ',
        id: 'المعرف',
        name: 'الاسم',
    },
    customer_view: {
        customer_details: 'تفاصيل العميل',
        name: 'الاسم',
        personal_file: 'الملف الشخصي',
        quick_details: 'تفاصيل سريعة',
        number_of_sales_invoices: 'عدد فواتير المبيعات',
        number_of_unpaid_sales_invoices: 'عدد فواتير المبيعات الغير مدفوعة',
        last_sales_invoice: 'آخر فاتورة مبيعات',
        last_sales_invoices: 'آخر فواتير المبيعات',
        balances: 'الارصدة',
        balance: 'الرصيد',
        debit: 'المبلغ المستحق',
        currency: 'العملة',
        last_validated_date: 'آخر تاريخ تحقق',
        last_sales_invoices_for: 'آخر فواتير مبيعات لـ',
        id: 'المعرف',
    },
    account_statement_print: {
        account_statement_for: 'كشف حساب لـ',
        account_summary: 'مختصر الحساب',
        opening_balance: 'الرصید الافتتاحي',
        total: 'مجموع الفواتير',
        paid_to_date: 'مجموع الدفعات',
        deserved_amount: 'المبلغ المستحق',
        account_transactions: 'حركات الحساب',
        the_date: 'التاريخ',
        operation_type: 'نوع العملية',
        the_amount: 'المبلغ',
        the_currency: 'العملة',
        exchange_rate: 'سعر الصرف',
        balance_before: 'الرصيد قبل',
        balance_after: 'الرصيد بعد',
        amount_due_before: 'المبلغ المستحق قبل',
        the_amount_due_after: 'المبلغ المستحق بعد',
        from: 'من',
        to: 'حتى',
        total_refund: 'مجموع المرتجعات',
        total_expenses: 'مجموع سندات الصرف',
        transactions_of_related_customers_accounts: 'حركات حسابات العملاء المرتبطين',
        balance: 'الرصيد',
    },
    bank_checking: {
        bank_checking: 'مطابقة رصيد البنوك',
        bank: 'البنك',
        from_date: 'من',
        to_date: 'الى',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        current_amount: 'المبلغ الحالي',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },
    bank_checking_history: {
        bank_checking_history: 'سجل المطابقة',
        bank: 'البنك',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        current_amount: 'المبلغ الحالي',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },
    customer_checking: {
        customer_checking: 'التحقق من رصيد العميل',
        customer: 'العميل',
        from_date: 'من',
        to_date: 'الى',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        credit: 'المبلغ الحالي دائن',
        debit: 'المبلغ الحالي مدين',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },
    customer_checking_history: {
        customer_checking_history: 'سجل التحقق من رصيد العميل',
        customer: 'العميل',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        current_amount: 'المبلغ الحالي',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },
    supplier_checking_history: {
        supplier_checking_history: 'سجل المطابقة',
        supplier: 'المورد',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        last_validated_date: 'تاريخ التحقق الأخير',
        currency: 'العملة',
        validator_user: 'المستخدم المحقق',
        current_amount: 'المبلغ الحالي',
        actual_amount: 'المبلغ الفعلي',
        cash: 'نقدي',
        currency_name: 'اسم العملة',
        last_validate_date: 'آخر تاريخ للتحقق من الصحة',
        last_val: 'آخر قيمة',
    },

    sales_printing: {
        package_label: 'ملصق الطرد',
        print_package_label: 'ملصق الطرد',
        receive_list: 'قائمة الاستلام',
        print_receive_list: 'قائمة الاستلام',
        delivery_label: 'ملصق التوصیل',
        print_delivery_label: 'ملصق التوصیل',
        invoice: 'الفاتورة',
        shipping_for: 'اشحن إلى',
        invoice_for: 'فاتورة إلى',
        item: 'المنتج',
        description: 'الوصف',
        quantity: 'الكمية',
        total: 'الإجمالي',
        inventory: 'المخزن',
        from: 'من',

        pdf_package_label: 'تصدير ملصق الطرد PDF',
        pdf_receive_list: 'تصدير قائمة الاستلام PDF',
        pdf_delivery_label: 'تصدير ملصق التوصیل PDF',
        print_packing_slip: 'ملصق التعبئة',
        pdf_packing_slip: 'تصدير ملصق التعبئة PDF',
    },
    sales_quotations: {
        sales_quotations: 'عرض سعر',
        title: 'عنوان',
        sales_quotation_code: 'كود الطلب',
        sales_quotation_date: 'تاريخ الطلب',
        sales_due_date: 'تاريخ الاستحقاق للطلب',
        branch: 'فرع',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        qty: 'كمية',
        sales_quotations_section: 'عرض سعر',
        add_sales_quotations: 'إضافة عرض سعر',
        convert_to_sales_quotations: 'تحويل لعرض سعر مبيعات',
        convert_to_sales_invoice: 'تحويل الى فاتورة مبيعات',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        customer: 'العميل',
        currency: 'العملة',
        subtotal: 'المبلغ الاجمالي',
        total: 'المجموع الكلي',
        company: 'شركة',
        shipping_with: 'خيارات الشحن',
        shipping_fees: 'رسوم الشحن',
        unit_price: 'سعر الوحدة',
    },

    general_sales_quotations: {
        sales_quotations: 'عرض سعر عام',
        title: 'عنوان',
        sales_quotation_code: 'كود الطلب',
        sales_quotation_date: 'تاريخ الطلب',
        sales_due_date: 'تاريخ الاستحقاق للطلب',
        branch: 'فرع',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        qty: 'كمية',
        sales_quotations_section: 'عرض سعر عام',
        add_sales_quotations: 'إضافة عرض سعر عام',
        convert_to_sales_quotations: 'تحويل لعرض سعر مبيعات',
        convert_to_sales_invoice: 'تحويل الى فاتورة مبيعات',
        convert_to_general_sales_invoice: 'تحويل الى فاتورة مبيعات عامة',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        customer: 'العميل',
        currency: 'العملة',
        subtotal: 'المبلغ الاجمالي',
        total: 'المجموع الكلي',
        company: 'شركة',
        shipping_with: 'خيارات الشحن',
        shipping_fees: 'رسوم الشحن',
        unit_price: 'سعر الوحدة',
    },

    sales_quotation_requests: {
        sales_quotation_requests: 'طلبات عروض الأسعار',
        title: 'العنوان',
        sales_quotation_request_code: 'كود الطلب',
        sales_quotation_request_date: 'تاريخ الطلب',
        sales_request_due_date: 'تاريخ الاستحقاق للطلب',
        branch: 'الفرع',
        attachment: 'المرفق',
        notes: 'الملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        qty: 'الكمية',
        sales_quotation_requests_section: 'طلبات عروض الأسعار',
        add_sales_quotation_requests: 'إضافة طلب عرض سعر',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيفت من قبل',
        create_date: 'تاريخ الإنشاء',
        customer: 'العميل',
    },
    customer_permissions: {
        customer_permissions: 'صلاحيات بيانات العملاء',
        customers_created_by_the_user: 'العملاء الذين قام المستخدم بانشائهم',
        customers_belonging_to_the_branches_that_the_user_has_access_to: 'العملاء التابعين للفروع التي للمستخدم صلاحية الوصول لها',
        branches: 'الفروع',
        customers_belonging_to_the_groups_that_the_user_has_access_to: 'العملاء التابعين للمجموعات التي للمستخدم صلاحية الوصول لها',
        groups: 'المجموعات',
        customers_belonging_to_the_users_that_the_user_has_access_to: 'العملاء التابعين للمستخدمين التي للمستخدم صلاحية الوصول لهم',
        users: 'المستخدمين',
    },
    supplier_permissions: {
        supplier_permissions: 'صلاحيات بيانات الموردين',
        suppliers_created_by_the_user: 'الموردين الذين قام المستخدم بانشائهم',
        suppliers_belonging_to_the_branches_that_the_user_has_access_to: 'الموردين التابعين للفروع التي للمستخدم صلاحية الوصول لها',
        branches: 'الفروع',
        suppliers_belonging_to_the_groups_that_the_user_has_access_to: 'الموردين التابعين للمجموعات التي للمستخدم صلاحية الوصول لها',
        groups: 'المجموعات',
        suppliers_belonging_to_the_users_that_the_user_has_access_to: 'الموردين التابعين للمستخدمين التي للمستخدم صلاحية الوصول لهم',
        users: 'المستخدمين',
    },
    pos_permissions: {
        pos_permissions: 'صلاحيات بيانات نقاط البيع',
        invoices_for_sessions_that_the_user_has_created: 'فواتير الجلسات التي قام المستخدم بإنشائها',
        stores_that_the_user_has_access_to: 'المتاجر التي للمستخدم حق الوصول إليها',
        stores: 'المتاجر',
        is_other_sessions_return: 'إرجاع فاتورة من جلسة اخرى',
        users: "المستخدمين"
    },
    tax_settings: {
        the_application_of_the_first_tax: 'تطبيق الضريبة الاولى',
        apply_the_tax_on_the_principal_amount_before_deduction: 'تطبيق الضريبة على المبلغ الاساسي  قبل الخصم',
        apply_the_tax_on_the_principal_amount_after_deduction: 'تطبيق الضريبة على المبلغ الاساسي  بعد  الخصم',
        the_application_of_the_second_tax: 'تطبيق الضريبة الثانية',
        apply_the_tax_on_the_principal_amount_the_first_tax_value_before_deduction: 'تطبيق الضريبة على المبلغ الأساسي  + قيمة الضريبة الاولى  قبل الخصم',
        apply_the_tax_on_the_principal_amount_the_first_tax_value_after_deduction: 'تطبيق الضريبة على المبلغ الأساسي  + قيمة الضريبة الاولى  بعد  الخصم',
        tax_free_product: 'منتج بلا ضريبة',
        application_of_tax_on_the_value_of_profit: 'تطبيق الضريبة على قيمة الربح',
        apply_the_tax_on_the_principal_amount_before_the_discount_tax_2: 'تطبيق الضريبة على المبلغ الأساسي قبل الخصم',
        apply_the_tax_on_the_principal_amount_after_the_discount_tax_2: 'تطبيق الضريبة على المبلغ الأساسي بعد الخصم',

    },
    sales_quotations_printing: {
        sales_quotations: 'عرض سعر',
        reference: 'المرجع',
        customer_information: 'بيانات العميل',
        item: 'المنتج',
        unit_price: 'سعر الوحدة',
        quantity: 'الكمية',
        subtotal: 'المجموع',
        total: 'الإجمالي',
        sales_quotation_date: 'تاريخ الطلب',
        sales_due_date: 'تاريخ الاستحقاق للطلب',
    },
    payment_purchase_invoices: {
        payment_purchase_invoices: 'دفعات مشتريات',
        payment_method: 'طريقة الدفع',
        banks: 'البنوك',
        amount: 'المبلغ',
        payment_date: 'موعد الدفع',
        payment_status: 'حالة الدفع',
        collected_by: 'جمع بواسطة',
        ref_no: 'الرقم المصدر',
        payment_details: 'بيانات الدفع',
        receipt_notes: 'ملاحظات الاستلام',
        attachment: 'المرفق',
        supplier_info: 'بيانات المورد',
        invoice_code: 'كود الفاتورة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        from_amount: 'من الكمية',
        to_amount: 'الى الكمية',
        supplier: 'المورد',
        invoiced_by: 'تم إصدار الفواتير بواسطة',
        payment_sales_invoices_section: 'دفع مبيعات',
        payment_purchase_invoices_section: 'دفع مشتريات',
        supplier_code: 'كود المورد',
        supplier_name: 'اسم المورد',
        type: 'نوع الفاتوره',
        purchase_invoice: 'فاتورة المشتريات',
        purchase_refund: 'مرتجع المشتريات',
        details: 'التفاصيل',
        treasuries: 'الخزائن',
        currency: 'العملة',
    },
    payment_purchase_invoices_details: {
        Supplier_Info: 'معلومات المورد',
        Payment_Details: 'بيانات الدفع',
        Supplier_Name: 'اسم المورد',
        Invoice_No: 'رقم الفاتورة.',
        Street_Address: 'عنوان الشارع',
        Payment_Method: 'طريقة الدفع',
        City: 'مدينة',
        Amount: 'المبلغ',
        State: 'المقاطعة',
        Transaction_ID: 'رقم المعاملة',
        Postal_Code: 'الرمز البريدي',
        Status: 'الحالة',
        Telephone: 'هاتف',
        Date: 'التاريخ',
        Country: 'الدولة',
        Added_By: 'أضيفت بواسطة',
        Currency: 'العملة',
        Details: 'التفاصيل',
        Receipt_Notes: 'ملاحظات الاستلام',
        delete: 'حذف',
        receipt: 'الإيصال',
        edit: 'تعديل',
    },
    taxes_rules: {
        new_tax_rule: 'قاعدة ضريبية جديدة',
        tax_rule: 'قاعدة ضريبية',
        name: 'الإسم',
        description: 'الوصف',
        example: 'المثال',
        type: 'النوع',
        sales: 'مبيعات',
        purchase: 'مشتريات',
        both: 'كلاهما',
        auto_apply: 'التطبيق التلقائي',
        key: 'المفتاح',
    },
    delimiters: {
        name: 'الإسم',
        description: 'الوصف',
        example: 'المثال',
        tax_rule: 'قاعدة ضريبية',
        options: 'الخيارات',
        value: 'القيمة',
        attachment: 'المرفق',
        new_delimiter: 'محدد جديد',
    },
    invoice_status: {
        statuses: 'الحالات',
        invoice: 'الفاتورة',
        invoice_no: 'رقم الفاتورة',
        description: 'الوصف',
        details: 'التفاصيل',
        no_data: 'لا يوجد بيانات',
    },
    laundry_services: {
        add_laundry_service: 'اضافة خدمة مغاسل',
        laundry_service: 'خدمة مغاسل',
        name: 'الاسم',
        notes: 'الوصف',
        tax: 'الضريبة',
        order: 'الترتيب',
        symbol: 'الاختصار',
    },
    laundry_services_pricing: {
        service_name: 'اسم الخدمة',
        price: 'السعر',
        number_of_pieces: 'عدد القطع',
        tax: 'الضريبة',
        notes: 'الملاحظات',
        status: 'الحالة',
        special_operations:'الخدمات الخاصة',
        operation:'الخدمة الخاصة',
        quantity:'الكمية',
    },
    price_lists_laundry_services: {
        name: "الاسم",
        notes: "الملاحظات",
        prices: "الأسعار",
        service_price: "سعر الخدمة",
        new_price_list_laundry_service: "قائمة أسعار خدمات مغاسل جديدة",
        service_name: "اسم الخدمة",
        user_name: "المستخدم",
        quantity: "عدد القطع",
    },

    attached_reasons: {
        new_attached_reason: 'اضافة وصف القطع المستلمة',
        attached_reason: 'وصف القطع المستلمة',
        name: 'الاسم',
        applied_services: 'الخدمات المطبقة',
        status: 'الحالة',
        created_at: 'تاريخ الإنشاء',
    },
    debits: {
        debits: 'إشعارات المدين',
        name: 'اسم',
        customer: 'العميل',
        debit_code: 'كود اشعار المدين',
        debit_date: 'تاريخ الإشعار',
        currency: 'العملة',
        shipping_fees: 'مصاريف الشحن',
        attachment: 'المرفق',
        notes: 'ملاحظات',
        items: 'المنتجات',
        item: 'المنتج',
        description: 'وصف',
        unit_price: 'سعر الوحدة',
        qty: 'الكمية',
        tax: 'الضريبة',
        discount_type: 'نوع الخصم',
        discount_value: 'قيمة الخصم',
        subtotal: 'اجمالى المبلغ',
        unit: 'الوحدة',
        credits_section: 'قسم اشعارات المدين',
        add_sales_debits: 'أضف إشعار مدين',
        add_sales_debits_general: 'أضف إشعار مدين عام',
        from_date: 'من تاريخ',
        to_date: 'إلى تاريخ',
        added_by: 'أضيف من قبل',
        create_date: 'تاريخ الإنشاء',
        invoice_subtotal: 'اجمالى المبلغ',
        total_after_discount: 'المجموع بعد الخصم',
        total_after_shipping: 'المجموع بعد الشحن',
        total_tax: 'مجموع الضريبة',
        total: 'المجموع',
        from_quantity: 'من الكمية',
        to_quantity: 'إلى الكمية',
        basic_information: 'المعلومات الاساسية',
        attachments: 'المرفقات',
        shipping_details: 'تفاصيل الشحن',
        adjustment: 'تعديل',
        upload_attachment: 'تحميل المرفق',
        deposite_value: 'قيمة الإيداع',
        payment_terms: 'شروط الدفع',
        issue_date: 'تاريخ الإصدار',
        credit_subtotal: 'اجمالى المبلغ',
        day_s: 'أيام',
        inventory: 'المخزن',
        amount_tax: 'مبلغ الضريبة',
        subtotal_before_tax: 'المبلغ الكلي قبل الضريبة',
        invoice_total_discount: 'مجموع الخصومات',
        original_qty: 'الكمية الأساسية',
    },
    extend_days_plan: {
        your_subscription_will_expire: 'اشتراكك سينتهي في {date} لديك {days} ايام مجانية',
        get_extended_days: 'احصل علي ايام التمديد',
        ignore: 'تجاهل',
    },
    renewal_plan: {
        your_subscription_will_expire: 'اشتراكك سينتهي في {date}',
        renewal_subscription: 'تجديد الاشتراك',
    },

    laundry_services_colors: {
        laundry_services_colors: "ألوان القطع المستلمة",
        is_default: "هو الافتراضي",
        is_active: "نشط",
        color: "اللون",
        colors: "اللون",
        name: "الاسم",
        statuses: "الحالات",
    },
    cleaning: {
        cleaning: 'قيد العمل',
        cleaned: 'تم تنظيفها',
        add_rack_location: 'إضافة موقع الرف',
        rack: 'الرف',
        confirm: 'تأكيد',
        invoice_code: 'كود الفاتورة',
        ready_by: 'جاهزة بواسطة',
        customer: 'العميل',
        order: 'الطلبية',
        Pcs: 'PCS',
        notes: 'الملاحظات',
        total: 'الاجمالي',
        id: 'المعرف',
        ready_date: 'جاهزة بحلول',
        invoice_date: 'تاريخ الفاتورة',
    },
    ready: {
        ready: 'الطلبات المجمعة',
        collected: 'تم جمعها',
        rack: 'الرف',
        confirm: 'تأكيد',
        invoice_code: 'كود الفاتورة',
        ready_by: 'جاهزة بواسطة',
        customer: 'العميل',
        order: 'الطلبية',
        Pcs: 'PCS',
        notes: 'الملاحظات',
        total: 'الاجمالي',
        payment_method: 'طريقة الدفع',
        id: 'المعرف',
        ready_date: 'جاهزة بحلول',
        invoice_date: 'تاريخ الفاتورة',
        payment: 'الدفع',
        are_you_sure_make_it_collected: 'هل انت متأكد من جمعها؟',
        order_status: 'حالة الطلب',
        are_you_sure_make_it_completed: 'هل انت متأكد من جعله مكتمل؟',
        change_rack_location: 'تعديل موقع الرف',
        completed_order: 'الطلب مكتمل',
    },
    pickups: {
        pickups: 'الطلبات الجاهزة',
        collected: 'تم جمعها',
        rack: 'الرف',
        confirm: 'تأكيد',
        invoice_code: 'كود الفاتورة',
        ready_by: 'جاهزة بواسطة',
        customer: 'العميل',
        order: 'الطلبية',
        Pcs: 'PCS',
        notes: 'الملاحظات',
        total: 'الاجمالي',
        payment_method: 'طريقة الدفع',
        id: 'المعرف',
        ready_date: 'جاهزة بحلول',
        invoice_date: 'تاريخ الفاتورة',
        payment: 'الدفع',
        are_you_sure_make_it_collected: 'هل انت متأكد من جمعها؟',
        address: 'الإتصال',
    },
    sales_via_interval_report: {
        sales_via_interval_report: 'مبيعات فترة محددة',
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        show_report: 'عرض التقرير',
        date: 'التاريخ',
        total: 'المجموع',
        number: 'الرقم',

        invoice_code: 'رقم الفاتورة',
        invoice_date: 'تاريخ الفاتورة',
        invoice_total: 'قيمة المبيعات',
        customer_name: 'اسم العميل',
        customer_tax_register: 'الرقم الضريبي',
    },
    details_laundry: {
        details: 'التفاصيل',
        cleaned: 'تم تنظيفها',
        add_rack_location: 'إضافة موقع الرف',
        change_rack_location: 'تعديل موقع الرف',
        rack: 'الرف',
        confirm: 'تأكيد',
        invoice_code: 'كود الفاتورة',
        ready_by: 'جاهزة بواسطة',
        customer: 'العميل',
        order: 'الطلبية',
        Pcs: 'PCS',
        notes: 'الملاحظات',
        total: 'الاجمالي',
        id: 'المعرف',
        ready_date: 'جاهزة بحلول',
        invoice_date: 'تاريخ الفاتورة',
        order_details: 'تفاصيل الطلب',
        construction: 'الانشاء',
        payment: 'الدفع',
        cleaning: 'التنظيف',
        item: 'المنتج',
        note_or_problem: 'الملاحظة او المشكلة',
    },
    cash_category: {
        cash_category: 'فئة نقدية',
        new_cash_category: 'اضافة فئة نقدية',
        change_status: 'تغيير الحالة',
        name: 'الاسم',
        currency: 'العملة',
        value: 'القيمة',
        status: 'الحالة',
        image: 'الصورة',
        description: 'الوضف',
        upload_image: 'تحميل صورة',
    },

    invoice_bond_configuration: {
        name: 'الاسم',
        type: 'النوع',
        description: 'الوصف',
        debit_config_customer: 'اعداد المدين للعميل',
        credit_config_customer: 'اعداد الدائن للعميل',
        debit_config_supplier: 'اعداد المدين للمورد',
        credit_config_supplier: 'اعداد الدائن للمورد',
    },
    expired_days_plan: {
        your_subscription_expired: 'لقد انتهى اشتراكك الرجاء التواصل مع الإدارة أو تمديد  الاشتراك',
    },
    invoices_bonds_management: {
        invoice_bond: 'الفاتورة / السند',
        credit: 'دائن',
        debit: 'مدين',
        customer_credit: 'عميل دائن',
        customer_debit: 'عميل مدين',
        supplier_credit: 'مورد دائن',
        supplier_debit: 'مورد مدين',
        max: 'اعلى قيمة',
        min: 'اقل قيمة',
    },
    basic_status_management: {
        basic_status_management: 'إدارة الحالات',
        basic_information: 'البيانات الاساسية',
        name: 'الاسم',
        slug: 'Slug',
        module_type: 'نوع المديول',
        order: 'الترتيب',
        Detailed_data_documents: 'البيانات التفصيلية وثائق',
        doc_type: 'نوع الوثيقة',
        Detailed_data_users_and_permissions: 'البيانات التفصيلية مستخدمين وصلاحيات',
        Detailed_data_permissions: 'صلاحيات',
        Detailed_data_users: 'مستخدمين',
        user: 'المستخدم',
        is_registered: 'مسجل؟',
        registered: 'مسجل',
        unregistered: 'غير مسجل',
        permission: 'الصلاحية',
        is_required: 'مطلوب؟',
    },
    document_status_paths: {
        document_status_paths: 'مسار الوثيقة',
        add_path: 'اضافة مسار وثيقة',
        name: 'الاسم',
        module: 'المديول',
        module_slug: 'Slug المديول',
        document: 'الوثيقة',
        document_slug: 'الوثيقة Slug',
        description: 'الوصف',
        is_main: 'رئيسي؟',
        status: 'الحالة',
        is_active: 'التفعيل',
        status_list: 'الحالات',
        applied_roles: 'الصلاحيات المطبقة',
        removed_roles: 'الصلاحيات الغير مطبقة',
    },
    status_mapping: {
        status_mapping: 'تخطيط الحالات',
        name: 'الاسم',
        module: 'المديول',
        module_slug: 'Slug المديول',
        document: 'الوثيقة',
        document_slug: 'الوثيقة Slug',
        notes: 'الملاحظات',
        is_required: 'مطلوب؟',
        status: 'الحالة',
        is_active: 'التفعيل',
        default_initial_status: 'الحالة الأولية الافتراضية',
        default_final_status: 'الحالة النهائية الافتراضية',
        required_status: 'الحالة المطلوبة',
        optional_status: 'الحالة الاختيارية',
        multi_initial_status: 'الحالات الأولية المتعددة',
        multi_final_status: 'الحالات النهائية المتعددة',
        event_status: 'حالة الحدث',
        from_status: 'من الحالة',
        to_status: 'الي الحالة',
        event: 'الحدث',
        disallowed_convert_status: 'الحالات الغير مسموح تعديلها',
    },

    connected_printers: {
        connected_printers: 'الطابعات المتصلة',
        add_connected_printer: 'اضاقة طابعة متصلة',
        name: 'الاسم',
        type: 'النوع',
        notes: 'الملاحظات',
    },
    tax_declaration_report: {
        sales: 'المبيعات',
        purchases: 'المشتريات',
        tax: 'الضريبة',
        tax_due: 'الضريبة المستحقة',
        edits: 'التعديل',
        payable_tax: 'الضريبة المدفوعة',
    },
    simple_customer_balance_report: {
        type: 'العملية',
        type_id: 'رقم العملية',
        date: 'التاريخ',
        debit_after: 'مدين',
        credit_after: 'دائن',
        amount: 'الرصيد',
        currency: 'العملة',
        notes: 'الملاحظات',
        customer: 'العميل',
        address: 'العنوان',
        mobile: 'الجوال',
        account_statement: 'كشف حساب',
        net_movement: 'صافي الحركة',
        closing_balance: 'الرصيد الختامي',

    },

    report_main: {
        from_date: 'من تاريخ',
        to_date: 'الى تاريخ',
        issued_from: 'صادر من',
        commercial_register: 'السجل التجاري',
        tax_number: 'الرقم الضريبي',
        address: 'العنوان',
        issued_to: 'صادر الى',
    },

    fabric_problems: {
        new_fabric_problems: 'اضافة مشكلة قماش',
        fabric_problems: 'مشاكل القماش',
        name: 'الاسم',
        ordering: 'الترتيب',
        status: 'الحالة',
        description: 'الوصف',
    },
    doc_payment_details: {
        doc_code: 'كود السند',
        doc_type: 'نوع السند',
        amount: 'المبلغ',
        currency: 'العملة',
        create_date: 'تاريخ الانشاء',
        invoices: 'الفواتير',
        invoice_code: 'كود الفاتورة',
        invoice_type: 'نوع الفاتورة',
        balance: 'الرصيد',
        type: 'النوع',
        direction: 'الاتجاه',
        payment_details: 'تفاصيل الدفع',
    },
    payment_sales_details: {
        amount: 'المبلغ',
        currency: 'العملة',
        create_date: 'تاريخ الانشاء',
        invoices: 'الفواتير',
        invoice_code: 'كود الفاتورة',
        invoice_type: 'نوع الفاتورة',
        balance: 'الرصيد',
        type: 'النوع',
        direction: 'الاتجاه',
        payment_details: 'تفاصيل الدفع',
        invoice_id: 'معرف الفاتورة',
        payment_code: 'كود الدفعة',
        payment_no: 'رقم الدفعة',
        main_invoice: 'الفاتورة الاساسية',
        attached_invoices: 'الفواتير الملحقة',
    },
    payment_purchase_details: {
        amount: 'المبلغ',
        currency: 'العملة',
        create_date: 'تاريخ الانشاء',
        invoices: 'الفواتير',
        invoice_code: 'كود الفاتورة',
        invoice_type: 'نوع الفاتورة',
        balance: 'الرصيد',
        type: 'النوع',
        direction: 'الاتجاه',
        payment_details: 'تفاصيل الدفع',
        invoice_id: 'معرف الفاتورة',
        payment_code: 'كود الدفعة',
        payment_no: 'رقم الدفعة',
        main_invoice: 'الفاتورة الاساسية',
        attached_invoices: 'الفواتير الملحقة',
    },

}
