import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import I18nService from "@/core/services/i18n.service";


Vue.prototype.$postStatus = true;
/**
 * Service to call HTTP request via Axios
 */
const addPageLoader = () => {
    document.body.classList.add("page-loading");
}
const removePageLoader = () => {
    setTimeout(() => {
        document.body.classList.remove("page-loading");
    }, 2000);
}
const ApiService = {
    init() {

        axios.defaults.headers.common["Accept-Language"] = `${I18nService.getActiveLanguage()}`;
        axios.interceptors.request.use(function (config) {
            addPageLoader();
            return config;
        }, function (error) {
            removePageLoader();
            return Promise.reject(error);
        });
        axios.interceptors.response.use(function (response) {
            removePageLoader();
            return response;
        }, function (error) {
            removePageLoader();
            return Promise.reject(error);
        });


        Vue.use(VueAxios, axios);

        const host = window.location.host;
        const protocol = window.location.protocol;
        const parts_length = (process.env.NODE_ENV == 'development' ? 2 : 3);
        const parts = host.replaceAll('http://', '').replaceAll('https://', '').replaceAll('www.', '').split('.');
        let inner_baseURL = ((parts.length == parts_length && parts[0]) ? protocol + '//' + parts[0] + '.' + process.env.VUE_APP_SERVER : process.env.VUE_APP_URL);
        Vue.axios.defaults.baseURL = inner_baseURL;


    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        Vue.axios.defaults.headers.common["Accept-Language"] = `${I18nService.getActiveLanguage()}`;
    },
    getBaseURL() {
        return Vue.axios.defaults.baseURL;
    },
    getHeaders() {
        return {
            "Authorization": `Bearer ${JwtService.getToken()}`,
            "Accept-Language": `${I18nService.getActiveLanguage()}`,
        }
    },
    query(resource, params) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            return Vue.axios.get(resource, {params: {...params}}).then((response) => {
                resolve(response);
                setTimeout(() => {
                    Vue.prototype.$postStatus = true;
                }, 2000);
            })
                // .catch((error) => {
                //     reject(error);
                //     setTimeout(() => {
                //         Vue.prototype.$postStatus = true;
                //     }, 2000);
                // });
        });
        //     .catch(error => {
        //     // console.log(error);
        //     throw new Error(`[KT] ApiService ${error}`);
        // });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    get(resource, params) {
        ApiService.setHeader();
        return new Promise((resolve, reject) => {
            return Vue.axios.get(`${resource}`, params).then((response) => {
                resolve(response);
                setTimeout(() => {
                    Vue.prototype.$postStatus = true;
                }, 2000);
            })
                // .catch((error) => {
                //     reject(error);
                //     setTimeout(() => {
                //         Vue.prototype.$postStatus = true;
                //     }, 2000);
                // });
        });
        //     .catch(() => {
        //     this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        //     this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        // });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.post(`${resource}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    postFormData(resource, params, headers) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.post(`${resource}`, params, headers).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }
    },

    // post(resource, params) {
    //     if (!Vue.prototype.$postStatus) {
    //         // If a request is already in progress, prevent further requests
    //         return Promise.reject(new Error('Request in progress'));
    //     }
    //
    //     Vue.prototype.$postStatus = false;
    //
    //     return new Promise((resolve, reject) => {
    //         Vue.axios.post(`${resource}`, params)
    //             .then((response) => {
    //                 resolve(response);
    //                 setTimeout(() => {
    //                     Vue.prototype.$postStatus = true;
    //                 }, 2000);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //                 setTimeout(() => {
    //                     Vue.prototype.$postStatus = true;
    //                 }, 2000);
    //             });
    //     });
    // },


    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        if (slug) {
            slug = '/' + slug;
        }
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.put(`${resource}${slug}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.put(`${resource}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }
    },
    /**
     * Send the PATCH HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    patch(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.patch(`${resource}`, params).then((response) => {
                    resolve(response);
                    Vue.prototype.$postStatus = true;
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    delete(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.delete(resource, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }
        //     .catch(error => {
        //     // console.log(error);
        //     throw new Error(`[RWV] ApiService ${error}`);
        // });
    }
};

export default ApiService;