import Vue from "vue";
import Permissions from "@/core/services/permissions.service";
// import profileModule from "@/core/services/store/profile.module";

Vue.prototype.$can = function(role) {
    const currentUserRoles = Permissions.getPermission();
    if (currentUserRoles.includes(role)){
        return true;
    }
    return false;
}
Vue.prototype.$canAny = function(roles) {
    const currentUserRoles = Permissions.getPermission();
    let status = false;
    roles.forEach((role)=>{
        if (currentUserRoles.includes(role)){
            status =  true;
        }
    })
    return status;
}
