<template>
  <div class="d-flex">
    <a v-if="templateUrl" style="height: fit-content;" target="_blank" :href="templateUrl" class="btn btn-dark btn-sm">
      <i class="fa fa-file" style="padding-left: unset;"></i>
    </a>
    <div style="margin-left: 5px;margin-right: 5px;">
      <input :id="'inputImportExcel'+innerId" type="file" class="inputfile" v-on:change="uploadFile" :disabled="disableLoadingButton">
      <label :for="'inputImportExcel'+innerId" :class="btnClass" :disabled="disableLoadingButton">
        <i class="fa fa-file-import"></i>
        {{ placeholder }}
        <span v-if="disableLoadingButton"><i class="fa fa-spinner fa-pulse fa-fw"></i></span>
      </label>
    </div>

    <div class="error" v-if="validation && validation.file" style="color: red">{{ validation.file[0] }}</div>
    <div class="error" v-if="validation && validation.extensions" style="color: red">{{ validation.extensions }}</div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import readXlsxFile from "read-excel-file";

export default {
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      disableLoadingButton: false,
      validation: null,
      data: [],
      templateUrl: null,

      valid: {
        extensions: null,
      },
    }
  },
  watch: {},
  props: {
    innerId: null,
    upload: null,
    placeholder: null,
    btnClass: null,
    typeExtension: null,
    templateLink: null,

  },
  mounted() {
    this.getTemplate();
  },
  methods: {
    getTemplate() {
      if (this.templateLink)
        ApiService.get(this.mainRouteDependency + "/" + this.templateLink).then((response) => {
          this.templateUrl = response.data.data.link ? response.data.data.link : null;
        });
    },
    uploadFile() {
      // File.
      const input = document.getElementById('inputImportExcel' + this.innerId);
      this.data = [];
      readXlsxFile(input.files[0]).then((rows) => {
        rows.forEach((row, index) => {
          let _headers = rows[0];
          let _inner_data = {};
          if (index != 0) {
            _headers.forEach((hd, index) => {
              _inner_data[hd] = row[index];
            });
            this.data.push(_inner_data)
          }
        })
        this.$emit('data', this.data);

      })


    },
    // onFileChange(e) {
    //   const input = document.getElementById('inputImportExcel' + this.innerId);
    //   console.log(input.files[0], input.files[0].size);
    //   if (!input.files[0].size) return;
    //   this.createInput(input.files[0]);
    // },
    // createInput(file) {
    //   let promise = new Promise((resolve, reject) => {
    //     let reader = new FileReader();
    //     let vm = this;
    //     reader.onload = e => {
    //       resolve((vm.fileinput = reader.result));
    //     };
    //     reader.readAsText(file);
    //   });
    //
    //   promise.then(
    //       result => {
    //         /* handle a successful result */
    //         console.log(this.fileinput);
    //       },
    //       error => {
    //         /* handle an error */
    //         console.log(error);
    //       }
    //   );
    // }
  }
}
</script>

<style>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>

