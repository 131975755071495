import ApiService from "@/core/services/api.service";

export const COMPANY_MAIN_ROUTE = "settings/company/getCompany";

// action types
export const GET_COMPANY = "getCompany";
export const SET_COMPANY = "setCompany";

export const SET_ERROR = "setError";
const state = {
  company_data: [],
  errors: null
};

const getters = {
  getCompanyData(state) {
    return state.company_data;
  },

};

const actions = {
  [GET_COMPANY](context) {
      ApiService.get(COMPANY_MAIN_ROUTE)
        .then(({ data }) => {
          context.commit(SET_COMPANY, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_COMPANY](state, data) {
    state.company_data = data;
  },

};

export default {
  state,
  actions,
  mutations,
  getters
};
