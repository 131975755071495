import { ref, nextTick } from 'vue';

import ApiService from "@/core/services/api.service";
import Permissions from "@/core/services/permissions.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_ERROR = "setError";
export const SET_PERMISSIONS_DATA = "setPermissionsData";

const state = {
    user_personal_info: {
        id: "",
        photo: "",
        name: "",
        surname: "",
        company_name: "",
        job: "",
        email: "",
        phone: "",
        company_site: "",
        plan_id: "",
        start_date: "",
        end_date: "",
        source_id: "",
        uuid: "",
        department: null,
        department_id: null,
        inventory: null,
        inventory_id: null,
        currency_id: null,
        currency_name: null,
        branch: null,
        branch_id: null,
        city: null,
        city_id: null,
        country: null,
        country_id: null,
        tax: null,
        tax_id: null,
        unit: null,
        unit_id: null,
        workstation: null,
        workstation_id: null,
        display_extension_days: null,
        is_renewal_plan: false,
        is_main: false,
        is_show_expired_company_account: false,
        is_system_user: false,
    },
    user_account_info: {
        username: "nick84",
        email: "nick.watson@loop.com",
        language: "English",
        time_zone: "(GMT-11:00) Midway Island",
        communication: {
            email: true,
            sms: true,
            phone: false
        },
        verification: true
    }
};

const getters = {
    currentUserPersonalInfo(state) {
        return state.user_personal_info;
    },

    currentUserAccountInfo(state) {
        return state.user_account_info;
    },

    currentUserPhoto(state) {
        return state.user_personal_info.photo;
    }
};

const actions = {
    [UPDATE_PERSONAL_INFO](context) {
        return new Promise(resolve => {
            ApiService.get("getUserPersonal")
                .then(({ data }) => {
                    context.commit(SET_PERSONAL_INFO, data.data);
                    // context.commit(SET_PERMISSIONS_DATA, data.data);
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                });
        });
        // context.commit(SET_PERSONAL_INFO, payload);
    },
    [UPDATE_ACCOUNT_INFO](context, payload) {
        context.commit(SET_ACCOUNT_INFO, payload);
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_PERSONAL_INFO](state, user_personal_info) {

        state.user_personal_info = user_personal_info;
        state.user_personal_info = {
            id: user_personal_info.id,
            photo: user_personal_info.image,
            name: user_personal_info.first_name,
            surname: user_personal_info.last_name,
            company_name: user_personal_info.company_name,
            job: user_personal_info.job,
            email: user_personal_info.email,
            phone: user_personal_info.phone,
            company_site: user_personal_info.company_site,
            plan_id: user_personal_info.plan_id,
            uuid: user_personal_info.uuid,
            start_date: user_personal_info.start_date,
            end_date: user_personal_info.end_date,
            source_id: user_personal_info.source_id,

            department: user_personal_info.department,
            department_id: user_personal_info.department_id,
            inventory: user_personal_info.inventory,
            inventory_id: user_personal_info.inventory_id,
            currency_id: user_personal_info.currency_id,
            currency_name: user_personal_info.currency_name,
            branch: user_personal_info.branch,
            branch_id: user_personal_info.branch_id,
            city: user_personal_info.city,
            city_id: user_personal_info.city_id,
            country: user_personal_info.country,
            country_id: user_personal_info.country_id,
            tax: user_personal_info.tax,
            tax_id: user_personal_info.tax_id,
            unit: user_personal_info.unit,
            unit_id: user_personal_info.unit_id,
            workstation: user_personal_info.workstation,
            workstation_id: user_personal_info.workstation_id,
            display_extension_days: user_personal_info.display_extension_days,
            is_renewal_plan: user_personal_info.is_renewal_plan,
            is_main: ((user_personal_info.is_main == 1) ? true : false),
            is_show_expired_company_account: user_personal_info.is_show_expired_company_account,
            is_system_user: user_personal_info.is_system_user,
        };
    },
    [SET_ACCOUNT_INFO](state, user_account_info) {
        state.user_account_info = user_account_info;
    },
    [SET_PERMISSIONS_DATA](state, permissions_data) {
        Permissions.savePermission(permissions_data.permission);//['users','another-actions']
        // const show_aa = ref(true);
        // const content_aa = ref();
        // nextTick((vm) => {
        //
        //     console.log(show_aa, content_aa);
        // });
        // this.$refs.main_menu_list.refresh();

    }
};

export default {
    state,
    actions,
    mutations,
    getters
};